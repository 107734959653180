import React from 'react'
import { useEntry } from 'intersection-observer'

import type { ComponentPageBrandsLogoBlend } from 'typings/strapi.graphql'

import BrandsRow from './components/BrandsRow/BrandsRow'


type MobileViewProps = {
  className?: string
  data: ComponentPageBrandsLogoBlend['rows']
}

const MobileView: React.FunctionComponent<MobileViewProps> = (props) => {
  const { className, data } = props

  const [ ref, entry ] = useEntry()
  const isVisible = entry?.isIntersecting

  return (
    <div ref={ref} className={className}>
      {
        data?.map(({ mobileRowImage, mobileRowImageWidth, mobileRowImageHeight }, index) => {
          return (
            <BrandsRow
              key={index}
              className={index && 'mt-32'}
              imageWidth={mobileRowImageWidth}
              imageHeight={mobileRowImageHeight}
              imageData={mobileRowImage?.data?.attributes}
              isVisible={isVisible}
              inverted={Boolean(index % 2)}
            />
          )
        })
      }
    </div>
  )
}

export default MobileView
