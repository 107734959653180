import React, { useCallback } from 'react'
import cx from 'classnames'

import { Image } from 'components/dataDisplay'
import { ButtonBase } from 'components/inputs'
import { AspectContainer } from 'components/layout'

import s from './TagItem.module.css'


type TagItemProps = {
  data: CataloguePages.AvailableFilterData['values'][number]
  appliedGroupTagId: CataloguePages.AvailableFilterData['values'][number]['id']
  position: number
  onClick: (data: CataloguePages.AvailableFilterData['values'][number], position: number) => void
}

const TagItem: React.FunctionComponent<TagItemProps> = ({ data, appliedGroupTagId, position, onClick }) => {
  const { id, name, rebrandImage, image } = data

  const isActive = id === appliedGroupTagId
  const isFaded = Boolean(!isActive && appliedGroupTagId)

  const handleClick = useCallback(() => {
    onClick(data, position)
  }, [ data, position, onClick ])

  const buttonClassName = cx({
    [s.active]: isActive,
    'opacity-50': isFaded,
  })

  return (
    <ButtonBase
      className={buttonClassName}
      role="checkbox"
      aria-checked={isActive}
      onClick={handleClick}
    >
      <AspectContainer className="w-full" aspect={1} fit="cover">
        <Image
          className={cx(s.image, 'rounded')}
          src={rebrandImage || image}
          alt={name}
          fill
          remWidth={116}
        />
      </AspectContainer>
      <div className="text-p4 mt-8">
        {name}
      </div>
    </ButtonBase>
  )
}


export default TagItem
