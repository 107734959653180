import React, { Fragment } from 'react'

import FeaturedBrands from '../shared/FeaturedBrands/FeaturedBrands'

import GiftHubSection from './components/GiftHubSection/GiftHubSection'
import PageNotification from './components/PageNotification/PageNotification'
import ImageBannersGrid from './components/ImageBannersGrid/ImageBannersGrid'
import CatalogueProductsFeed from './components/CatalogueProductsFeed/CatalogueProductsFeed'
import BrandsLogoBlend from './components/BrandsLogoBlend/BrandsLogoBlend'


const ComponentsMap = {
  'ComponentPageGiftHubSection': GiftHubSection,
  'ComponentPagePageNotification': PageNotification,
  'ComponentPageFeaturedBrands': FeaturedBrands,
  'ComponentPageImageBannersGridOld': ImageBannersGrid,
  'ComponentPageCatalogueProductsFeed': CatalogueProductsFeed,
  'ComponentPageBrandsLogoBlend': BrandsLogoBlend,
  'ComponentPageContentSlot': -1,
}

type BodySectionProps = {
  contentSlot?: React.ReactNode
  data: DynamicPagesModule.BodySectionData
}

const BodySection: React.FC<BodySectionProps> = (props) => {
  const { contentSlot, data = [] } = props

  return (
    <>
      {data.map((section, index) => {
        const Component = ComponentsMap[section.__typename]

        if (Component === -1) {
          return (
            <Fragment key={index}>{contentSlot}</Fragment>
          )
        }

        if (!Component) {
          return null
        }

        return (
          <Component key={index} data={section} />
        )
      })}
    </>
  )
}

export default BodySection
