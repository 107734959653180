import React, { Fragment, useMemo } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { Text } from 'components/dataDisplay'
import { Grid } from 'components/layout'

import { useCatalogueContext } from 'pages/catalog/util'
import FilterModalsActionButtons from 'pages/catalog/components/FilterModalsActionButtons/FilterModalsActionButtons'

import NoteListItem from '../NoteListItem/NoteListItem'

import messages from './messages'


type BrandsLayerProps = {
  onApply: () => void
}

// TODO react.window?
//  there a lot of notes and they are duplicated by design (image card for every note + alphabet list) - added on 06.01.2022 by sonatskiy
const NotesLayer: React.FunctionComponent<BrandsLayerProps> = ({ onApply }) => {
  const { availableFilters } = useCatalogueContext()

  const availableNotes = availableFilters.NOTES.values

  const { isMobile } = useDevice()

  const noteGroups = useMemo(() => {
    if (!availableNotes) {
      return {}
    }

    // notes are sorted by name from backend
    return availableNotes.reduce<CataloguePages.FilterGroups>((result, item) => {
      let firstLetter = item.name.charAt(0).toUpperCase()

      result[firstLetter] = result[firstLetter] || []
      result[firstLetter].push(item)

      return result
    }, {})
  }, [ availableNotes ])

  return (
    <div className="flex flex-col">
      <Text className="mb-32 flex-none" message={messages.title} style="sh3" />
      <div className="flex-1">
        {
          Boolean(availableNotes.length) && (
            <>
              <Grid columns={3} gutter={12} role="group">
                {
                  availableNotes.map((data) => (
                    <Grid.Cell key={data.name} className="pb-4">
                      <NoteListItem data={data} type="card" />
                    </Grid.Cell>
                  ))
                }
              </Grid>
              {
                Object.keys(noteGroups).map((letter) => (
                  <Fragment key={letter}>
                    <Text
                      className="mt-24 pb-8 pt-24"
                      message={letter}
                      style="h8"
                      color="gold-50"
                    />
                    {/*
                     TODO it's incorrect aria and the should be "listbox" - added on 27.09.2021 by sonatskiy
                     check this PR https://github.com/tailwindlabs/headlessui/pull/648 to implement Listbox with multiselect
                     */}
                    <div className={cx('my-0', isMobile ? '-mx-16' : '-mx-40')} role="group">
                      {
                        noteGroups[letter].map((data) => (
                          <NoteListItem key={data.id} data={data} type="row" />
                        ))
                      }
                    </div>
                  </Fragment>
                ))
              }
            </>
          )
        }
      </div>
      <FilterModalsActionButtons filterKey="NOTES" onApply={onApply} />
    </div>
  )
}


export default NotesLayer
