import type { HrefProps } from 'components/navigation'


type LinkProps = Pick<HrefProps, 'to' | 'toTab' | 'href'>

// TODO: Not 100% correct, also the ability to force flags needs to be added — added on 28–02–2025 bu agleas
export default (link?: string): LinkProps | null => {
  const fixedLink = link?.replace(/https?:\/\/(local|test|preprod|www)\.scentbird\.com\//, '/')

  if (!fixedLink) {
    return null
  }

  if (fixedLink.startsWith('/blog')) {
    return { toTab: fixedLink }
  }

  const isExternal = !fixedLink.startsWith('/')

  if (isExternal) {
    return { href: fixedLink }
  }

  return { to: fixedLink }
}
