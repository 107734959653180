import React from 'react'
import cx from 'classnames'
import { track } from 'analytics'
import { useDevice } from 'device'
import { getLinkProps } from 'modules/strapi'
import type { ComponentPageImageBannersGridItemOld } from 'typings/strapi.graphql'

import { Image } from 'components/dataDisplay'
import { Href } from 'components/navigation'

import s from './ImageBannersGridItem.module.css'


const sizeToClassName = [ s['size-1'], s['size-2'], s['size-3'] ] as const

type ImageBannersGridItemProps = {
  className?: string
  data: ComponentPageImageBannersGridItemOld
}

const ImageBannersGridItem: React.FC<ImageBannersGridItemProps> = (props) => {
  const { className, data } = props
  const {
    desktopImage,
    desktopOrder = 0,
    link,
    mobileImage,
    mobileOrder = 0,
    size = 1,
    mixpanel,
  } = data

  const { isMobile } = useDevice()

  const linkProps = getLinkProps(link)

  const image = isMobile ? mobileImage : desktopImage
  const position = isMobile ? mobileOrder : desktopOrder
  const trackParams = mixpanel?.trackParams || {}

  const orderStyle = { order: isMobile ? mobileOrder : desktopOrder }

  const sizeClassName = sizeToClassName[size - 1]

  const containerClassName = cx(className, 'relative', isMobile ? s['size-3'] : sizeClassName, !isMobile && 'h-[304rem]')

  const content = (
    <Image
      className="size-full object-cover"
      src={image?.data?.attributes?.url}
      alt={image?.data?.attributes?.alternativeText}
    />
  )

  if (linkProps) {
    const handleClick = () => {
      track('Promo hub block click', {
        position,
        ...trackParams,
      })
    }

    return (
      <Href {...linkProps} className={containerClassName} style={orderStyle} onClick={handleClick}>
        {content}
      </Href>
    )
  }

  return (
    <div className={containerClassName} style={orderStyle}>
      {content}
    </div>
  )
}

export default ImageBannersGridItem
