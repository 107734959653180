import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import type { ComponentPageGiftHubHero } from 'typings/strapi.graphql'

import { Image, RichText } from 'components/dataDisplay'


type GiftHubHeroProps = {
  className?: string
  data: ComponentPageGiftHubHero
}

const GiftHubHero: React.FC<GiftHubHeroProps> = (props) => {
  const { className, data } = props
  const { heroText, label: labelImage } = data

  const { isMobile } = useDevice()

  const label = isMobile
    ? labelImage?.mobile?.data?.attributes
    : labelImage?.desktop?.data?.attributes

  const height = isMobile ? labelImage?.mobileHeight : labelImage?.desktopHeight

  return (
    <div className={cx(className, 'text-center')}>
      {
        Boolean(label) && (
          <Image
            className="max-w-full"
            src={label.url}
            style={{
              height: height ? `${height}rem` : undefined,
            }}
            alt={label.alternativeText}
          />
        )
      }
      {
        Boolean(heroText) && (
          <RichText message={isMobile ? heroText.mobile : heroText.desktop} />
        )
      }
    </div>
  )
}

export default GiftHubHero
