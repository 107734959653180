import React, { useEffect } from 'react'
import cx from 'classnames'

import { Disclosure as HeadlessDisclosure } from '@headlessui/react'

import { Icon, Text } from 'components/dataDisplay'

import { useFilterDisclosureGroupContext } from '../../util'


type DisclosureContentGroupItemProps = CatalogFilters.FilterItemListProps & {
  title: string | Intl.Message
  filterKey: string
  'data-testid'?: string
}

type DisclosureContentGroupItemContentProps = Omit<DisclosureContentGroupItemProps, 'data-testid'> & {
  open: boolean
  close: () => void
}


const DisclosureContentGroupItemContent: React.CFC<DisclosureContentGroupItemContentProps> = (props) => {
  const { filterKey, title, open, children, onFilterListOpen, close } = props
  const filterDisclosureGroup = useFilterDisclosureGroupContext()

  useEffect(() => {
    if (open) {
      if (onFilterListOpen) {
        onFilterListOpen(filterKey)
      }

      // We can't control closing programmatically (https://github.com/tailwindlabs/headlessui/discussions/475)
      filterDisclosureGroup.closePreviousItem(close)
    }
  }, [ close, filterDisclosureGroup, filterKey, onFilterListOpen, open ])

  const iconClassName = cx('transform-gpu transition-transform', open && 'rotate-180')

  return (
    <>
      <HeadlessDisclosure.Button
        className="hover:text-gold-30 flex w-full cursor-pointer items-center justify-between py-16 text-black"
      >
        <Text message={title} style="t5" color={open ? 'gold-50' : 'black'} />
        <Icon className={iconClassName} name="32/arrow-down" color="black" />
      </HeadlessDisclosure.Button>
      <HeadlessDisclosure.Panel className="pb-24">
        {children}
      </HeadlessDisclosure.Panel>
    </>
  )
}

const DisclosureContentGroupItem: React.CFC<DisclosureContentGroupItemProps> = (props) => {
  const { defaultOpen = false, 'data-testid': dataTestId, ...restProps } = props

  return (
    <HeadlessDisclosure as="div" defaultOpen={defaultOpen} data-testid={dataTestId}>
      {
        ({ open, close }) => (
          <DisclosureContentGroupItemContent
            open={open}
            close={close}
            {...restProps}
          />
        )
      }
    </HeadlessDisclosure>
  )
}


export default DisclosureContentGroupItem
