import React from 'react'
import { useDevice } from 'device'

import InfinityScrollProductFeedMobileView from './components/InfinityScrollProductFeedMobileView/InfinityScrollProductFeedMobileView'
import InfinityScrollProductFeedDesktopView from './components/InfinityScrollProductFeedDesktopView/InfinityScrollProductFeedDesktopView'


const InfinityScrollProductFeed: React.FC<InfinityScrollProductFeed.Props> = (props) => {
  const { isMobile } = useDevice()

  const ViewComponent = isMobile ? InfinityScrollProductFeedMobileView : InfinityScrollProductFeedDesktopView

  return (
    <ViewComponent {...props} />
  )
}


export default React.memo(InfinityScrollProductFeed)
