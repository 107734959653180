import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import type { ComponentPageOfferBanner } from 'typings/strapi.graphql'

import { Image, RichText } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'


type OfferBannerProps = {
  data: ComponentPageOfferBanner
}

const OfferBanner: React.FunctionComponent<OfferBannerProps> = (props) => {
  const { data } = props
  const { desktop, image, logo, mobile, text } = data

  const { isMobile } = useDevice()

  const isVisible = isMobile ? mobile : desktop

  if (!isVisible) {
    return null
  }

  const offerImage = isMobile
    ? image?.mobile?.data?.attributes
    : image?.desktop?.data?.attributes
  const logoImage = isMobile
    ? logo?.mobile?.data?.attributes
    : logo?.desktop?.data?.attributes

  return (
    <WidthContainer>
      <div className={cx('bg-light-beige flex items-center rounded p-12', isMobile ? 'mt-24' : '')}>
        {
          Boolean(offerImage) && (
            <Image className="h-88 mr-8 flex w-[132rem] object-contain" src={offerImage.url} />
          )
        }
        <div className="flex flex-col items-center">
          {
            Boolean(logoImage) && (
              <Image className="max-h-24" src={logoImage.url} />
            )
          }
          {
            Boolean(text) && (
              <RichText message={isMobile ? text.mobile : text.desktop} />
            )
          }
        </div>
      </div>
    </WidthContainer>
  )
}

export default OfferBanner
