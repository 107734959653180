import type * as Types from '../../../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type AnalyticsAdditionalDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


type AnalyticsAdditionalDataQueryPayload = { __typename?: 'Query', quizResult: { __typename?: 'QuizResultPayload', representation: { __typename?: 'QuizFrontendRepresentation', scentProfileTitle: string, fragranceFamily: Array<{ __typename?: 'FragranceFamilyDetail', name: string }> } | null } };

type AnalyticsAdditionalDataFragment = { __typename?: 'Query', quizResult: { __typename?: 'QuizResultPayload', representation: { __typename?: 'QuizFrontendRepresentation', scentProfileTitle: string, fragranceFamily: Array<{ __typename?: 'FragranceFamilyDetail', name: string }> } | null } };

export const AnalyticsAdditionalDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AnalyticsAdditionalData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Query"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quizResult"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"EnumValue","value":"DISCOVERY"}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"representation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"scentProfileTitle"}},{"kind":"Field","name":{"kind":"Name","value":"fragranceFamily"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode<AnalyticsAdditionalDataFragment, unknown>;
 const AnalyticsAdditionalDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AnalyticsAdditionalData"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AnalyticsAdditionalData"}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AnalyticsAdditionalData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Query"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quizResult"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"EnumValue","value":"DISCOVERY"}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"representation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"scentProfileTitle"}},{"kind":"Field","name":{"kind":"Name","value":"fragranceFamily"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode<AnalyticsAdditionalDataQueryPayload, AnalyticsAdditionalDataQueryVariables>;


export type AnalyticsAdditionalDataVariables = AnalyticsAdditionalDataQueryVariables;
export type AnalyticsAdditionalDataPayload = AnalyticsAdditionalDataQueryPayload;

// remove fragments duplicates
// @ts-expect-error
AnalyticsAdditionalDataDocument['definitions'] = [ ...new Set(AnalyticsAdditionalDataDocument.definitions) ];

export type AnalyticsAdditionalDataFragmentPayload = AnalyticsAdditionalDataFragment;
export default AnalyticsAdditionalDataDocument;