import React from 'react'
import { track } from 'analytics'

import { useDevice } from 'device'
import type { ComponentPageCatalogueProductsFeed } from 'typings/strapi.graphql'

import { Image, Label } from 'components/dataDisplay'
import { Href } from 'components/navigation'
import { AspectContainer } from 'components/layout'


type InjectedImageBannerProps = {
  data: ComponentPageCatalogueProductsFeed['injectedBanners'][number]
}

const InjectedImageBanner: React.FC<InjectedImageBannerProps> = ({ data }) => {
  const { link, mobileBannerImage, desktopBannerImage, mobileLabelContent, desktopLabelContent, trackParams, isExternalLink } = data
  const { isMobile } = useDevice()

  const handleClick = () => {
    track('Banner click', {
      link,
      action: 'link',
      ...trackParams,
    })
  }

  const bannerImage = isMobile ? mobileBannerImage?.data?.attributes : desktopBannerImage?.data?.attributes
  const labelContent = isMobile ? mobileLabelContent : desktopLabelContent

  const content = (
    <>
      {
        Boolean(labelContent) && (
          <Label
            className="absolute -left-6 -top-6 z-10"
            bgColor="black"
            richText={labelContent}
            withTail
          />
        )
      }
      <AspectContainer aspect={bannerImage?.width / bannerImage?.height}>
        <Image
          fill
          src={bannerImage?.url}
          alt={bannerImage?.alternativeText}
        />
      </AspectContainer>
    </>
  )

  if (!link) {
    return (
      <div className="bg-light-beige relative">
        {content}
      </div>
    )
  }


  const to = !isExternalLink ? link : null
  const href = isExternalLink ? link : null

  return (
    <Href
      className="bg-light-beige relative block"
      to={to}
      href={href}
      onClick={handleClick}
    >
      {content}
    </Href>
  )
}

export default InjectedImageBanner
