import React, { useCallback } from 'react'
import cx from 'classnames'
import { useSearchParams } from 'router'
import type { GlobalHTMLAttrs } from 'helpers/getters'

import { Image, Text } from 'components/dataDisplay'
import { AspectContainer } from 'components/layout'

import { useCatalogueContext } from 'pages/catalog/util'
import RowListItemView from '../RowListItemView/RowListItemView'


type BrandListItemProps = CatalogFilters.FilterItemProps & {
  data: CataloguePages.AvailableFilterData['values'][number]
  type: 'card' | 'row'
}


const NoteListItem: React.FunctionComponent<BrandListItemProps> = (props) => {
  const { data, type, onFilterSelect, onFilterUnselect } = props
  const { id, name, rebrandImage, image } = data

  const [ , setSearchParams ] = useSearchParams()

  const { appliedFilters } = useCatalogueContext()

  const searchParamsNotes = appliedFilters.NOTES

  const isActive = searchParamsNotes?.includes(id)

  const handleClick = useCallback(() => {
    const notes = (
      isActive
        ? searchParamsNotes?.filter((value) => value !== id)
        : (searchParamsNotes || []).concat(id)
    )

    if (isActive) {
      onFilterUnselect('NOTES', notes)
    }
    else {
      onFilterSelect('NOTES', notes)
    }

    // TODO: Provide update function from useCatalogueContext — added on 28–09–2023 by algeas
    setSearchParams((prevParams) => ({
      ...prevParams,
      notes,
    }), { replace: false, scroll: false })
  }, [ id, isActive, onFilterSelect, onFilterUnselect, searchParamsNotes, setSearchParams ])

  const htmlAttrs: GlobalHTMLAttrs = {
    role: 'checkbox',
    'aria-checked': isActive,
  }

  if (type === 'card') {
    const cardClassName = cx(
      'border-solid-transparent h-full w-[92rem] shrink-0 cursor-pointer rounded',
      'desktop-hover:border-gold-50 desktop-hover:opacity-100',
      {
        ['border-gold-50 shadow-[0_0_0_2px_transparent] shadow-gold-50']: isActive,
      }
    )

    return (
      <button
        className={cardClassName}
        type="button"
        {...htmlAttrs}
        onClick={handleClick}
      >
        <AspectContainer className="w-full overflow-hidden rounded" aspect={1} fit="cover">
          <Image
            src={rebrandImage || image}
            alt={name}
            fill
            remWidth={120}
          />
        </AspectContainer>
        <Text className="px-4 py-8" message={name} style="p3" />
      </button>
    )
  }

  return (
    <RowListItemView
      title={data.name}
      isActive={isActive}
      {...htmlAttrs}
      onClick={handleClick}
    />
  )
}


export default React.memo(NoteListItem)
