import React, { useRef } from 'react'
import cx from 'classnames'
import links from 'links'
import { track } from 'analytics'
import { useDevice } from 'device'
import { usePathname, useSearchParams } from 'router'

import { Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'

import { useCatalogueContext } from 'pages/catalog/util'

import messages from './messages'


const DEFAULT_FILTER_KEYS = [ 'BRANDS', 'NOTES', 'TAGS', 'PRICE', 'SIZES', 'FULL_BOTTLE_TYPES' ]

type ActionButtonsProps = {
  filterKeys?: Array<Extract<CataloguePages.FilterKey, 'BRANDS' | 'NOTES' | 'TAGS' | 'PRICE' | 'SIZES' | 'FULL_BOTTLE_TYPES'>>
  onApply: () => void
}

const FiltersActionButtons: React.FunctionComponent<ActionButtonsProps> = (props) => {
  const { filterKeys = DEFAULT_FILTER_KEYS, onApply } = props

  const { isMobile } = useDevice()

  const pathname = usePathname()
  const [ searchParams, setSearchParams ] = useSearchParams()

  const { availableFilters, appliedFilters, count, isFetchingProducts, appliedFiltersCount } = useCatalogueContext()
  const { targetParams, targetCount } = filterKeys.reduce((acc, filterKey) => {
    if (appliedFilters?.[filterKey]) {
      acc.targetCount += appliedFilters?.[filterKey]?.length || 0
      acc.targetParams[filterKey] = appliedFilters?.[filterKey]
    }
    return acc
  }, { targetParams: {}, targetCount: 0 })

  const types = filterKeys.map((filterKey) => availableFilters?.[filterKey]?.id)

  const prevParamsRef = useRef(targetParams)

  const handleClearAll = () => {
    track('Filter clear all')

    const nextParams = types.reduce((acc, type) => {
      acc[type] = undefined
      return acc
    }, {})

    setSearchParams((prevParams) => ({
      ...prevParams,
      ...nextParams,
      price: undefined,
      gwp: undefined,
      sale: undefined,
    }), { replace: false, scroll: false })
  }

  const handleApply = () => {
    const hasActiveFilter = appliedFiltersCount > 0 || searchParams?.gwp === 'true' || searchParams?.sale === 'true'

    if (!isFetchingProducts && count === 0 && hasActiveFilter) {
      track('Filter zero result display', {
        flow: 'Applied by user',
      })
    }

    const getAvailableItemName = (key: CataloguePages.FilterKey, id: string) => {
      const availableItem = availableFilters?.[key]?.values.find((item) => id === item.id)

      return availableItem?.name || id
    }

    const getSingleAppliedFilterName = (key: CataloguePages.FilterKey) => {
      const appliedItemId = appliedFilters?.[key]?.[0]

      return getAvailableItemName(key, appliedItemId)
    }

    const getMultipleAppliedFiltersNames = <T extends string | string[]>(key: CataloguePages.FilterKey): T => {
      return appliedFilters?.[key]?.map((id) => getAvailableItemName(key, id)) as T
    }

    track('Filter apply', {
      notes: appliedFilters?.NOTES || [],
      productBrands: appliedFilters?.BRANDS || [],
      types: getSingleAppliedFilterName('FULL_BOTTLE_TYPES'),
      sizes: getSingleAppliedFilterName('SIZES'),
      tagNames: getMultipleAppliedFiltersNames('TAGS'),
      priceRanges: getSingleAppliedFilterName('PRICE'),
      isGift: searchParams?.gwp === 'true' || undefined,
      isSale: searchParams?.sale === 'true' || undefined,
    })

    onApply()
  }

  const isPrimaryButtonVisible = useRef(false)
  const isClearAllButtonVisible = targetCount > 0

  if (!isPrimaryButtonVisible.current && targetParams !== prevParamsRef.current) {
    isPrimaryButtonVisible.current = true
  }

  if (!isPrimaryButtonVisible.current && !isClearAllButtonVisible) {
    return null
  }

  let noteMessage

  if (targetParams?.['BRANDS']?.length > 8) {
    noteMessage = messages.tooMuchBrands
  }
  else if (!isFetchingProducts && count === 0) {
    noteMessage = messages.emptyNote
  }

  const productType = pathname === links.subscription.perfumes
    ? 'perfume'
    : pathname === links.subscription.colognes ? 'cologne' : null

  return (
    <div className={cx('sticky bottom-0 my-0 flex flex-1 flex-col justify-end bg-white pb-16 pt-8', isMobile ? '-mx-16 px-16' : '-mx-40 px-40')}>
      {
        Boolean(noteMessage) && (
          <Text className="mb-8 text-center" message={noteMessage} style="p4" color="gold-70" />
        )
      }
      {
        isPrimaryButtonVisible.current && (
          <Button
            title={{ ...messages.primaryButton, values: { productType, count: count || 0 } }}
            size={56}
            style="primary"
            loading={isFetchingProducts}
            fullWidth
            html
            onClick={handleApply}
            data-testid="applyButton"
          />
        )
      }
      {
        isClearAllButtonVisible && (
          <Button
            className={isPrimaryButtonVisible.current ? 'mt-8' : null}
            title={messages.clearAll}
            size={56}
            style="tertiary"
            fullWidth
            onClick={handleClearAll}
            data-testid="clearButton"
          />
        )
      }
    </div>
  )
}


export default FiltersActionButtons
