import type * as Types from '../../../../../../typings/strapi.graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type PageSeosQueryVariables = Types.Exact<{
  filters: Types.InputMaybe<Types.PageSeoFiltersInput>;
}>;


type PageSeosQueryPayload = { __typename?: 'Query', pageSeos: { __typename?: 'PageSeoEntityResponseCollection', data: Array<{ __typename?: 'PageSeoEntity', id: string | null, attributes: { __typename?: 'PageSeo', url: string, seo: { __typename?: 'ComponentSharedSeo', id: string, canonicalURL: string | null, keywords: string | null, metaDescription: string | null, metaTitle: string | null, structuredData: any | null, metaImage: { __typename?: 'UploadFileEntityResponse', data: { __typename?: 'UploadFileEntity', id: string | null, attributes: { __typename?: 'UploadFile', url: string } | null } | null } | null } | null } | null }> } | null };


 const PageSeosDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PageSeos"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"PageSeoFiltersInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pageSeos"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"attributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"seo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"canonicalURL"}},{"kind":"Field","name":{"kind":"Name","value":"keywords"}},{"kind":"Field","name":{"kind":"Name","value":"metaDescription"}},{"kind":"Field","name":{"kind":"Name","value":"metaTitle"}},{"kind":"Field","name":{"kind":"Name","value":"metaImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"attributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"structuredData"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<PageSeosQueryPayload, PageSeosQueryVariables>;


export type PageSeosVariables = PageSeosQueryVariables;
export type PageSeosPayload = PageSeosQueryPayload;

// remove fragments duplicates
// @ts-expect-error
PageSeosDocument['definitions'] = [ ...new Set(PageSeosDocument.definitions) ];

export default PageSeosDocument;