import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { Bone, WidthContainer } from 'components/layout'

import { Text } from 'components/dataDisplay'

import Filters from 'pages/catalog/components/Filters/Filters'
import ActiveFiltersList from 'pages/catalog/components/ActiveFiltersList/ActiveFiltersList'
import MobileStickyTopContainer from 'pages/catalog/components/MobileStickyTopContainer/MobileStickyTopContainer'


type FiltersSectionProps = Pick<CataloguePages.ProductFeedViewProps, 'countMessage' | 'isFetching'> & {
  className?: string
  filtersModalHeaderContent?: React.ReactElement
  withSort?: boolean
  isEcommerce?: boolean
  showGwpFilter?: boolean
  showSaleFilter?: boolean
}

const FiltersSection: React.FC<FiltersSectionProps> = (props) => {
  const { isMobile } = useDevice()
  const { className, filtersModalHeaderContent, countMessage, isFetching, withSort = false, isEcommerce = false, showGwpFilter, showSaleFilter } = props

  const filtersProps = {
    withSort,
    isEcommerce,
    showGwpFilter,
    showSaleFilter,
    filtersModalHeaderContent,
  }

  if (isMobile) {
    return (
      <MobileStickyTopContainer id="catalogueFilters" stickyClassName="group shadow is-sticky">
        <WidthContainer className={cx(className, 'bg-white py-16')}>
          <Filters {...filtersProps} />
          <div className={cx('mt-16 group-[.is-sticky]:hidden')}>
            {
              isFetching ? (
                <Bone w={200} h={20} />
              ) : (
                <Text message={countMessage} style="sh6" color="gold-70" html />
              )
            }
          </div>
          <ActiveFiltersList className="mt-8 group-[.is-sticky]:-mb-4 group-[.is-sticky]:mt-12" />
        </WidthContainer>
      </MobileStickyTopContainer>
    )
  }

  return (
    <WidthContainer id="catalogueFilters" className={cx(className, 'flex items-center justify-between')}>
      <div className="flex-1 overflow-hidden">
        {
          isFetching ? (
            <Bone w={274} h={27} />
          ) : (
            <Text message={countMessage} style="sh4" color="gold-70" html />
          )
        }
        <ActiveFiltersList className="mt-8" />
      </div>
      <Filters className="flex-none" {...filtersProps} />
    </WidthContainer>
  )
}

export default FiltersSection
