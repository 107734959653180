import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { useUser } from 'modules/user'

import type { ComponentSelectCampaignDefaultHero } from 'typings/strapi.graphql'

import { WidthContainer } from 'components/layout'
import { HeroMediaBlock, Image, RichText } from 'components/dataDisplay'


type SelectCampaignDefaultHeroProps = {
  data: ComponentSelectCampaignDefaultHero
}

const SelectCampaignDefaultHero: React.FunctionComponent<SelectCampaignDefaultHeroProps> = (props) => {
  const { data } = props

  const { isMobile } = useDevice()
  const { gender: userGender } = useUser()

  const {
    gender,
    mobileContentText, desktopContentText, contentBgColor,
    mobileHeroImage, desktopHeroImage, mobileHeroImageHeight, desktopHeroImageHeight,
    mobileContentImage, desktopContentImage, mobileContentImageWidth, desktopContentImageWidth,
  } = data

  if (gender && !gender?.includes(userGender)) {
    return null
  }

  const heroImage = isMobile ? mobileHeroImage?.data?.attributes : desktopHeroImage?.data?.attributes
  const contentImage = isMobile ? mobileContentImage?.data?.attributes : desktopContentImage?.data?.attributes

  const heroImageHeight = isMobile ? mobileHeroImageHeight : desktopHeroImageHeight
  const contentImageWidth = isMobile ? mobileContentImageWidth : desktopContentImageWidth

  const contentText = isMobile ? mobileContentText : desktopContentText
  const contentBgColorClassname = contentBgColor ? `bg-${contentBgColor.replace('_', '-')}` : 'bg-black-100'

  return (
    <>
      <HeroMediaBlock>
        <HeroMediaBlock.Image
          src={heroImage?.url}
          height={heroImageHeight}
          priority
        />
      </HeroMediaBlock>
      <WidthContainer
        className={cx('relative text-center text-white', contentBgColorClassname, isMobile ? 'pb-32 pt-24' : '-mt-56 pb-40 pt-32')}
        size={944}
      >
        {contentImage && (
          <Image
            className={isMobile ? 'mb-8 w-[366rem]' : 'mb-16 w-[444rem]'}
            src={contentImage?.url}
            alt={contentImage?.alternativeText}
            width={contentImageWidth}
          />
        )}
        <RichText message={contentText} />
      </WidthContainer>
    </>
  )
}

export default SelectCampaignDefaultHero
