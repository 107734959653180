import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import type { ComponentPageHeroWithLabel } from 'typings/strapi.graphql'

import { HeroMediaBlock, RichText } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'


type DefaultHeroProps = {
  className?: string
  data: ComponentPageHeroWithLabel
}

const DEFAULT_HEIGHT_DESKTOP = 520
const DEFAULT_HEIGHT_MOBILE = 400

const HeroWithLabel: React.FC<DefaultHeroProps> = (props) => {
  const { className, data } = props
  const { heroImage, heroText, label } = data

  const { isMobile } = useDevice()

  const image = isMobile
    ? heroImage?.mobile?.data?.attributes
    : heroImage?.desktop?.data?.attributes
  const labelImage = isMobile
    ? label?.mobile?.data?.attributes
    : label?.desktop?.data?.attributes

  const withHeroBlock = Boolean(image || labelImage)

  return (
    <div className={className}>
      {
        withHeroBlock && (
          <HeroMediaBlock>
            {
              Boolean(image) && (
                <HeroMediaBlock.Image
                  src={image.url}
                  height={isMobile ? DEFAULT_HEIGHT_MOBILE : DEFAULT_HEIGHT_DESKTOP}
                  priority
                />
              )
            }
            {
              Boolean(labelImage) && (
                <div className={cx('relative text-center', isMobile ? 'mt-[-94rem]' : 'mt-[-120rem]')} >
                  <img
                    src={labelImage.url}
                    alt={labelImage.alternativeText}
                    width={isMobile ? 125 : 149}
                  />
                </div>
              )
            }
          </HeroMediaBlock>
        )
      }
      {
        Boolean(heroText) && (
          <WidthContainer className="relative">
            <RichText message={isMobile ? heroText.mobile : heroText.desktop} />
          </WidthContainer>
        )
      }
    </div>
  )
}

export default HeroWithLabel
