import React from 'react'
import cx from 'classnames'
import { useScrollingFrame } from 'hooks'

import { Image } from 'components/dataDisplay'


type BrandsRowProps = {
  alt: string
  aspect: number
  className?: string
  height: number
  inverted: boolean
  isVisible: boolean
  src: string
  width: number
}

const BrandsRow: React.FunctionComponent<BrandsRowProps> = (props) => {
  const { className, inverted, isVisible, ...restProps } = props

  const ref = useScrollingFrame({
    inverted,
    isVisible,
  })

  return (
    <div className={cx(className, 'relative overflow-hidden pt-48')}>
      <div ref={ref} className="absolute left-0 top-0 mx-16 h-[44rem] w-[864rem]">
        <Image className="w-full object-contain" {...restProps} lazy />
      </div>
    </div>
  )
}

export default BrandsRow
