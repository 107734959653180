import React, { useMemo } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import cx from 'classnames'
import { useDevice } from 'device'
import { useIntl } from 'intl'

import { constants, getTradingItem, getAddItemProduct, sanitizeHtmlStrict, truncateText } from 'helpers'
import { getTradingItemDiscount, getTradingItemsDiscount } from 'helpers/getters'
import { useCnstrcProductTrackingDataAttrs } from 'modules/constructorIO'
import { useProductSelectCampaigns } from 'modules/selectCampaigns'

import { Text, Rating, OfferLabel } from 'components/dataDisplay'
import { Href } from 'components/navigation'

import { Button, buttonMessages } from 'components/inputs'
import ProductPrice from 'compositions/ProductPrice/ProductPrice'
import ProductPriceRange from 'compositions/ProductPriceRange/ProductPriceRange'
import ProductImage from 'compositions/ProductImage/ProductImage'
import AddToButton from 'compositions/buttons/AddToButton/AddToButton'

import Labels from '../components/Labels/Labels'
import Notes from '../components/Notes/Notes'
import RetailPrice from '../components/RetailPrice/RetailPrice'
import Brand from '../components/Brand/Brand'

import productCardMessages from '../messages'


const ProductCardDetailed: React.FunctionComponent<ProductCard.DetailedCardProps> = (props) => {
  const {
    className, productImageClassName, bgColorClassName = 'bg-light-beige', data, buttonProps, position, withoutLink, replaceRatingWithRetailPrice,
    isEcommerce, withEcommercePrefix = true, withProductPrice = true, withProductPriceRange = false, withDescription = true,
    withCursorPointer, excludeLabels, excludeVolumes, withoutNotes, withForwardedTradingItemUid = false, withTradingItemImage, lazy,
    onCardClick, onProductClick, onProductLinkClick, onProductButtonClick, dataAttributes,
  } = props

  const {
    url, image, rebrandImage, brandInfo: { name: brand }, name, fullName,
    description, upchargePrice, rating, notes, fragranceFamilies,
    rebrandLabels, prices, typeGroup, tradingItems,
  } = data

  const isAiSummaryEnabled = useFeatureIsOn(constants.features.aiSummary)
  const isAiSummaryCardsFeatureEnabled = useFeatureIsOn(constants.features.aiSummaryCards)
  const isHideCasesReviewsEnabled = useFeatureIsOn(constants.features.hideCasesReviews)

  const isAiSummaryCardsEnabled = isAiSummaryEnabled && isAiSummaryCardsFeatureEnabled

  const { isMobile } = useDevice()
  const intl = useIntl()

  const isWhite = bgColorClassName === 'bg-white'

  const { tradingItem, isEcommerceProduct } = getTradingItem({
    product: data,
    isEcommerce,
    isFullSizeBottlePrioritized: isEcommerce,
    excludeVolumes,
  })
  const productSelectCampaigns = useProductSelectCampaigns({ tradingItems: tradingItems.items })

  const isExtraProduct = typeGroup === 'PerfumeCase' || typeGroup === 'FragranceStorageBox'

  const isNotesVisible = !withoutNotes && Boolean(notes?.length)
  const isRatingVisible = !isExtraProduct || !isHideCasesReviewsEnabled
  const hasAiSummary = isAiSummaryCardsEnabled && true // TODO: Backend integration (AI reviews summary) - added on 2024-08-27 by girilloid

  const addToButtonProps = {
    type: isEcommerceProduct ? 'cart' as const : 'queue' as const,
    product: getAddItemProduct({
      product: data,
      tradingItem,
      intl,
    }),
  }

  const cnstrcTrackingDataAttrs = useCnstrcProductTrackingDataAttrs({
    id: data.uid,
    name: data?.name,
    prices: tradingItem?.prices,
    variationId: tradingItem?.sku,
  })

  const linkClickHandler = () => {
    if (typeof onProductClick === 'function') {
      onProductClick({
        position,
        action: 'link',
      })
    }

    if (typeof onProductLinkClick === 'function') {
      onProductLinkClick(
        data,
        volume ? `${volume} ${unit}` : '',
        tradingItem?.prices?.discountPrice
      )
    }
  }

  const productUrl = (() => {
    let finalUrl = url

    if (withForwardedTradingItemUid) {
      const hasQuery = /\?[^?]+$/.test(finalUrl)
      finalUrl = `${finalUrl}${hasQuery ? '&' : '?'}forwardedTradingItemUid=${tradingItem?.uid}`
    }

    if (withEcommercePrefix && isEcommerceProduct && typeGroup !== 'GiftSet') {
      finalUrl = `/ecommerce${finalUrl}`
    }

    return finalUrl
  })()

  const discount = (() => {
    if (!isEcommerceProduct) {
      return 0
    }

    if (withProductPrice && tradingItem) {
      return getTradingItemDiscount(tradingItem)
    }

    if (withProductPriceRange && tradingItems?.items) {
      return getTradingItemsDiscount(tradingItems?.items)
    }

    return 0
  })()

  const sanitizedDescription = useMemo(() => {
    // get first 200 chars, to remove very long descriptions
    return truncateText(sanitizeHtmlStrict(description), 200)
  }, [ description ])

  const { volume, unit } = tradingItem?.volume || {}
  const productName = unit && unit !== 'pcs' && isEcommerceProduct && !withProductPriceRange ? `${name} · ${volume} ${unit}` : name

  const topContent = (
    <>
      <ProductImage
        className={productImageClassName}
        src={withTradingItemImage && tradingItem.image || rebrandImage || image}
        alt={fullName}
        bgColor={isWhite ? 'white' : 'light-beige'}
        lazy={lazy}
        remWidth={320}
      />
      <Brand className="mt-8 line-clamp-2" brand={brand} style="h6" color="gold-50" />
      <Text className="mt-4 line-clamp-2" message={productName} style="p3" data-testid="productName" />
      {
        replaceRatingWithRetailPrice ? (
          <RetailPrice className="mt-16" price={prices?.retail} />
        ) : (
          isRatingVisible && (
            <Rating className="mx-auto mt-16" value={rating.avgRate} size={14} />
          )
        )
      }
    </>
  )

  return (
    <div
      className={cx(className, 'relative flex flex-col justify-between text-center', bgColorClassName)}
      data-testid="productCardDetailed"
      {...cnstrcTrackingDataAttrs}
      {...dataAttributes}
    >
      <div className={cx('px-24 pt-16', withCursorPointer && 'cursor-pointer')} onClick={onCardClick}>
        <Labels
          labels={rebrandLabels}
          excludeLabels={excludeLabels}
          upchargePrice={upchargePrice}
          isEcommerce={isEcommerceProduct}
          discount={discount}
          tradingItemType={tradingItem?.type}
          productSelectCampaigns={productSelectCampaigns}
        />
        {
          !withoutLink && url ? (
            <Href to={productUrl} draggable={false} onClick={linkClickHandler}>
              {topContent}
            </Href>
          ) : (
            topContent
          )
        }
        {
          Boolean(fragranceFamilies?.length) && (
            <div className="mt-16">
              {
                fragranceFamilies.map(({ name }) => (
                  <OfferLabel className="mx-4 mt-4" key={name} title={name} bgColor="pink" />
                ))
              }
            </div>
          )
        }
        {
          isNotesVisible && (
            <Notes
            // ATTN mirror desktop styles if product cards will be used in row (carousel)
              className={cx('mt-16 flex-none', !isMobile && 'min-h-[94rem]')}
              items={notes}
              bgColor={isWhite ? 'white' : 'light-beige'}
            />
          )
        }
        {
          isEcommerceProduct && (withProductPrice || withProductPriceRange) ? (
            withProductPriceRange ? (
              <ProductPriceRange
                className="mb-8 mt-32 flex flex-auto items-end justify-center"
                tradingItems={tradingItems?.items}
              />
            ) : (
              <ProductPrice
                className="mb-8 mt-32 flex flex-auto items-end justify-center"
                uid={data.uid}
                data={tradingItem?.prices}
              />
            )
          ) : (
            withDescription && (
              <>
                {
                  Boolean(sanitizedDescription) && (
                    <Text
                      // Required for safari, includes 16rem of padding
                      className="mt-auto line-clamp-3 max-h-[76rem] pt-16"
                      message={sanitizedDescription} // TODO: Backend integration (AI reviews summary) - added on 2024-08-27 by girilloid
                      messageTag="div"
                      style="p3"
                      html
                    />
                  )
                }
                {
                  hasAiSummary && (
                    <Text className="mt-8" message={productCardMessages.aiSummaryNote} align="center" color="gray-50" style="p6" />
                  )
                }
              </>
            )

          )
        }
      </div>
      <div className="px-24 pb-24">
        {
          Boolean(buttonProps) && (
            isEcommerceProduct && withProductPriceRange ? (
              <Button
                className="mt-24 w-full flex-none"
                title={buttonMessages.addToCart}
                to={productUrl}
                size={48}
                onClick={() => {
                  if (typeof linkClickHandler === 'function') {
                    linkClickHandler()
                  }

                  if (typeof buttonProps.onClick === 'function') {
                    buttonProps.onClick()
                  }

                  if (typeof onProductButtonClick === 'function') {
                    onProductButtonClick()
                  }
                }}
              />
            ) : (
              <AddToButton
                className="mt-24 w-full flex-none"
                size={48}
                {...addToButtonProps}
                {...buttonProps}
                onClick={() => {
                  if (typeof onProductClick === 'function') {
                    onProductClick({
                      position,
                      action: 'Add to cart',
                    })
                  }

                  if (typeof buttonProps.onClick === 'function') {
                    buttonProps.onClick()
                  }

                  if (typeof onProductButtonClick === 'function') {
                    onProductButtonClick()
                  }
                }}
              />
            )
          )
        }
      </div>
    </div>
  )
}


export default React.memo(ProductCardDetailed)
