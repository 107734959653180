import React from 'react'
import cx from 'classnames'
import { getAnalyticsContext } from 'analytics'
import { useQueueAddItems } from 'modules/queue'

import { Button, buttonMessages } from 'components/inputs'

import useAddClickHandler from '../../util/useAddClickHandler'

import type { AddToButtonBaseProps } from '../AddToButtonBase/AddToButtonBase'

import messages from './messages'


export type AddToQueueTryItOldViewButtonProps = (
  QueueModule.AddItemInput & QueueModule.AddItemParams
  & Pick<AddToButtonBaseProps, 'className' | 'onClick' | 'data-testid'>
  )

const AddToQueueTryItOldViewButton: React.FunctionComponent<AddToQueueTryItOldViewButtonProps> = (props) => {
  const {
    className,
    'data-testid': dataTestId = 'addToQueueButton',
    product,
    metadata,
    force,
    position,
    withNonAuthRedirect,
    withNotification,
    withErrorModals,
    withResubscribeFlow = true,
    withNotificationOnly,
    onClick,
  } = props

  const [ submit, { isFetching } ] = useQueueAddItems()

  const { isAdded, handleClick } = useAddClickHandler(async () => {
    const { page, pageType } = getAnalyticsContext()

    await submit({
      product,
      force,
      position,
      metadata: {
        page,
        pageType,
        ...metadata,
      },
    }, {
      withErrorModals,
      withNonAuthRedirect,
      withNotification,
      withResubscribeFlow,
      withNotificationOnly,
    })

    if (typeof onClick === 'function') {
      onClick()
    }
  })

  /* some products don't have tradingItem when they are out of stock, so we disable the button in this case */
  const isOutOfStock = !product.tradingItemUid

  return (
    <div className={className}>
      <hr className="bg-gold-30 mb-24" />
      <Button
        className={cx('h-s24 text-t4', isAdded ? 'text-gold-70' : 'hover:text-gold-30')}
        title={isAdded ? buttonMessages.added : messages.tryFragrance}
        leftIcon={isAdded ? '24/checkmark-in-circle' : (isFetching ? '20/loader' : '24/plus-in-circle')}
        size={null}
        style={null}
        loading={isFetching}
        disabled={isOutOfStock}
        onClick={handleClick}
        fullWidth
        data-testid={dataTestId}
      />
    </div>
  )
}


export default AddToQueueTryItOldViewButton
