import React, { useMemo, useState } from 'react'
import { IntlProvider } from 'intl'
import cookieStorage from 'cookie-storage'
import localStorage from 'local-storage'
import { constants } from 'helpers'
import logger from 'logger'

import { LocalePreferencesContext } from './LocalePreferencesContext'


type LocalePreferencesProviderProps = {
  children: React.ReactNode
  value: Intl.LocalePreferences
  onChange?: (config: Intl.LocalePreferences) => void
}

const LocalePreferencesProvider: React.FC<LocalePreferencesProviderProps> = (props) => {
  const { children, value: initialValue, onChange } = props

  const [ value, setValue ] = useState(initialValue)

  const contextValue = useMemo<Intl.LocalePreferencesContextValue>(() => {
    return {
      localePreferences: value,
      setLocalePreferences: (updater) => {
        const newValue = updater(value)

        if (
          newValue.locale === value.locale
          && newValue.country === value.country
          && newValue.currency === value.currency
        ) {
          // do not update if nothing has changed
          return
        }

        logger.info(`Change current locale preferences: ${JSON.stringify(newValue)}`)

        // update different storages
        window.__LOCALE_PREFERENCES__ = newValue
        cookieStorage.setItem(constants.cookieNames.localeOverride, newValue.locale)
        cookieStorage.removeItem(constants.cookieNames.country)
        localStorage.removeItem(constants.localStorageNames.shippingFormValues)

        setValue(newValue)

        if (typeof onChange === 'function') {
          onChange(newValue)
        }
      },
    }
  }, [ value, onChange ])

  return (
    <LocalePreferencesContext.Provider value={contextValue}>
      <IntlProvider locale={value.locale} currency={value.currency}>
        {children}
      </IntlProvider>
    </LocalePreferencesContext.Provider>
  )
}

export default LocalePreferencesProvider
