import React, { Fragment, useMemo } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { string } from 'helpers'

import { Text } from 'components/dataDisplay'

import { useCatalogueContext } from 'pages/catalog/util'
import FilterModalsActionButtons from 'pages/catalog/components/FilterModalsActionButtons/FilterModalsActionButtons'

import BrandListItem from '../BrandListItem/BrandListItem'

import messages from './messages'


type BrandsLayerProps = {
  onApply: () => void
}

const BrandsLayer: React.FunctionComponent<BrandsLayerProps> = ({ onApply }) => {
  const { availableFilters } = useCatalogueContext()

  const availableFilter = availableFilters.BRANDS
  const availableBrands = availableFilter?.values
  const { groupByFirstLetter = true } = availableFilter?.settings || {}

  const { isMobile } = useDevice()

  const { sortedBrands, brandGroups, popularBrands } = useMemo(() => {
    if (!availableBrands) {
      return {}
    }

    const popularBrands = availableBrands.filter(({ popular }) => popular)
    const sortedBrands = [ ...availableBrands ].sort((a, b) => a.name.localeCompare(b.name))

    // if grouping is enabled group by a first letter, otherwise create one single group
    let brandGroups = groupByFirstLetter ? sortedBrands.reduce<CataloguePages.FilterGroups>((result, item) => {
      let firstLetter = string.getFirstLetter(item.name)

      if (/\d/.test(firstLetter)) {
        firstLetter = '0-9'
      }

      result[firstLetter] = result[firstLetter] || []
      result[firstLetter].push(item)

      return result
    }, {}) : null

    return {
      sortedBrands,
      brandGroups,
      popularBrands,
    }
  }, [ availableBrands, groupByFirstLetter ])

  return (
    <div className="flex flex-col">
      <Text className="mb-8 flex-none" message={messages.title} style="sh3" />
      <div className="flex-1">
        {
          Boolean(popularBrands.length) && (
            <>
              <Text
                className="mb-8 pb-8 pt-24"
                message={messages.popularBrands}
                style="h8"
                color="gold-50"
              />
              <div
                className={cx('no-scrollbar my-0 flex overflow-x-auto', isMobile ? '-mx-16 px-12' : '-mx-40 px-36')}
                role="group"
              >
                {
                  popularBrands.map((data) => (
                    <BrandListItem key={data.name} data={data} type="card" />
                  ))
                }
              </div>
            </>
          )
        }
        {
          Boolean(brandGroups) && (
            Object.keys(brandGroups).map((letter) => (
              <Fragment key={letter}>
                <Text
                  className="pb-8 pt-24"
                  message={String(letter)}
                  style="h8"
                  color="gold-50"
                />
                {/*
                  TODO it's incorrect aria and the should be "listbox" - added on 27.09.2021 by sonatskiy
                    check this PR https://github.com/tailwindlabs/headlessui/pull/648 to implement Listbox with multiselect
                */}
                <div className={cx(isMobile ? '-mx-16' : '-mx-40')} role="group">
                  {
                    brandGroups[letter].map((data) => (
                      <BrandListItem key={data.id} data={data} type="row" />
                    ))
                  }
                </div>
              </Fragment>
            ))
          )
        }
        {
          Boolean(!groupByFirstLetter && sortedBrands) && (
            <div className={cx('mt-24', isMobile ? '-mx-16' : '-mx-40')} role="group">
              {
                sortedBrands.map((data) => (
                  <BrandListItem key={data.id} data={data} type="row" />
                ))
              }
            </div>
          )
        }
      </div>
      <FilterModalsActionButtons filterKey="BRANDS" onApply={onApply} />
    </div>
  )
}


export default BrandsLayer
