import React from 'react'
import cx from 'classnames'
import { devicePx } from 'helpers'
import { useDevice } from 'device'

import type { ComponentPageTallHero } from 'typings/strapi.graphql'

import { HeroMediaBlock, Image, RichText } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'


const DEFAULT_IMAGE_HEIGHT_DESKTOP = 460
const DEFAULT_IMAGE_HEIGHT_MOBILE = 320

const DEFAULT_LOGO_HEIGHT_DESKTOP = 444
const DEFAULT_LOGO_HEIGHT_MOBILE = devicePx(366)

type TallHeroProps = {
  data: ComponentPageTallHero
}

const TallHero: React.FunctionComponent<TallHeroProps> = (props) => {
  const { data } = props

  const { isMobile } = useDevice()

  const { image, logo, text } = data

  const heroImage = isMobile ? image?.mobile?.data?.attributes : image?.desktop?.data?.attributes
  const heroLogo = isMobile ? logo?.mobile?.data?.attributes : logo?.desktop?.data?.attributes

  const heroImageHeight = isMobile ? image?.mobileHeight || DEFAULT_IMAGE_HEIGHT_MOBILE : image?.desktopHeight || DEFAULT_IMAGE_HEIGHT_DESKTOP
  const heroLogoHeight = isMobile ? logo?.mobileHeight || DEFAULT_LOGO_HEIGHT_MOBILE : logo?.desktopHeight || DEFAULT_LOGO_HEIGHT_DESKTOP

  const heroText = isMobile ? text.mobile : text.desktop

  return (
    <>
      <HeroMediaBlock>
        <HeroMediaBlock.Image
          src={heroImage?.url}
          height={heroImageHeight}
          priority
        />
      </HeroMediaBlock>
      <WidthContainer
        className={cx('bg-black-100 relative text-center text-white', isMobile ? 'pb-32 pt-24' : '-mt-56 pb-40 pt-32')}
        size={944}
      >
        {heroLogo && (
          <Image
            className={isMobile ? 'mb-8 w-[366rem]' : 'mb-16 w-[444rem]'}
            src={heroLogo?.url}
            alt={heroLogo?.alternativeText}
            width={heroLogoHeight}
          />
        )}
        <RichText message={heroText} />
      </WidthContainer>
    </>
  )
}

export default TallHero
