import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import type { ComponentPageGiftHubSection } from 'typings/strapi.graphql'

import GiftHubCard from './components/GiftHubCard/GiftHubCard'


type GiftHubSectionProps = {
  data: ComponentPageGiftHubSection
}

const GiftHubSection: React.FC<GiftHubSectionProps> = (props) => {
  const { data } = props
  const { cards } = data

  const { isMobile } = useDevice()

  return (
    <div className={cx('flex flex-wrap gap-16', isMobile ? 'mt-40' : 'mt-60')}>
      {
        cards.map((card) => {
          const { id } = card

          return (
            <GiftHubCard key={id} data={card} />
          )
        })
      }
    </div>
  )
}

export default GiftHubSection
