import React from 'react'

import { NoData } from 'components/feedback'

import messages from './messages'


type NoItemsProps = {
  className?: string
}

const NoItems: React.FunctionComponent<NoItemsProps> = ({ className }) => (
  <NoData className={className} title={messages.title} />
)


export default NoItems
