import React, { Fragment } from 'react'

import FeaturedBrands from '../shared/FeaturedBrands/FeaturedBrands'

import DefaultHero from './components/DefaultHero/DefaultHero'
import GiftCardHero from './components/GiftCardHero/GiftCardHero'
import GiftHubHero from './components/GiftHubHero/GiftHubHero'
import GiftPageHero from './components/GiftPageHero/GiftPageHero'
import HeroWithLabel from './components/HeroWithLabel/HeroWithLabel'
import OfferBanner from './components/OfferBanner/OfferBanner'
import TallHero from './components/TallHero/TallHero'
import SelectCampaignDefaultHero from './components/SelectCampaignDefaultHero/SelectCampaignDefaultHero'


const ComponentsMap = {
  'ComponentPageDefaultHero': DefaultHero,
  'ComponentPageGiftCardHero': GiftCardHero,
  'ComponentPageGiftHubHero': GiftHubHero,
  'ComponentPageGiftPageHero': GiftPageHero,
  'ComponentPageHeroWithLabel': HeroWithLabel,
  'ComponentPageOfferBanner': OfferBanner,
  'ComponentPageTallHero': TallHero,
  'ComponentPageFeaturedBrands': FeaturedBrands,
  'ComponentSelectCampaignDefaultHero': SelectCampaignDefaultHero,
  'ComponentPageContentSlot': -1,
}

type HeroSectionProps = {
  className?: string
  contentSlot?: React.ReactNode
  data: DynamicPagesModule.HeroSectionData
}

const HeroSection: React.FC<HeroSectionProps> = (props) => {
  const { className, contentSlot, data = [] } = props

  return (
    <>
      {data.map((section, index) => {
        const Component = ComponentsMap[section.__typename]

        if (Component === -1) {
          return (
            <Fragment key={index}>{contentSlot}</Fragment>
          )
        }

        if (!Component) {
          return null
        }

        return (
          <Component
            key={index}
            className={index === 0 && className}
            data={section}
          />
        )
      })}
    </>
  )
}

export default HeroSection
