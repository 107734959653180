export default {
  FULL_BOTTLE_TYPES: {
    en: 'Filter by type {quantity}',
  },
  SIZES: {
    en: 'Filter by size {quantity}',
  },
  PRICE: {
    en: 'Filter by price {quantity}',
  },
}
