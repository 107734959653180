import React, { useMemo } from 'react'

import { WidthContainer } from 'components/layout'
import ProductCardSmallSkeleton from 'compositions/productCards/ProductCardSmallSkeleton/ProductCardSmallSkeleton'

import NoItems from '../NoItems/NoItems'
import ProductList from '../ProductList/ProductList'
import FetchMoreIndicator from '../FetchMoreIndicator/FetchMoreIndicator'
import { isProduct } from '../../util'


const InfinityScrollProductFeed: React.FC<InfinityScrollProductFeed.Props> = (props) => {
  const {
    className, products, metadata, fetchMore, totalCount, fetchSkeletonsAmount, injectBanners,
    isEcommerce, isFetching, excludeLabels, withoutNotes, withEcommercePrefix, dataAttributes,
    withProductPriceRange, onProductButtonClick, withForwardedTradingItemUid = false,
  } = props

  /*
  * |c| — common product card
  * |v| — product card with variety
  * |b| — injected banner
  *
  * Injected banner:
  * Index — 6
  *
  * Input:      Output:
  * |c| |c|     |c| |c|
  * |c| |c|  => |c| |c|
  * |c| |v|     |—————|
  * |c| |c|     |  v  |
  *             |—————|
  *             |c| |c|
  *             |  b  |
  *               |c|
  * */
  const content = useMemo(() => {
    if (!products) {
      return []
    }

    const productListCommonProps = {
      metadata,
      excludeLabels,
      isEcommerce,
      withEcommercePrefix,
      withoutNotes,
      withForwardedTradingItemUid,
      withProductPriceRange,
      onProductButtonClick,
    }

    // All nodes that will be rendered in the feed
    const feedNodes = []
    // Temporary storage of accumulated products without variety
    let productChunk = []
    let indexForBanners = 0

    const renderProductList = (key, productList) => {
      return (
        <ProductList
          key={key}
          className={feedNodes.length ? 'mt-16' : null}
          products={productList}
          {...productListCommonProps}
        />
      )
    }

    const processProductChunk = (index, finalChunk = false) => {
      // don't flush empty chunk or not-final junk with one element
      if (!products.length || !finalChunk && products.length === 1) {
        return
      }

      // If this is not the final chunk and there's an odd number of products,
      // remove the last product and place it in the next group
      const lastProduct = !finalChunk && productChunk.length % 2 === 1 ? productChunk.pop() : null

      feedNodes.push(renderProductList(`products-${index}`, productChunk))

      productChunk = lastProduct ? [ lastProduct ] : []
    }

    products.forEach((product, index) => {
      // If current product has a variety, we add all the accumulated products to the feed nodes,
      // and then add a variety product card to the feed nodes as well
      if (isProduct(product) && product.hasVariety) {
        processProductChunk(index)

        feedNodes.push(renderProductList(`products-with-variety-${index}`, [ product ]))

        return
      }

      // Banner should consider only products without variety, so we count indexes for it separately
      const injectedBanner = injectBanners?.find((banner) => banner.index === indexForBanners)
      indexForBanners++

      if (injectedBanner) {
        processProductChunk(index)

        feedNodes.push(injectedBanner.node)
      }

      // After adding banners and cards for variety products, as well as (possibly) accumulated products, we can add the current product to the chunk
      productChunk.push(product)

      // If we have reached the end of the product list, we need to add all the remaining products
      // to the end of the feed, regardless of their quantity
      if (index === products.length - 1) {
        processProductChunk(index, true)
      }
    })

    return feedNodes
  }, [
    products,
    metadata,
    excludeLabels,
    isEcommerce,
    withEcommercePrefix,
    withoutNotes,
    withForwardedTradingItemUid,
    withProductPriceRange,
    onProductButtonClick,
    injectBanners,
  ])

  if (!isFetching && products && !products?.length) {
    return (
      <NoItems className="mt-48" />
    )
  }

  const currentCount = products?.length || 0
  const isFetchMoreVisible = !isFetching && totalCount > currentCount

  return (
    <div className={className} {...dataAttributes}>
      <>
        {content}
      </>
      {
        isFetching && (
          <WidthContainer className="grid-cols-2-center mt-16 grid items-stretch gap-16">
            {
              Array(fetchSkeletonsAmount || 6).fill(0).map((_, index) => (
                <ProductCardSmallSkeleton key={index} />
              ))
            }
          </WidthContainer>
        )
      }
      {
        isFetchMoreVisible && (
          // key required for remount after product list update
          <FetchMoreIndicator key={currentCount} fetchMore={fetchMore} />
        )
      }
    </div>

  )
}


export default React.memo(InfinityScrollProductFeed)
