export default {
  filters: {
    en: 'Filter',
  },
  or: {
    en: 'or',
  },
  personalize: {
    en: 'Personalize',
  },
  sort: {
    en: 'Sort',
  },
}
