import { useMemo } from 'react'
import { constants } from 'helpers'
import { useFt } from 'hooks'
import { useIntl } from 'intl'

import messages from './messages'


const CATALOGUES_WITH_COLLABORATIONS: ProductsModule.CatalogueName[] = [ 'colognes', 'perfumes' ]

const useCollaborationsFilters = (catalogueName: ProductsModule.CatalogueName) => {
  const isMobileScentbirdSelectFilterEnabled = useFt(constants.features.mobileScentbirdSelectFilter)

  const intl = useIntl()

  const areCollaborationsFiltersAvailable = CATALOGUES_WITH_COLLABORATIONS.includes(catalogueName)

  const filters: CataloguePages.AvailableFilterData[] = useMemo(() => {
    if (!areCollaborationsFiltersAvailable) {
      return []
    }

    const values = []

    // mobileScentbirdSelectFilter feature adds category filter on backend side
    if (!isMobileScentbirdSelectFilterEnabled) {
      values.push({
        group: '',
        id: 'scentbirdSelect',
        image: '',
        label: intl.formatMessage(messages.labels.new),
        name: intl.formatMessage(messages.collaborations.scentbirdSelect),
        popular: false,
        rebrandImage: '',
      })
    }

    if (!values.length) {
      return []
    }

    return [ {
      filterSelectionType: 'SINGLE',
      filterType: 'COLLABORATION',
      id: 'collaboration',
      title: '',
      values,
    } ]
  }, [ areCollaborationsFiltersAvailable, intl, isMobileScentbirdSelectFilterEnabled ])

  return {
    filters: filters,
  }
}

export default useCollaborationsFilters
