import { useDevice } from 'device'
import getIndentClassNameFromStrapiValue from './getIndentClassNameFromStrapiValue'


type StrapiBlockIndent = {
  mobile?: string
  desktop?: string
}

const DEFAULT_INDENT = {
  mobile: 'mt-40',
  desktop: 'mt-40',
}


const useStrapiBlockIndent = (indent: StrapiBlockIndent, defaultIndentClassName?: StrapiBlockIndent) => {
  const { isMobile } = useDevice()

  const blockIndent = isMobile ? indent?.mobile : indent?.desktop
  const defaultBlockIndent = { ...DEFAULT_INDENT, ...defaultIndentClassName }

  const indentClassName = getIndentClassNameFromStrapiValue(blockIndent)

  if (!indentClassName) {
    return isMobile ? defaultBlockIndent.mobile : defaultBlockIndent.desktop
  }

  return indentClassName
}


export default useStrapiBlockIndent
