export default {
  all: {
    en: 'All fragrances',
  },
  perfumes: {
    en: 'Perfumes',
  },
  colognes: {
    en: 'Colognes',
  },
  unisex: {
    en: 'Unisex',
  },
}
