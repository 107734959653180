import React, { useEffect } from 'react'
import { throttle } from 'throttle-debounce'
import { useDevice } from 'device'

import { AbFtProvider, ShownPopupsProvider, UserProvider } from 'modules/user'
import { SubscribeOfferProvider, SubscriptionProvider } from 'modules/subscription'
import { PriceSelectionProvider } from 'modules/priceSelection'
import { SelectCampaignsProvider } from 'modules/selectCampaigns'

import Routes from 'containers/Routes/Routes'
import ModalsRegistry from 'containers/ModalsRegistry/ModalsRegistry'

import Notifications from './components/Notifications/Notifications'
import StaticNotifications from './components/StaticNotifications/StaticNotifications'
import InitialData from './components/InitialData/InitialData'
import AnalyticsSetup from './components/AnalyticsSetup/AnalyticsSetup'
import GlobalMeta from './components/GlobalMeta/GlobalMeta'
import AppVersionChecker from './components/AppVersionChecker/AppVersionChecker'
import GlobalLoaderProvider from './components/GlobalLoaderProvider/GlobalLoaderProvider'


type AppProps = {
  isLightweightSSR?: boolean
}

const App: React.FunctionComponent<AppProps> = ({ isLightweightSSR }) => {
  const { isBot, isMobile } = useDevice()

  useEffect(() => {
    document.documentElement.setAttribute('loaded', 'true')
  }, [])

  // see scss function safeVh
  useEffect(() => {
    if (!isMobile) {
      return
    }

    const handler = throttle(() => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    }, 150)

    handler()
    window.addEventListener('resize', handler)

    return () => {
      window.removeEventListener('resize', handler)
      document.documentElement.style.removeProperty('--vh')
    }
  }, [ isMobile ])

  return (
    <InitialData isLightweightSSR={isLightweightSSR}>
      <AbFtProvider>
        <UserProvider>
          <SubscriptionProvider>
            <ShownPopupsProvider>
              <SelectCampaignsProvider>
                <PriceSelectionProvider>
                  <SubscribeOfferProvider>
                    {
                      !isBot && (
                        <>
                          {/* Notifications should be rendered first to subscribe to events */}
                          <Notifications />
                          <AppVersionChecker />
                          <AnalyticsSetup />
                        </>
                      )
                    }
                    <GlobalMeta />
                    <GlobalLoaderProvider>
                      <ModalsRegistry />
                      <Routes />
                      <StaticNotifications />
                    </GlobalLoaderProvider>
                  </SubscribeOfferProvider>
                </PriceSelectionProvider>
              </SelectCampaignsProvider>
            </ShownPopupsProvider>
          </SubscriptionProvider>
        </UserProvider>
      </AbFtProvider>
    </InitialData>
  )
}


export default App
