import React, { useCallback } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { useSearchParams } from 'router'
import { getTestIdFromText } from 'helpers'

import type { GlobalHTMLAttrs } from 'helpers/getters'

import { useCatalogueContext } from 'pages/catalog/util'

import RowListItemView from '../RowListItemView/RowListItemView'
import FilterDisclosureGroup from '../FilterDisclosureGroup/FilterDisclosureGroup'

import messages from './messages'


type FilterWithValuesProps = CatalogFilters.FilterListContainerProps & {
  filterKey: Extract<CataloguePages.FilterKey, 'PRICE' | 'SIZES' | 'FULL_BOTTLE_TYPES'>
}

const FilterWithValues: React.FunctionComponent<FilterWithValuesProps> = (props) => {
  const { filterKey, defaultOpen, onFilterSelect, onFilterUnselect, onFilterListOpen } = props

  const { isMobile } = useDevice()
  const [ _, setSearchParams ] = useSearchParams()

  const { appliedFilters, availableFilters } = useCatalogueContext()

  const availableItems = availableFilters?.[filterKey]?.values
  const searchParamsKey = availableFilters?.[filterKey]?.id
  const appliedFilterIds = appliedFilters?.[filterKey]

  const handleClick = useCallback((id: string) => {
    const isActive = appliedFilterIds?.includes(id)
    const actionValue = availableItems.find((item) => item.id === id)?.name

    if (isActive) {
      onFilterUnselect(filterKey, undefined)
    }
    else {
      onFilterSelect(filterKey, actionValue)
    }

    setSearchParams((prevParams) => ({
      ...prevParams,
      [searchParamsKey]: !isActive ? id : undefined,
    }), { replace: false, scroll: false })
  }, [ appliedFilterIds, filterKey, searchParamsKey, availableItems, onFilterSelect, onFilterUnselect, setSearchParams ])

  const title = {
    ...messages[filterKey],
    values: {
      quantity: appliedFilterIds?.length > 0 ? `(${appliedFilterIds?.length})` : '',
    },
  }

  if (!availableItems) {
    return null
  }

  const testId = getTestIdFromText(filterKey.toLocaleLowerCase(), 'ecommFilter')

  return (
    <FilterDisclosureGroup.Item
      filterKey={filterKey}
      title={title}
      defaultOpen={defaultOpen}
      onFilterListOpen={onFilterListOpen}
      data-testid={testId}
    >
      <div className={cx(!isMobile && '-mx-40 px-20')}>
        {
          availableItems.map(({ id, name }) => {
            const isActiveFilterItem = appliedFilterIds?.includes(id)
            const htmlAttrs: GlobalHTMLAttrs = {
              role: 'checkbox',
              'aria-checked': isActiveFilterItem,
            }

            return (
              <RowListItemView
                key={name}
                title={name}
                isActive={isActiveFilterItem}
                {...htmlAttrs}
                onClick={() => handleClick(id)}
              />
            )
          })
        }
      </div>
    </FilterDisclosureGroup.Item>
  )
}


export default FilterWithValues
