import { useCatalogueId } from 'modules/products'


type UseCollaborationCatalogueId = {
  catalogueType: CollaborationsModule.CatalogueType
  collaboration: CollaborationsModule.Collaboration
  skip?: boolean
}

const collaborationCatalogues: Record<CollaborationsModule.Collaboration, CollaborationsModule.Catalogues> = {
  saks: {
    all: 'SaksAll',
    colognes: 'SaksColognes',
    perfumes: 'SaksPerfumes',
    unisex: 'SaksUnisex',
  },
}

const useCollaborationCatalogueId = ({ collaboration, catalogueType, skip }: UseCollaborationCatalogueId) => {
  const collaborationCatalogueName = collaborationCatalogues[collaboration]?.[catalogueType]

  const catalogueId = useCatalogueId({
    catalogueName: collaborationCatalogueName,
    skip: skip || !collaborationCatalogueName,
  })

  return catalogueId
}

export default useCollaborationCatalogueId
