import React from 'react'
import { track } from 'analytics'
import { useSearchParams } from 'router'

import { ActionListItem } from 'components/navigation'

import { useCatalogueContext } from 'pages/catalog/util'


type CategoryListProps = {
  filterType: Extract<CataloguePages.FilterKey, 'CATEGORY' | 'BEAUTY' | 'CATALOGUE' | 'COLLABORATION' | 'SELECT_CAMPAIGNS'>
  withBorderBottom?: boolean
  onClick: () => void
}

type AnalyticsEventFilterValue = Analytics.FilterByCategoryValue | Analytics.FilterByCollaborationValue

const CategoryList: React.FunctionComponent<CategoryListProps> = ({ filterType, withBorderBottom, onClick }) => {
  const [ _, setSearchParams ] = useSearchParams()

  const { availableFilters } = useCatalogueContext()

  const availableCategories = availableFilters?.[filterType]?.values

  if (!availableCategories?.length) {
    return null
  }

  const urlKey = filterType.toLowerCase()

  const handleClick = (value: string) => {
    track('Filter select', {
      filterBy: value as AnalyticsEventFilterValue,
    })

    setSearchParams({
      [urlKey]: value,
    }, { replace: false, scroll: false })

    onClick()
  }

  return (
    <>
      {
        availableCategories.map(({ id, name, label }, index) => (
          <ActionListItem
            key={id}
            title={name}
            label={label}
            style="black"
            withBorder={index > 0}
            withBorderBottom={withBorderBottom && index === availableCategories.length - 1}
            data-testid={id}
            onClick={() => handleClick(id)}
          />
        ))
      }
    </>
  )
}


export default React.memo(CategoryList)
