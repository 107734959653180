import React, { useCallback, useRef } from 'react'
import cx from 'classnames'
import links from 'links'
import { track } from 'analytics'
import { useDevice } from 'device'
import { usePathname, useSearchParams } from 'router'

import { Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'

import { useCatalogueContext } from 'pages/catalog/util'

import messages from './messages'


type ActionButtonsProps = {
  filterKey: Extract<CataloguePages.FilterKey, 'BRANDS' | 'NOTES' | 'TAGS'>
  onApply: () => void
}

const FilterModalsActionButtons: React.FunctionComponent<ActionButtonsProps> = ({ filterKey, onApply }) => {
  const type = filterKey.toLowerCase()

  const { isMobile } = useDevice()

  const pathname = usePathname()
  const [ , setSearchParams ] = useSearchParams()

  const { availableFilters, appliedFilters, count, isFetchingProducts, appliedFiltersCount } = useCatalogueContext()
  const targetParams = appliedFilters?.[filterKey]

  const prevParamsRef = useRef(targetParams)

  const handleClearAll = useCallback(() => {
    track(filterKey === 'TAGS' ? 'Personalize clear all' : 'Filter clear all')

    setSearchParams((prevParams) => ({
      ...prevParams,
      [type]: undefined,
    }), { replace: false, scroll: false })
  }, [ filterKey, setSearchParams, type ])

  const getAvailableItemName = useCallback((key: CataloguePages.FilterKey, id: string) => {
    const availableItem = availableFilters?.[key]?.values.find((item) => id === item.id)
    return availableItem?.name || id
  }, [ availableFilters ])

  const appliedFiltersToNames = useCallback((key) => {
    return appliedFilters?.[key]?.map((id) => getAvailableItemName(key, id))
  }, [ appliedFilters, getAvailableItemName ])

  const appliedFiltersToGroups = useCallback((key) => {
    return appliedFilters?.[key]?.map((id) => {
      const item = availableFilters[key]?.values.find((item) => item.id === id)
      return item?.group || id
    })
  }, [ appliedFilters, availableFilters ])

  const trackZeroResults = useCallback(() => {
    if (filterKey === 'TAGS') {
      track('Personalize zero result display', {
        tagName: appliedFiltersToNames('TAGS'),
        tagCategory: appliedFiltersToGroups('TAGS'),
      })
    }
    else {
      track('Filter zero result display', { flow: 'Applied by user' })
    }
  }, [ filterKey, appliedFiltersToNames, appliedFiltersToGroups ])

  const trackApply = useCallback(() => {
    if (filterKey === 'TAGS') {
      track('Personalize apply', {
        tagName: appliedFiltersToNames('TAGS'),
        tagCategory: appliedFiltersToGroups('TAGS'),
      })
    }
    else {
      track('Filter apply', {
        notes: appliedFilters?.NOTES || [],
        productBrands: appliedFilters?.BRANDS || [],
        tagNames: appliedFiltersToNames('TAGS'),
      })
    }
  }, [ filterKey, appliedFiltersToNames, appliedFiltersToGroups, appliedFilters ])

  const handleApply = useCallback(() => {
    if (!isFetchingProducts && appliedFiltersCount > 0 && count === 0) {
      trackZeroResults()
    }

    trackApply()
    onApply()
  }, [ isFetchingProducts, appliedFiltersCount, count, trackZeroResults, trackApply, onApply ])


  const isPrimaryButtonVisible = useRef(false)
  const isClearAllButtonVisible = Boolean(targetParams?.length)

  if (!isPrimaryButtonVisible.current && targetParams !== prevParamsRef.current) {
    isPrimaryButtonVisible.current = true
  }

  if (!isPrimaryButtonVisible.current && !isClearAllButtonVisible) {
    return null
  }

  let noteMessage

  if (filterKey === 'BRANDS' && targetParams?.length > 8) {
    noteMessage = messages.tooMuchBrands
  }
  else if (!isFetchingProducts && count === 0) {
    noteMessage = messages.emptyNote
  }

  const productType = pathname === links.subscription.perfumes
    ? 'perfume'
    : pathname === links.subscription.colognes ? 'cologne' : null

  return (
    <div className={cx('sticky bottom-0 bg-white pb-16 pt-8', isMobile ? '-mx-16 px-16' : '-mx-40 px-40')}>
      {
        Boolean(noteMessage) && (
          <Text className="mb-8 text-center" message={noteMessage} style="p4" color="gold-70" />
        )
      }
      {
        isPrimaryButtonVisible.current && (
          <Button
            title={{ ...messages.primaryButton, values: { productType, count: count || 0 } }}
            size={56}
            style="primary"
            loading={isFetchingProducts}
            fullWidth
            html
            onClick={handleApply}
            data-testid="applyButton"
          />
        )
      }
      {
        isClearAllButtonVisible && (
          <Button
            className={isPrimaryButtonVisible.current ? 'mt-8' : null}
            title={messages.clearAll}
            size={56}
            style="tertiary"
            fullWidth
            onClick={handleClearAll}
            data-testid="clearButton"
          />
        )
      }
    </div>
  )
}


export default FilterModalsActionButtons
