import React, { useCallback } from 'react'
import { useDevice } from 'device'
import { useSearchParams } from 'router'
import { type ModalComponentProps, standaloneModal } from 'modals'
import { getTestIdFromText } from 'helpers'
import { track } from 'analytics'

import { Text } from 'components/dataDisplay'
import { buttonMessages } from 'components/inputs'
import { ModalButtons, PlainModal } from 'components/feedback'

import { useCatalogueContext } from 'pages/catalog/util'

import RowListItemView from './components/RowListItemView/RowListItemView'

import messages from './messages'

// TODO: Move to the backend available sort — added on 22–09–2023 by algeas
const sortTypes = [
  'DEFAULT',
  'PRICE_ASC',
  'PRICE_DESC',
  'RATING_DESC',
  'NEW_ARRIVALS_DESC',
]

const analyticEventsMap = {
  DEFAULT: 'By default',
  PRICE_ASC: 'By price, low to high',
  PRICE_DESC: 'By price, high to low',
  RATING_DESC: 'By rating, high to low',
  NEW_ARRIVALS_DESC: 'By new arrivals',
}

const SortingModal: React.FunctionComponent<ModalComponentProps> = ({ closeModal }) => {
  const { isMobile } = useDevice()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const { scrollToFilters } = useCatalogueContext()

  const selectedSortType = searchParams?.sort || 'DEFAULT'

  const handleClick = useCallback((sortBy: string) => {
    track('Sort select', {
      sortBy: analyticEventsMap[sortBy],
    })

    setSearchParams((prevParams) => ({
      ...prevParams,
      sort: sortBy === 'DEFAULT' ? undefined : sortBy,
    }), { replace: false, scroll: false })
  }, [ setSearchParams ])

  const handleApply = useCallback(() => {
    track('Sort apply', {
      sortBy: analyticEventsMap[searchParams?.sort],
    })

    closeModal()
    scrollToFilters()
  }, [ closeModal, scrollToFilters, searchParams?.sort ])

  const handleCancel = useCallback(() => {
    track('Sort cancel')

    setSearchParams((prevParams) => ({
      ...prevParams,
      sort: undefined,
    }), { replace: false, scroll: false })

    closeModal()
  }, [ closeModal, setSearchParams ])


  const buttonMessage = messages.button[selectedSortType] || messages.button.DEFAULT

  return (
    <PlainModal
      type={isMobile ? 'sidebar-left' : 'sidebar-right'}
      bgColor="white"
      closeModal={closeModal}
      data-testid="filtersModal"
    >
      <Text className="mb-24" message={messages.title} style="sh5" />
      {
        sortTypes.map((sortType) => (
          <RowListItemView
            key={sortType}
            title={messages.sort[sortType]}
            isActive={selectedSortType === sortType}
            onClick={() => handleClick(sortType)}
            data-testid={getTestIdFromText(sortType.toLowerCase(), 'EcommSort')}
          />
        ))
      }

      <div className="flex flex-1 flex-col justify-end">
        <ModalButtons
          primary={{
            title: buttonMessage,
            onClick: handleApply,
            fullWidth: true,
          }}
          tertiary={{
            title: buttonMessages.cancel,
            onClick: handleCancel,
          }}
        />
      </div>
    </PlainModal>
  )
}

declare global {
  interface ModalsRegistry extends ExtendModalsRegistry<{ sortingModal: typeof SortingModal }> {}
}


export default standaloneModal('sortingModal', SortingModal)
