import React from 'react'

import { Text } from 'components/dataDisplay'
import { Notification } from 'components/feedback'
import type { NotificationProps } from 'components/feedback'


type PlainNotificationProps = NotificationProps & {
  title: string | Intl.Message
  text: string | Intl.Message
}

const WelcomeNotification: React.FunctionComponent<PlainNotificationProps> = (props) => {
  const { title, text, onClick, closeNotification, onClose } = props

  return (
    <Notification
      className="bg-light-beige p-16"
      closeNotification={closeNotification}
      withAnimation={false}
      timeout={0}
      onClick={onClick}
      onClose={onClose}
    >
      <Text textClassName="font-semibold" style="p1" message={title} color="black" data-testid="title" html />
      <Text className="mt-8" style="p2" message={text} color="black" html data-testid="text" />
    </Notification>
  )
}


export default WelcomeNotification
