import React from 'react'
import cx from 'classnames'

import { Icon, Text } from 'components/dataDisplay'
import type { IconName } from 'components/dataDisplay'
import { ButtonBase, type ButtonBaseProps } from 'components/inputs'


type FilterButtonProps = {
  className?: string
  title: Intl.Message
  iconName: IconName
  'data-testid': string
  onClick: ButtonBaseProps['onClick']
}

const FilterButton: React.FunctionComponent<FilterButtonProps> = (props) => {
  const { className, title, iconName, 'data-testid': dataTestId, onClick } = props
  const rootClassName = cx(
    className,
    'border-solid-gray-50 text-gray-70 h-48',
    'desktop-hover:border-gray-80 desktop-hover:text-black',
    'bg-gray-10 flex cursor-pointer items-center justify-between rounded pl-16 pr-8'
  )

  return (
    <ButtonBase
      className={rootClassName}
      data-testid={dataTestId}
      onClick={onClick}
    >
      <Text message={title} style="t2" />
      <Icon className="ml-8 text-gray-50" name={iconName} />
    </ButtonBase>
  )
}


export default FilterButton
