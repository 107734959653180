import { useMemo } from 'react'
import { constants } from 'helpers'
import { useFt } from 'hooks'
import { useIntl } from 'intl'

import { useAvailableCatalogues } from 'modules/products'

import messages from './messages'


const THE_SHOP_IGNORED_CATALOGUES = [ 'theShopAllBathAndBody', 'theShopAllCandlesHomeScents' ]

const THE_SHOP_ROOT_CATALOGUES_MAP = {
  theShopAllBathAndBody: 'theShopBathAndBody',
  theShopAllCandlesHomeScents: 'theShopCandlesHomeScents',
}

const useTheShopCatalogueFilters = (catalogueName: ProductsModule.CatalogueName) => {
  const isTheShopEnabled = useFt(constants.features.theShop)
  const intl = useIntl()

  const rootCatalogueName = isTheShopEnabled ? THE_SHOP_ROOT_CATALOGUES_MAP[catalogueName] : null

  const { availableCatalogues, isFetching } = useAvailableCatalogues({
    catalogueName: rootCatalogueName,
    excludeCatalogues: THE_SHOP_IGNORED_CATALOGUES,
    skip: !rootCatalogueName,
  })

  const filters: CataloguePages.AvailableFilterData[] = useMemo(() => {
    const catalogues = availableCatalogues?.map((catalogue) => {
      const { id, name } = catalogue

      return {
        group: '',
        id: id.toString(),
        image: '',
        name: intl.formatMessage(messages.categories[name]),
        popular: false,
        rebrandImage: '',
      }
    })

    if (!catalogues) {
      return []
    }

    return [ {
      filterSelectionType: 'SINGLE',
      filterType: 'CATALOGUE',
      id: 'catalogue',
      title: intl.formatMessage(messages.categories.title),
      values: catalogues,
    } ]
  }, [ availableCatalogues, intl ])

  return {
    filters: filters,
    isFetching,
  }
}

export default useTheShopCatalogueFilters
