import '../styles/critical.css'
import '../styles/styles.css'
import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import smoothscroll from 'smoothscroll-polyfill'
import config from 'config'
import { DeviceProvider } from 'device'
import logger from 'logger'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { loadableReady } from '@loadable/component'
import { ApolloClientsProvider } from 'apollo-client'
import { createClients } from 'apollo-client/client'
import { SvgProvider } from 'svg-provider'
import BrowserRuntime from 'svg-provider/BrowserRuntime'
import { mergeSearchParams } from 'router'
import { BrowserRouter } from 'router/client'
import { routeAnalytics } from 'links'
import { mixpanel, rudderstack, updateAnalyticsContext } from 'analytics'
import cookieStorage from 'cookie-storage'
import { constants } from 'helpers'
import { isExperimentTracked, markExperimentAsTracked } from 'modules/growthbook'
import { LocalePreferencesProvider } from 'modules/localePreferences'

import 'focus-visible'

import App from 'containers/App/App'
import ErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary'
import dumpData from 'containers/App/components/InitialData/util/dumpData'


smoothscroll.polyfill()

const apolloClients = createClients({
  // Probably should be replaced by a separate provider object
  getLocale: () => window.__LOCALE_PREFERENCES__.locale,
  enableCaptcha: window.location.search.includes('captcha=1'),
})

let svgRuntime: BrowserRuntime

if (config.runEnv !== 'prod') {
  window.__APOLLO_CLIENTS__ = apolloClients
}


const cleanQueryParams = () => {
  // remove ltn param
  const url = mergeSearchParams(window.location.toString(), {
    ltn: undefined,
    magicToken: undefined,
  })

  window.history.replaceState({}, document.title, url)
}

cleanQueryParams()

const gb = (() => {
  const gb = new GrowthBook({
    apiHost: config.growthBookApiHost,
    clientKey: config.growthBookClientKey,
    enableDevMode: config.runEnv !== 'prod',
    attributes: window.__GROWTH_BOOK_DATA__.attributes,
    trackingCallback: (experiment, result) => {
      const currentAttributes = gb.getAttributes()

      if (!currentAttributes.growthBookUid) {
        mixpanel.track('Growthbook error', { message: 'No growthBookUid' })
        return
      }

      if (isExperimentTracked(experiment.key)) {
        logger.info('[Growthbook] Experiment %s already tracked', experiment.key)
        return
      }

      mixpanel.register({
        [`ab ${experiment.key}`]: result.variationId,
      })

      const shouldTrackEvent = !isExperimentTracked(experiment.key) && (currentAttributes.loggedIn || experiment.key.endsWith('lead-track'))

      if (!shouldTrackEvent) {
        return
      }

      const eventName = '$experiment_started'
      const eventParams = {
        'Experiment name': experiment.key,
        'Variant name': result.variationId,
        'growthBookUid': currentAttributes.growthBookUid,
        $source: 'growthbook',
        'rudderstackAvailable': Boolean(rudderstack.getAnonymousId()),
      }

      mixpanel.track(eventName, eventParams)
      rudderstack.track(eventName, eventParams)

      markExperimentAsTracked(experiment.key)
    },
  })

  // force feature override
  try {
    const gbOverride = cookieStorage.getItem(constants.cookieNames.gbOverride)

    if (gbOverride) {
      const forceFeatures = new Map<string, any>()
      Object.keys(gbOverride).forEach((key) => {
        forceFeatures.set(key, gbOverride[key])
      })
      gb.setForcedFeatures(forceFeatures)
    }
  }
  catch (error) {
    logger.warn(error)
  }

  return gb.initSync({
    payload: window.__GROWTH_BOOK_DATA__.payload,
  })
})()

const onLocaleChange = () => {
  const resetCache = (rootId: string, fields?: string[]) => {
    if (fields?.length) {
      fields.forEach((fieldName) => {
        apolloClients.default.cache.evict({ id: rootId, fieldName })
      })
    }
    else {
      apolloClients.default.cache.evict({ id: rootId })
    }
  }

  // resetCache('UserData:{}', [ 'cart', 'queue' ])
  // TODO: replace to more granular reset to reset only locale related things
  resetCache('ROOT_QUERY')
}

// Update analytics context on each route change or reset it to default value
const onRouteMatch = (match: { path: string }) => {
  const pageContext = routeAnalytics[match?.path]
  updateAnalyticsContext(pageContext || {})
}

window.dumpData = () => dumpData(apolloClients.default, gb)

const Root = () => (
  <ApolloClientsProvider clients={apolloClients}>
    <LocalePreferencesProvider value={window.__LOCALE_PREFERENCES__} onChange={onLocaleChange}>
      <HelmetProvider>
        <DeviceProvider value={window.__DEVICE__}>
          <SvgProvider value={svgRuntime}>
            <GrowthBookProvider growthbook={gb}>
              <ErrorBoundary>
                <BrowserRouter onRouteMatch={onRouteMatch}>
                  <Helmet>
                    <title>Scentbird</title>
                  </Helmet>
                  <App isLightweightSSR={window.lightweightSSR} />
                </BrowserRouter>
              </ErrorBoundary>
            </GrowthBookProvider>
          </SvgProvider>
        </DeviceProvider>
      </HelmetProvider>
    </LocalePreferencesProvider>
  </ApolloClientsProvider>
)

loadableReady().then(() => {
  const hydrate = () => {
    // create runtime only when document is loaded
    svgRuntime = new BrowserRuntime()

    logger.info(`Client scripts loaded in ${Date.now() - window.__START__}ms`)
    hydrateRoot(document.getElementById('root'), <Root />)
  }

  // ATTN it's required because all scripts have async property
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', hydrate)
  }
  else {
    hydrate()
  }
}, (error) => {
  logger.fatal(error, 'Fail to render')
})
