import { useCallback } from 'react'
import { track } from 'analytics'
import { useFt } from 'hooks'
import { constants } from 'helpers'


const FILTER_KEY_TO_ANALYTIC_EVENT_NAME_MAP = {
  BRANDS: 'Brands name',
  NOTES: 'Notes name',
  PRICE: 'Price',
  SIZES: 'Size',
  FULL_BOTTLE_TYPES: 'Type',
  GWP: 'GWP',
  SALE: 'Sale',
} as const

type UseEcommerceFiltersModalParams = {
  showGwpFilter?: boolean
  showSaleFilter?: boolean
}

const useEcommerceFiltersModal = ({ showGwpFilter = true, showSaleFilter = true }: UseEcommerceFiltersModalParams) => {
  const isSaleEcommerceFilterEnabled = useFt(constants.features.saleEcommerceFilter)

  const isSaleFilterVisible = isSaleEcommerceFilterEnabled && showSaleFilter
  const isSwitchFiltersVisible = showGwpFilter || isSaleFilterVisible

  const trackFilterItemSelect = useCallback((filterKey: string, filterValue?: string | string[]) => {
    const filterBy = FILTER_KEY_TO_ANALYTIC_EVENT_NAME_MAP[filterKey]

    if (filterBy) {
      track('Filter select', { filterBy, filterValue })
    }
  }, [])

  const trackFilterItemUnselect = useCallback((filterKey: string, filterValue: string | string[]) => {
    const filterBy = FILTER_KEY_TO_ANALYTIC_EVENT_NAME_MAP[filterKey]

    if (filterBy) {
      track('Filter unselect', { filterBy, filterValue })
    }
  }, [])

  return {
    isGwpFilterVisible: showGwpFilter,
    isSaleFilterVisible,
    isSwitchFiltersVisible,
    trackFilterItemSelect,
    trackFilterItemUnselect,
    trackOpenedFilterItem: trackFilterItemSelect,
  }
}


export default useEcommerceFiltersModal
