import React from 'react'
import { twcx } from 'helpers'
import { useEntry } from 'intersection-observer'

import type { FeaturedBrandsProps } from '../../FeaturedBrands'

import BrandsRow from './components/BrandsRow/BrandsRow'


const MobileView: React.FunctionComponent<FeaturedBrandsProps> = (props) => {
  const { className, data } = props

  const [ ref, entry ] = useEntry()
  const isVisible = entry?.isIntersecting

  const rows = data?.brands?.data

  if (!rows) {
    return null
  }

  return (
    <div ref={ref} className={twcx('mt-40', className)}>
      {
        rows.map((row, index) => {
          const { attributes, id } = row
          const { alternativeText, height, url, width } = attributes

          const aspect = width / height

          return (
            <BrandsRow
              key={id}
              className={index > 0 && 'mt-32'}
              alt={alternativeText}
              aspect={isNaN(aspect) ? 1 : aspect}
              height={height}
              inverted={index % 2 === 1}
              isVisible={isVisible}
              src={url}
              width={width}
            />
          )
        })
      }
    </div>
  )
}

export default MobileView
