import React, { useEffect } from 'react'
import config from 'config'
import { openModal } from 'modals'

import messages from './messages'


const primaryReloadTime = 12 * 60 * 60 * 1000 // 12 hours
const secondaryReloadTime = 30 * 60 * 1000 // 30 minutes

const openReloadPageModal = () => {
  // TODO add analytics - added on 2021-06-03 by maddoger
  // track('Reload page notification')

  const closeModal = openModal('commonModal', {
    title: messages.title,
    text: messages.text,
    primaryButton: {
      title: messages.primaryButtonTitle,
      onClick: () => {
        // track('Reload page click')
        document.location.reload()
      },
    },
    tertiaryButton: {
      title: messages.secondaryButtonTitle,
      onClick: () => {
        closeModal()

        // open the modal again if a user skips page reloading
        setTimeout(() => {
          openReloadPageModal()
        }, secondaryReloadTime)
      },
    },
    'data-testid': 'reloadPageModal',
  })
}

let timeout

const runTimer = () => {
  timeout = setTimeout(async () => {
    try {
      // be sure that the app was updated to prevent showing the modal if it's not necessary
      const { appVersion, buildTime } = await window.fetch('/site-api/app-version')
        .then((response) => response.json())

      // Check if the app version is different and the server build is newer than the client build
      const serverBuildTime = Number(buildTime)
      const clientBuildTime = Number(config.buildTime)

      if (appVersion !== config.appVersion && serverBuildTime > clientBuildTime) {
        openReloadPageModal()
      }
      else {
        // run timer again if the versions are same or the server build is not newer
        // (that means that the app wasn't updated or the user has a newer build)
        runTimer()
      }
    }
    catch (error) {
      console.error(error)
      runTimer()
    }
  }, primaryReloadTime)
}

const AppVersionChecker: React.FunctionComponent = () => {
  useEffect(() => {
    runTimer()

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return null
}


export default AppVersionChecker
