import { useOnce } from 'hooks'
import { noibu } from 'analytics'
import config from 'config'


const useNoibuSetup = ({ user, growthBookUid }: AnalyticsSetup.HookProps) => {
  const id = user?.id

  useOnce(() => {
    if (!__CLIENT__) {
      return
    }

    // we use noibu only in production
    if (config.runEnv !== 'prod') {
      return
    }

    if (id) {
      noibu.addCustomAttribute('userID', String(id))
    }

    if (growthBookUid) {
      noibu.addCustomAttribute('growthBookUid', growthBookUid)
    }
  }, [ id, growthBookUid ])
}


export default useNoibuSetup
