import { useSearchParams } from 'router'
import { useFt } from 'hooks'
import { constants } from 'helpers'


type SortType = ProductsModule.UseCatalogueProductsProps['variables']['input']['sort']

const useCatalogueSorting = (): ProductsModule.UseCatalogueProductsProps['variables']['input']['sort'] | null => {
  const [ searchParams ] = useSearchParams()

  const isEcommerceTwoZeroEnabled = useFt(constants.features.ecommerceTwoZero)
  const hasSelectedSort = Boolean(searchParams?.sort)

  return isEcommerceTwoZeroEnabled && hasSelectedSort ? searchParams?.sort as SortType : null
}


export default useCatalogueSorting
