import React, { useCallback, useMemo } from 'react'
import { useDevice } from 'device'
import { useSearchParams } from 'router'
import { type ModalComponentProps, standaloneModal } from 'modals'

import { Text } from 'components/dataDisplay'
import { PlainModal } from 'components/feedback'

import { useCatalogueContext } from 'pages/catalog/util'

import ActiveFiltersList from 'pages/catalog/components/ActiveFiltersList/ActiveFiltersList'

import NotesFilter from './components/NotesFilter/NotesFilter'
import BrandsFilter from './components/BrandsFilter/BrandsFilter'
import FilterSwitch from './components/FilterSwitch/FilterSwitch'
import FilterWithValues from './components/FilterWithValues/FilterWithValues'
import FiltersActionButtons from './components/FiltersActionButtons/FiltersActionButtons'
import FilterDisclosureGroup from './components/FilterDisclosureGroup/FilterDisclosureGroup'

import useEcommerceFiltersModal from './util/useEcommerceFiltersModal'
import messages from './messages'


type EcommerceFiltersModalProps = {
  headerContent?: React.ReactElement
  showGwpFilter?: boolean
  showSaleFilter?: boolean
}

const FILTERS_LISTS_KEYS = [ 'BRANDS', 'NOTES', 'PRICE', 'SIZES', 'FULL_BOTTLE_TYPES' ] as const

const EcommerceFiltersModal: React.FunctionComponent<ModalComponentProps & EcommerceFiltersModalProps> = (props) => {
  const { headerContent, closeModal } = props

  const { isMobile } = useDevice()
  const [ searchParams ] = useSearchParams()
  const { availableFilters, appliedFiltersCount, scrollToFilters } = useCatalogueContext()

  const {
    isGwpFilterVisible,
    isSaleFilterVisible,
    isSwitchFiltersVisible,
    trackFilterItemSelect,
    trackFilterItemUnselect,
    trackOpenedFilterItem,
  } = useEcommerceFiltersModal(props)

  const handleApply = useCallback(() => {
    closeModal()
    scrollToFilters()
  }, [ closeModal, scrollToFilters ])

  const filtersList = useMemo(() => {
    let enabledFiltersList = FILTERS_LISTS_KEYS
      .filter((filterKey) => Boolean(availableFilters?.[filterKey]))

    enabledFiltersList.sort((a, b) => {
      return searchParams?.[availableFilters?.[a]?.id] && !searchParams?.[availableFilters?.[b]?.id] ? -1 : 1
    })

    return enabledFiltersList.map((filterKey, index) => {
      const isOpenedByDefault = !index && searchParams?.[availableFilters?.[filterKey]?.id] !== undefined

      let FilterItem

      if (filterKey === 'BRANDS') {
        FilterItem = BrandsFilter
      }
      else if (filterKey === 'NOTES') {
        FilterItem = NotesFilter
      }
      else {
        FilterItem = FilterWithValues
      }

      return (
        <FilterItem
          key={filterKey}
          filterKey={filterKey}
          defaultOpen={isOpenedByDefault}
          onFilterSelect={trackFilterItemSelect}
          onFilterUnselect={trackFilterItemUnselect}
          onFilterListOpen={trackOpenedFilterItem}
        />
      )
    })
  }, [ availableFilters, searchParams, trackFilterItemSelect, trackFilterItemUnselect, trackOpenedFilterItem ])

  return (
    <PlainModal
      type={isMobile ? 'sidebar-left' : 'sidebar-right'}
      bgColor="white"
      closeModal={closeModal}
      data-testid="filtersModal"
    >
      <Text className={appliedFiltersCount ? 'mb-16' : 'mb-24'} message={messages.title} style="sh5" />

      {
        headerContent && (
          <div className="w-full" data-testid="modalHeaderContent">
            {headerContent}
          </div>
        )
      }

      <div className="w-full">
        <ActiveFiltersList className="mb-12" />
      </div>

      <FilterDisclosureGroup>
        {filtersList}
      </FilterDisclosureGroup>

      {
        isSwitchFiltersVisible && (
          <div className="mt-32" data-testid="ecommBooleanFilters">
            {
              isGwpFilterVisible && (
                <FilterSwitch
                  filterKey="GWP"
                  onFilterSelect={trackFilterItemSelect}
                  onFilterUnselect={trackFilterItemUnselect}
                />
              )
            }
            {
              isSaleFilterVisible && (
                <FilterSwitch
                  filterKey="SALE"
                  onFilterSelect={trackFilterItemSelect}
                  onFilterUnselect={trackFilterItemUnselect}
                />
              )
            }
          </div>
        )
      }
      <FiltersActionButtons onApply={handleApply} />
    </PlainModal>
  )
}

declare global {
  interface ModalsRegistry extends ExtendModalsRegistry<{ ecommerceFiltersModal: typeof EcommerceFiltersModal }> {
  }
}


export default standaloneModal('ecommerceFiltersModal', EcommerceFiltersModal)
