import React, { useCallback, useEffect } from 'react'
import cx from 'classnames'
import { useSearchParams } from 'router'
import { useField } from 'formular'
import { useDevice } from 'device'
import { getTestIdFromText } from 'helpers'

import { Text } from 'components/dataDisplay'
import { Switch } from 'components/inputs'

import messages from './messages'


const FILTER_KEY_MAP = {
  GWP: 'gwp',
  SALE: 'sale',
}

type FilterSwitchProps = CatalogFilters.FilterListContainerProps & {
  filterKey: keyof typeof FILTER_KEY_MAP
}

const FilterSwitch: React.CFC<FilterSwitchProps> = (props) => {
  const { filterKey, onFilterSelect, onFilterUnselect } = props

  const searchParamsKey = FILTER_KEY_MAP[filterKey]
  const { isMobile } = useDevice()
  const [ searchParams, setSearchParams ] = useSearchParams()

  const isSwitchEnabled = Boolean(searchParams?.[searchParamsKey])

  const title = messages[filterKey]
  const field = useField<boolean>({
    value: isSwitchEnabled,
  })

  useEffect(() => {
    if (field.state.value !== isSwitchEnabled) {
      field.set(isSwitchEnabled)
    }
  }, [ field, isSwitchEnabled ])

  const handleChange = useCallback((value: boolean) => {
    if (value) {
      onFilterSelect(filterKey)
    }
    else {
      onFilterUnselect(filterKey)
    }

    setSearchParams((prevParams) => ({
      ...prevParams,
      [searchParamsKey]: value || undefined,
    }), { replace: false, scroll: false })
  }, [ filterKey, searchParamsKey, onFilterSelect, onFilterUnselect, setSearchParams ])

  const bgClassName = cx(
    '-z-1 bg-light-beige absolute h-full',
    isMobile ? '-inset-x-16' : '-inset-x-40'
  )
  const switchClassName = cx(
    'relative z-0 flex h-56 w-full cursor-pointer items-center justify-between'
  )
  const testId = getTestIdFromText(filterKey.toLowerCase(), 'ecommFilter')

  return (
    <Switch.Group>
      <Switch.Label>
        <div className={switchClassName} data-testid={testId}>
          <span className={bgClassName} />
          <Text style="t5" color="gold-70" message={title} />
          <Switch style="gold" field={field} size={30} onChange={handleChange} />
        </div>
      </Switch.Label>
    </Switch.Group>
  )
}


export default FilterSwitch
