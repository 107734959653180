import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import type { AnalyticsMetadataInput, Label } from 'typings/graphql'

import { WidthContainer } from 'components/layout'
import ProductCardDetailed from 'compositions/productCards/ProductCardDetailed/ProductCardDetailed'
import ProductCardSmall from 'compositions/productCards/ProductCardSmall/ProductCardSmall'
import ProductCardDrift from 'compositions/productCards/ProductCardDrift/ProductCardDrift'

import { isProduct } from '../../util'


type ProductListProps = {
  className?: string
  products: InfinityScrollProductFeed.Props['products']
  metadata: AnalyticsMetadataInput
  withoutNotes: boolean
  isEcommerce: boolean
  withProductPriceRange?: boolean
  withEcommercePrefix?: boolean
  withForwardedTradingItemUid?: boolean
  excludeLabels: Label[]
  onProductButtonClick?: ProductCard.OnProductClick
}


const ProductList: React.FC<ProductListProps> = (props) => {
  const { className, products, metadata, isEcommerce, withEcommercePrefix, excludeLabels, withoutNotes,
    withForwardedTradingItemUid = false, withProductPriceRange, onProductButtonClick } = props

  const { isMobile } = useDevice()

  if (!products?.length) {
    return null
  }

  const isSingleProductWithVariety = isMobile && products.length === 1 && isProduct(products[0]) && products[0].hasVariety

  const rootClassName = isSingleProductWithVariety
    ? 'mt-16'
    : cx(className, 'grid items-stretch gap-16', isMobile ? 'grid-cols-2-center' : 'grid-cols-3-center')

  return (
    <WidthContainer className={rootClassName} data-testid="productList">
      {
        products.map((data) => {
          if (isProduct(data)) {
            const productProps = {
              key: data.uid,
              data,
              isEcommerce,
              withEcommercePrefix,
              excludeLabels,
              withProductPriceRange,
              withDescription: false,
              buttonProps: {
                metadata,
              },
              withoutNotes,
              withForwardedTradingItemUid,
              onProductButtonClick,
            }

            let ProductCardComponent = ProductCardDetailed

            if (data.hasVariety) {
              ProductCardComponent = ProductCardDrift
            }
            else if (isMobile) {
              ProductCardComponent = ProductCardSmall
            }

            return React.createElement(ProductCardComponent, productProps)
          }

          return data
        })
      }
    </WidthContainer>
  )
}


export default ProductList
