import React from 'react'
import { Icon, type IconName, Text, type TextBaseProps, type TextColor, type TextStyle } from 'components/dataDisplay'


type BrandProps = {
  className?: string
  brand: string
  style: TextStyle
  color: TextColor
}

const CUSTOM_LOGOS = {
  'STRAWBERRY SHORTCAKE X SCENTBIRD': {
    logo: 'other/strawberry-shortcake-scentbird-logo',
  },
} satisfies Record<string, { logo: IconName }>

const Brand: React.FC<BrandProps> = (props) => {
  const { className, brand, style, color } = props

  const textProps: TextBaseProps = {
    className,
    style,
    color,
    children: null,
  }

  const customLogo = CUSTOM_LOGOS[brand?.toUpperCase()]

  if (customLogo) {
    textProps.children = (
      <>
        <span className="visually-hidden">{brand}</span>
        <Icon className="h-auto max-w-full" name={customLogo.logo} ariaLabel={brand} />
      </>
    )
  }
  else {
    textProps.message = brand
  }

  return (
    <Text data-testid="productBrand" {...textProps} />
  )
}

export default Brand
