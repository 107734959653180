import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { Text } from 'components/dataDisplay'
import { ButtonBase } from 'components/inputs'
import { ScrollableContainer } from 'components/layout'

import { useCatalogueContext } from 'pages/catalog/util'

import NoteListItem from '../NoteListItem/NoteListItem'
import FilterDisclosureGroup from '../FilterDisclosureGroup/FilterDisclosureGroup'

import messages from './messages'


const NotesFilter: React.FunctionComponent<CatalogFilters.FilterListContainerProps> = (props) => {
  const { defaultOpen, onFilterSelect, onFilterUnselect, onFilterListOpen } = props

  const { isMobile } = useDevice()
  const { availableFilters, appliedFilters } = useCatalogueContext()

  const [ selectedLetter, setSelectedLetter ] = React.useState<string>(null)

  const selectedNotes = appliedFilters?.NOTES || []
  const availableNotes = availableFilters.NOTES.values

  const { noteGroups, popularNotes, firstGroupLetter } = useMemo(() => {
    if (!availableNotes) {
      return {}
    }

    const popularNotes = availableNotes.filter(({ name, popular }) => popular)

    let firstGroupLetter: string

    // notes are sorted by name from backend
    const noteGroups = availableNotes.reduce<CataloguePages.FilterGroups>((result, item) => {
      let firstLetter = item.name.charAt(0).toUpperCase()

      result[firstLetter] = result[firstLetter] || []
      result[firstLetter].push(item)

      firstGroupLetter = firstGroupLetter || firstLetter

      return result
    }, {})

    return {
      noteGroups,
      popularNotes,
      firstGroupLetter,
    }
  }, [ availableNotes ])

  const handleLetterClick = useCallback((letter: string) => {
    setSelectedLetter(letter)
  }, [])

  const title = {
    ...messages.title,
    values: {
      quantity: selectedNotes.length > 0 ? `(${selectedNotes.length})` : '',
    },
  }

  const activeGroupLetter = selectedLetter || firstGroupLetter

  return (
    <FilterDisclosureGroup.Item
      filterKey="NOTES"
      title={title}
      defaultOpen={defaultOpen}
      onFilterListOpen={onFilterListOpen}
      data-testid="notesEcommFilter"
    >
      <div className="flex flex-col">
        <div className="flex-1">
          <ScrollableContainer className="flex gap-20 p-2" isDesktopEnabled>
            {
              Boolean(popularNotes.length) && (
                popularNotes.map((data) => (
                  <NoteListItem
                    key={data.name}
                    data={data}
                    type="card"
                    onFilterSelect={onFilterSelect}
                    onFilterUnselect={onFilterUnselect}
                  />
                ))
              )
            }
          </ScrollableContainer>

          <ScrollableContainer className="-mx-16 mb-24 flex px-16" isDesktopEnabled>
            {
              Boolean(noteGroups) && (
                Object.keys(noteGroups).map((letter) => (
                  <ButtonBase
                    key={letter}
                    className="relative mr-32 cursor-pointer pb-8 pt-24 outline-none before:absolute before:-inset-16 before:content-['']"
                    tag="div"
                    role="button"
                    tabIndex={0}
                    onClick={() => handleLetterClick(letter)}
                  >
                    <Text
                      key={letter}
                      message={String(letter)}
                      style="h7"
                      color={activeGroupLetter === letter ? 'gold-50' : 'black'}
                    />
                  </ButtonBase>
                ))
              )
            }
          </ScrollableContainer>

          <div className={cx(!isMobile && '-mx-40 mt-24 px-20')} role="group">
            {
              noteGroups[activeGroupLetter].map((data) => (
                <NoteListItem
                  key={data.id}
                  data={data}
                  type="row"
                  onFilterSelect={onFilterSelect}
                  onFilterUnselect={onFilterUnselect}
                />
              ))
            }
          </div>
        </div>
      </div>
    </FilterDisclosureGroup.Item>
  )
}


export default NotesFilter
