import React from 'react'
import { useDevice } from 'device'
import { devicePx, scrollToElement } from 'helpers'
import { track } from 'analytics'
import { useHistory, usePathname } from 'router'

import ButtonView from '../ButtonView/ButtonView'

import messages from './messages'


const ClearAllButton: React.FunctionComponent = () => {
  const history = useHistory()
  const pathname = usePathname()
  const { isMobile } = useDevice()

  const handleClick = () => {
    track('Filter clear result click', {
      filterBy: 'All',
    })

    // drop searchParams
    history.push(pathname, { scroll: false })

    if (isMobile) {
      scrollToElement('#catalogueFilters', { onlyUp: true, compensation: devicePx(-20), smooth: true })
    }
  }

  return (
    <ButtonView title={messages.title} isBold onClick={handleClick} data-testid="clearAllFilters" />
  )
}


export default ClearAllButton
