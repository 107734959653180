import React from 'react'
import cx from 'classnames'
import { track } from 'analytics'
import { useDevice } from 'device'
import type { ComponentPageGiftHubCard } from 'typings/strapi.graphql'

import { Image, RichText } from 'components/dataDisplay'
import { Href } from 'components/navigation'

import s from './GiftHubCard.module.css'


const sizeToClassName = [ s['size-1'], s['size-2'], s['size-3'] ] as const

const variantToClassName = {
  basic: 'p-24',
  offer: 'flex h-full w-full items-end justify-center p-12',
} as const

type GiftHubCardProps = {
  className?: string
  data: ComponentPageGiftHubCard
}

const GiftHubCard: React.FC<GiftHubCardProps> = (props) => {
  const { className, data } = props
  const {
    desktopImage,
    desktopOrder = 0,
    desktopText,
    desktopWhite,
    label,
    link,
    mixpanelGiftType,
    mobileImage,
    mobileOrder = 0,
    mobileText,
    mobileWhite,
    size = 1,
    variant = 'basic',
  } = data

  const { isMobile } = useDevice()

  const withLink = Boolean(link)

  const text = isMobile ? mobileText : desktopText
  const image = isMobile ? mobileImage : desktopImage
  const white = isMobile ? mobileWhite : desktopWhite

  const orderStyle = { order: isMobile ? mobileOrder : desktopOrder }

  const sizeClassName = sizeToClassName[size - 1]
  const variantClassName = variantToClassName[variant]

  const containerClassName = cx(className, 'relative', isMobile ? s['size-3'] : sizeClassName, !isMobile && 'h-[304rem]')

  const content = (
    <>
      <Image
        className="size-full object-cover"
        src={image?.data?.attributes?.url}
      />
      <div className={cx('absolute left-0 top-0 z-[1] size-full', label && (isMobile ? 'py-20' : 'flex items-center'))}>
        <div
          className={cx(variantClassName, s['children-full-width'], {
            'inline-block bg-black py-16': label,
            'text-white': white,
          })}
        >
          <RichText message={text} />
        </div>
      </div>
    </>
  )

  if (withLink) {
    const handleClick = () => {
      track('Gift explore click', {
        giftType: mixpanelGiftType?.replace(/_/g, ' '),
        link,
      })
    }

    return (
      <Href to={link} className={containerClassName} style={orderStyle} onClick={handleClick}>
        {content}
      </Href>
    )
  }

  return (
    <div className={containerClassName} style={orderStyle}>
      {content}
    </div>
  )
}

export default GiftHubCard
