import { useMemo } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useQuery } from 'apollo-client'
import dayjs from 'date'
import { usePathname, useSearchParams } from 'router'

import { constants } from 'helpers'
import { useFt } from 'hooks'

import { useLocalePreferences } from 'modules/localePreferences'
import { useUser } from 'modules/user'

import pageContentStrapiQuery from './graph/pageContent.strapi.graphql'

import modifyDynamicPageContent from './modifyDynamicPageContent'


export type UseDynamicPageContentProviderProps = {
  ssr?: boolean
}

const useDynamicPageContentProvider = (props: UseDynamicPageContentProviderProps = {}) => {
  const { ssr = true } = props

  const pathname = usePathname()
  const [ { previewId } ] = useSearchParams<{ previewId?: string }>()

  const isStrapiDynamicPageEnabled = useFt(constants.features.strapiDynamicPage)
  const isStrapiLocalizationEnabled = useFeatureIsOn(constants.features.strapiLocalization)
  const isStrapiLocalizationPageEnabled = useFeatureIsOn(constants.features.strapiLocalizationPage)

  const { dateTime } = useUser()
  const { localePreferences } = useLocalePreferences()

  const nowDateTime = dayjs(dateTime).startOf('hour').utc().format()

  const filters = previewId ? [
    {
      id: { eq: previewId },
    },
  ] : [
    isStrapiLocalizationEnabled && {
      or: [
        { locale: { null: true } },
        // retrieve pages with fallback locale when feature is disabled
        { locale: { containsi: isStrapiLocalizationPageEnabled ? localePreferences?.locale : 'en-US' } },
      ],
    },
    {
      or: [
        { startsFrom: { null: true } },
        { startsFrom: { lte: nowDateTime } },
      ],
    },
    {
      or: [
        { endsAfter: { null: true } },
        { endsAfter: { gte: nowDateTime } },
      ],
    },
  ]

  const { data, isFetching } = useQuery(pageContentStrapiQuery, {
    client: 'strapi',
    ssr,
    skip: !isStrapiDynamicPageEnabled,
    variables: {
      filters: {
        and: [
          { url: { eq: pathname } },
          ...filters.filter(Boolean),
        ],
      },
      publicationState: previewId ? 'PREVIEW' : 'LIVE',
      sort: [ 'startsFrom:asc', 'endsAfter:asc', ...(isStrapiLocalizationEnabled ? [ 'locale:asc' ] : []) ], // make sure that evergreen page is the last one
    },
    context: {
      ssrCache: true,
    },
  })

  const modifiedData = useMemo(() => {
    if (!data) {
      return null
    }

    return modifyDynamicPageContent(data.pages?.data?.[0]?.attributes)
  }, [ data ])

  return {
    data: modifiedData,
    isFetching,
  }
}

export default useDynamicPageContentProvider
