import React, { useMemo } from 'react'
import cx from 'classnames'
import config from 'config'
import { safeJsonStringify } from 'helpers'
import { getErrorInfo } from './utils'


type ErrorTechnicalDetailsProps = {
  className?: string
  error?: string | Error
  traceId?: string
}

const isProd = config.runEnv === 'prod'

const ErrorTechnicalDetails: React.FunctionComponent<ErrorTechnicalDetailsProps> = (props) => {
  const { className, error, traceId } = props

  const errorInfo = useMemo(() => getErrorInfo(error), [ error ])

  if (isProd && (errorInfo || traceId)) {
    const logs = [
      `console.log('x-trace-id:', ${JSON.stringify(traceId)})`,
      `console.error(${safeJsonStringify(errorInfo)})`,
    ]

    return (
      <script dangerouslySetInnerHTML={{ __html: logs.join(';') }} />
    )
  }

  return (
    <div className={cx(className, 'hide-empty text-p4 max-w-full rounded bg-white p-16')}>
      {
        Boolean(traceId) && (
          <div>
            <b>x-trace-id:</b>&nbsp;{traceId}
          </div>
        )
      }
      {
        Boolean(errorInfo) && (
          <pre className="text-p4 mt-32" style={{ maxHeight: '300rem', overflow: 'auto' }}>
            {errorInfo}
          </pre>
        )
      }
    </div>
  )
}


export default ErrorTechnicalDetails
