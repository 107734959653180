export default {
  title: {
    en: 'Sort results',
  },
  sort: {
    DEFAULT: {
      en: 'By default',
    },
    PRICE_ASC: {
      en: 'By price, low to high',
    },
    PRICE_DESC: {
      en: 'By price, high to low',
    },
    RATING_DESC: {
      en: 'By rating, high to low',
    },
    NEW_ARRIVALS_DESC: {
      en: 'New arrivals, new to old',
    },
  },
  button: {
    DEFAULT: {
      en: 'Sort',
    },
    PRICE_ASC: {
      en: 'Sort by price, low to high',
    },
    PRICE_DESC: {
      en: 'Sort by price, high to low',
    },
    RATING_DESC: {
      en: 'Sort by rating, high to low',
    },
    NEW_ARRIVALS_DESC: {
      en: 'Sort by new arrivals, new to old',
    },
  },
}
