import React from 'react'
import { useDevice } from 'device'

import { useStrapiBlockIndent } from 'modules/strapi'

import type { ComponentPageBrandsLogoBlend } from 'typings/strapi.graphql'

import DesktopView from './components/DesktopView/DesktopView'
import MobileView from './components/MobileView/MobileView'


type BrandsLogoBlendProps = BrandPage.StrapiBodySectionsBaseProps<ComponentPageBrandsLogoBlend>

const DEFAULT_INDENT = {
  mobile: 'mt-40',
  desktop: 'mt-48',
}

const BrandsLogoBlend: React.FunctionComponent<BrandsLogoBlendProps> = (props) => {
  const { data } = props
  const { indent, rows } = data

  const { isMobile } = useDevice()

  const blockIndentClassName = useStrapiBlockIndent(indent, DEFAULT_INDENT)

  if (isMobile) {
    return (
      <MobileView className={blockIndentClassName} data={rows} />
    )
  }

  return (
    <DesktopView className={blockIndentClassName} data={rows} />
  )
}

export default BrandsLogoBlend
