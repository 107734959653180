import React, { useRef, useState } from 'react'
import cx from 'classnames'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

import { Text } from 'components/dataDisplay'
import { ButtonBase } from 'components/inputs'

import messages from './messages'


type DesktopProps = {
  className?: string
  uniqueKey?: string
  placement?: 'page' | 'modal'
}

const Desktop: React.CFC<DesktopProps> = (props) => {
  const { className, uniqueKey, placement = 'page', children } = props

  const [ isOpen, setIsOpen ] = useState(false)
  const [ hiddenTotal, setHiddenTotal ] = useState(0)

  const listRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const marginValueRef = useRef<number>(null)

  const maxWidthCompensation = placement === 'page' ? 100 : 120

  // TODO hope we will remove it - added on 01.10.2021 by sonatskiy
  useIsomorphicLayoutEffect(() => {
    const rootEl = listRef.current
    const childList = Array.from(rootEl.children)

    if (isOpen) {
      childList.forEach((child) => {
        child.classList.remove('hidden')
      })
    }
    else {
      const maxWidth = rootEl.parentElement.clientWidth - maxWidthCompensation
      let width = 0
      let hiddenCount = 0

      if (marginValueRef.current === null) {
        const elementInTheMiddleStyle = childList[1] ? getComputedStyle(childList[1]) : null

        marginValueRef.current = elementInTheMiddleStyle
          ? parseInt(elementInTheMiddleStyle.marginLeft) + parseInt(elementInTheMiddleStyle.marginRight)
          : 12 // fallback
      }

      childList.forEach((child) => {
        width += child.clientWidth + marginValueRef.current

        if (width >= maxWidth && child !== buttonRef.current) {
          child.classList.add('hidden')
          hiddenCount++
        }
        else {
          child.classList.remove('hidden')
        }
      })

      setHiddenTotal(hiddenCount)
    }

    rootEl.classList.remove('opacity-0')
  }, [ isOpen, uniqueKey, maxWidthCompensation ])

  return (
    // key is required for correct calculations and render
    <div
      className={cx(className, 'flex justify-start opacity-0', isOpen && 'flex-wrap')}
      key={uniqueKey}
      ref={listRef}
      data-testid="activeFilterList"
    >
      {children}
      {
        !isOpen && hiddenTotal > 0 && (
          <ButtonBase ref={buttonRef} className="cursor-pointer" onClick={() => setIsOpen(true)}>
            <Text message={{ ...messages.more, values: { count: hiddenTotal } }} style="p4" color="gold-70" />
          </ButtonBase>
        )
      }
    </div>
  )
}


export default React.memo(Desktop)
