import { createContext, useContext } from 'react'
import type { ApolloError } from 'apollo-client'
import type { SelectCampaignsModifiedData } from 'modules/selectCampaigns/SelectCampaignsProvider'


export type SelectCampaignsContextValue = {
  data: SelectCampaignsModifiedData
  error: ApolloError
  isFetching: boolean
}

export const SelectCampaignsContext = createContext<SelectCampaignsContextValue>(null)

export const useSelectCampaigns = () => useContext(SelectCampaignsContext)
