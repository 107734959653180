import React from 'react'
import links from 'links'
import { Redirect } from 'router'
import { useCatalogueId } from 'modules/products'

import { WhitePageLayout } from 'components/layout'
import PageSkeleton from 'compositions/PageSkeleton/PageSkeleton'

import { CatalogueContext, useCatalogueContextValue, type UseCatalogueContextValueProps } from 'pages/catalog/util'


type CatalogueContextProviderContentProps = UseCatalogueContextValueProps & {
  children: React.ReactNode
}

const CatalogueContextProviderContent = (props: CatalogueContextProviderContentProps) => {
  const { children } = props

  const contextValue = useCatalogueContextValue(props)

  return (
    <CatalogueContext.Provider value={contextValue}>
      <WhitePageLayout>
        {children}
      </WhitePageLayout>
    </CatalogueContext.Provider>
  )
}

type CatalogueContextProviderProps = Omit<UseCatalogueContextValueProps, 'catalogueId' | 'catalogueKey'>

const CatalogueContextProvider: React.CFC<CatalogueContextProviderProps> = (props) => {
  const {
    children,
    catalogueName,
    defaultFilters,
    defaultSort,
    isEcommerce = false,
    isExtras = false,
    productsFeed,
    limit,
    filtersStrategy,
  } = props

  const { catalogueId, catalogueKey, isFetching } = useCatalogueId({ catalogueName })

  if (isFetching) {
    return <PageSkeleton />
  }

  if (!catalogueId && !catalogueKey) {
    return <Redirect to={links.subscription.main} />
  }

  return (
    <CatalogueContextProviderContent
      catalogueId={catalogueId}
      catalogueKey={catalogueKey}
      catalogueName={catalogueName}
      filtersStrategy={filtersStrategy}
      defaultFilters={defaultFilters}
      defaultSort={defaultSort}
      isEcommerce={isEcommerce}
      isExtras={isExtras}
      productsFeed={productsFeed}
      limit={limit}
    >
      {children}
    </CatalogueContextProviderContent>
  )
}


export default CatalogueContextProvider
