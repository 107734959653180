import React from 'react'
import { cx } from 'helpers'
import { useScrollingFrame } from 'hooks'
import type { UploadFile } from 'typings/strapi.graphql'

import { Image } from 'components/dataDisplay'


type BrandsRowProps = {
  className?: string
  imageWidth: number
  imageHeight: number
  imageData: UploadFile
  inverted?: boolean
  isVisible?: boolean
}

const BrandsRow: React.FunctionComponent<BrandsRowProps> = (props) => {
  const { className, imageData, imageHeight, imageWidth, inverted, isVisible } = props

  const imageRef = useScrollingFrame({ inverted, isVisible })

  return (
    <div className={cx(className, 'relative overflow-hidden')} style={{ paddingTop: `${imageHeight}rem` }}>
      <div ref={imageRef} className="absolute left-0 top-0 mx-16" style={{ width: `${imageWidth}rem` }}>
        <Image
          className="object-contain"
          src={imageData?.url}
          remWidth={imageWidth}
          alt={imageData?.alternativeText}
          lazy
        />
      </div>
    </div>
  )
}

export default BrandsRow
