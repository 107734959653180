export type UseProductCollaborationProps = {
  labels: ProductFragment.Base['rebrandLabels']
}

const getCollaboration = (rebrandLabels: ProductFragment.Base['rebrandLabels']): CollaborationsModule.Collaboration => {
  if (rebrandLabels?.extra?.includes('SAKS_SELECTED')) {
    return 'saks'
  }
}

const useProductCollaboration = ({ labels }: UseProductCollaborationProps ): CollaborationsModule.Collaboration => {
  const collaboration = getCollaboration(labels)

  if (collaboration === 'saks') {
    return collaboration
  }

  return null
}

export default useProductCollaboration
