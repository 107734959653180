import { useMemo } from 'react'

import { useCatalogueProducts, type UseCatalogueProductsProps } from 'modules/products'
import type { ProductsFeedPage } from 'typings/graphql'

import useCollaborationCatalogueId from './useCollaborationCatalogueId'


type UseCollaborationProductsProps = {
  catalogueType: CollaborationsModule.CatalogueType
  collaboration: CollaborationsModule.Collaboration
  excludeId?: number
  fetchPolicy?: 'cache-and-network'
  limit?: number
  productsFeed?: ProductsFeedPage
  skip?: boolean
  filter?: UseCatalogueProductsProps['variables']['input']['filter']
}

const useCollaborationProducts = (props: UseCollaborationProductsProps) => {
  const { catalogueType, collaboration, excludeId, fetchPolicy, limit, productsFeed, skip, filter } = props

  const { catalogueId, catalogueKey, isFetching: isFetchingCatalogueId } = useCollaborationCatalogueId({
    catalogueType,
    collaboration,
    skip,
  })

  const { data: rawProducts, cnstrcResultId, isFetching: isFetchingProducts } = useCatalogueProducts({
    variables: {
      catalogueId,
      catalogueKey,
      input: {
        limit: limit ? excludeId ? limit + 1 : limit : null,
        productsFeed,
        filter,
      },
    },
    fetchPolicy,
    skip: skip || !(catalogueId || catalogueKey),
  })

  const products = useMemo(() => {
    return rawProducts?.products?.filter((product) => {
      const { id } = product

      return id !== excludeId
    }).slice(0, limit) || []
  }, [ excludeId, limit, rawProducts ])

  return {
    data: products,
    isFetching: isFetchingCatalogueId || isFetchingProducts,
    catalogueId,
    catalogueKey,
    cnstrcResultId,
  }
}

export default useCollaborationProducts
