export default {
  title: {
    en: 'Personalize',
  },
  description: {
    en: 'Explore fragrances for all your moods by filtering to your exact needs.',
  },
  descriptionFilter: {
    en: 'If you select any of the areas below, your <b>filters</b> will be removed.',
  },
}
