import React, { useEffect, useMemo } from 'react'
import { useLocationState } from 'router'
import { useDevice } from 'device'
import { track } from 'analytics'
import { useFt } from 'hooks'
import { constants, getTradingItemsByVolume } from 'helpers'

import { useCnstrcResultsTrackingDataAttrs } from 'modules/constructorIO'

import type { Volume } from 'typings/graphql'

import { Pagination } from 'components/navigation'
import InfinityScrollProductFeed from 'compositions/InfinityScrollProductFeed/InfinityScrollProductFeed'

import { useCatalogueContext } from 'pages/catalog/util'
import FiltersSection from 'pages/catalog/components/FiltersSection/FiltersSection'

import messages from './messages'


const metadata = {
  placement: 'Feed',
}

const limit = 24

type ProductFeedProps = {
  filtersClassName?: string
  withSort?: boolean
  withFilters?: boolean
  isEcommerceFilters?: boolean
  hideWithoutTradingItems?: boolean
  injectBanners?: CataloguePages.ProductFeedViewProps['injectBanners']
  injectFeedBanners?: CataloguePages.ProductFeedViewProps['injectFeedBanners']
  filtersByVolume?: Volume[]
  filtersModalHeaderContent?: React.ReactElement
  withEcommercePrefix?: boolean
  withForwardedTradingItemUid?: boolean
  withProductPriceRange?: boolean
  showGwpFilter?: boolean
  showSaleFilter?: boolean
  onProductButtonClick?: ProductCard.OnProductButtonClick
} & AllOrNothing<{
  isFragrance: boolean
  isMale: boolean
}>

const ProductFeed: React.FunctionComponent<ProductFeedProps> = (props) => {
  const {
    injectBanners, injectFeedBanners, filtersClassName, withFilters = false, isFragrance = false, isMale = false,
    hideWithoutTradingItems = false, filtersByVolume, withEcommercePrefix, withSort = false, isEcommerceFilters = false, withForwardedTradingItemUid = false,
    showGwpFilter, showSaleFilter, filtersModalHeaderContent, withProductPriceRange, onProductButtonClick,
  } = props

  const { previousUrl } = useLocationState()
  const isEcommerceTwoZeroEnabled = useFt(constants.features.ecommerceTwoZero)
  const {
    catalogueId,
    catalogueKey,
    cnstrcResultId,
    products,
    count,
    appliedFiltersCount,
    isFetchingFilters,
    isFetchingProducts,
    isEcommerce,
    isExtras,
    fetchMore,
  } = useCatalogueContext()

  const { isMobile, isBot } = useDevice()

  const { visibleProducts, visibleProductsAmount, totalCount } = useMemo(() => {
    let productsList = hideWithoutTradingItems
      ? products?.filter(({ tradingItems }) => Boolean(tradingItems.items.length))
      : products?.slice()

    if (productsList?.length > 0 && filtersByVolume?.length > 0) {
      productsList = productsList.reduce((result, product) => {
        const filteredTradingItems = getTradingItemsByVolume({
          tradingItems: product?.tradingItems?.items,
          filtersByVolume,
        })

        if (filteredTradingItems.length) {
          result.push({
            ...product,
            tradingItems: {
              items: filteredTradingItems,
            },
          })
        }

        return result
      }, [])
    }

    // inject feed banners
    let list: InfinityScrollProductFeed.Props['products'] = productsList

    // we count before injecting banners
    const visibleProductsAmount = productsList?.length || 0
    const totalCount = count - (products?.length - productsList?.length)
    const hasMore = totalCount - visibleProductsAmount

    if (list?.length && injectFeedBanners?.length) {
      injectFeedBanners.forEach(({ node, index }) => {
        // don't add to the end
        if (index >= productsList.length) {
          return
        }

        list.splice(index, 0, node)

        if (hasMore) {
          // remove last item to compensate banner during loading
          list.splice(list.length - 1, 1)
        }
      })
    }

    return {
      visibleProducts: list,
      visibleProductsAmount,
      totalCount,
    }
  }, [ count, products, hideWithoutTradingItems, filtersByVolume, injectFeedBanners ])

  // ConstructorIO browse attributes
  const cnstrcBrowseTrackingDataAttrs = useCnstrcResultsTrackingDataAttrs({
    type: 'browse',
    count: totalCount,
    resultId: cnstrcResultId,
    filterName: catalogueKey && 'collection_id',
    filterValue: catalogueKey?.toString() || catalogueId?.toString() || '',
  })

  // TODO: Remove after backend implement server-side filter — added on 05–10–2022 by algeas
  const countMessage = withFilters ? {
    ...appliedFiltersCount > 0
      ? isFragrance ? messages.matchesFragranceCount : messages.matchesProductCount
      : isFragrance ? messages.defaultFragranceCount : messages.defaultProductCount,
    values: { count: totalCount || '', isMale },
  } : null

  const restProductsAmount = count - visibleProductsAmount
  const fetchMoreSkeletonsAmount = restProductsAmount > limit ? limit : restProductsAmount

  useEffect(() => {
    if (!previousUrl && withFilters && !isFetchingProducts && appliedFiltersCount > 0 && count === 0) {
      track('Filter zero result display', {
        flow: 'Direct link',
      })
    }
  }, [ appliedFiltersCount, count, isFetchingProducts, withFilters, previousUrl ])

  const isFetching = isFetchingFilters || isFetchingProducts
  const filterSectionClassName = filtersClassName || (isMobile ? null : 'mt-48')
  const isFiltersVisible = withFilters && (!isEcommerceFilters || isEcommerceTwoZeroEnabled)

  const pageCount = Math.ceil((count || totalCount) / limit)
  const isPaginationVisible = isBot && Boolean(pageCount > 1)

  return (
    <>
      {
        isFiltersVisible && (
          <FiltersSection
            className={filterSectionClassName}
            filtersModalHeaderContent={filtersModalHeaderContent}
            countMessage={countMessage}
            isFetching={isFetching}
            withSort={withSort}
            isEcommerce={isEcommerceFilters}
            showGwpFilter={showGwpFilter}
            showSaleFilter={showSaleFilter}
          />
        )
      }
      <InfinityScrollProductFeed
        className={isMobile ? (isFiltersVisible ? null : 'mt-16') : (isFiltersVisible ? 'mt-16' : 'mt-48')}
        products={visibleProducts}
        metadata={metadata}
        injectBanners={injectBanners}
        totalCount={totalCount}
        fetchMore={fetchMore}
        fetchSkeletonsAmount={fetchMoreSkeletonsAmount}
        isEcommerce={isEcommerce || isExtras}
        withProductPriceRange={withProductPriceRange}
        withEcommercePrefix={withEcommercePrefix}
        withForwardedTradingItemUid={withForwardedTradingItemUid}
        onProductButtonClick={onProductButtonClick}
        isFetching={isFetching}
        dataAttributes={cnstrcBrowseTrackingDataAttrs}
      />
      {
        isPaginationVisible && (
          <Pagination
            className="mt-40 text-center"
            pageCount={pageCount}
          />
        )
      }
    </>
  )
}


export default ProductFeed
