import { useEffect } from 'react'
import { useGrowthBook } from '@growthbook/growthbook-react'
import logger from 'logger'


const useSyncGrowthBookAttributes = (props: AnalyticsSetup.HookProps) => {
  const { user, country, growthBookUid, isFetching } = props
  const { id: userId, email, analyticsMetadata } = user || {}

  const gb = useGrowthBook()
  const isNotReady = !gb?.ready || isFetching

  useEffect(() => {
    if (isNotReady) {
      return
    }

    try {
      void gb.updateAttributes({
        growthBookUid,
        id: userId,
        email,
        loggedIn: Boolean(userId),
        country,
        ...analyticsMetadata,
      })
    }
    catch (error) {
      logger.error(error)
    }

  }, [ userId, email, analyticsMetadata, gb, isNotReady, growthBookUid, country ])
}

export default useSyncGrowthBookAttributes
