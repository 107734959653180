import { useCatalogueId } from 'modules/products'
import type { CatalogueName } from 'modules/products/useCatalogueId'


type UseSelectCampaignCatalogueId = {
  catalogueType: CollaborationsModule.CatalogueType
  skip?: boolean
}

const collaborationCatalogues = {
  all: 'subscription' as CatalogueName,
  colognes: 'colognes',
  perfumes: 'perfumes',
}

const useSelectCampaignCatalogueId = ({ catalogueType, skip }: UseSelectCampaignCatalogueId) => {
  const collaborationCatalogueName = collaborationCatalogues[catalogueType]

  return useCatalogueId({
    catalogueName: collaborationCatalogueName,
    skip: skip || !collaborationCatalogueName,
  })
}

export default useSelectCampaignCatalogueId
