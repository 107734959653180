import { useMemo } from 'react'
import { useQuery } from 'apollo-client'
import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useUser } from 'modules/user'
import selectCampaignsQuery, { type SelectCampaignsPayload } from 'modules/selectCampaigns/graph/selectCampaigns.graphql'
import { SelectCampaignsContext } from 'modules/selectCampaigns/SelectCampaignsContext'


export type SelectCampaignsModifiedData = ReturnType<typeof modifyData>

const modifyData = (campaignsList: SelectCampaignsPayload['selectCampaigns']['data']) => {
  const labelsMap: SelectCampaignsModule.CampaignLabelsMap = {}
  const campaignsMap: SelectCampaignsModule.CampaignDataMap = {}

  campaignsList?.forEach((selectCampaign) => {
    campaignsMap[selectCampaign.id] = selectCampaign

    if (selectCampaign.gwpCampaignShortLabel && selectCampaign.gwpCampaignFullLabel) {
      labelsMap[selectCampaign.id] = {
        shortLabel: selectCampaign.gwpCampaignShortLabel,
        fullLabel: selectCampaign.gwpCampaignFullLabel,
      }
    }
  })

  return {
    labelsMap,
    campaignsMap,
    campaignsList,
  }
}

const SelectCampaignsProvider: React.CFC = ({ children }) => {
  const { country } = useUser()
  const isSelectCampaignLabelsEnabled = useFeatureIsOn(constants.features.selectCampaignLabels)

  const { data, isFetching, error } = useQuery(selectCampaignsQuery, {
    variables: {
      country,
    },
    fetchPolicy: 'cache-first',
    context: {
      ssrCache: true,
    },
    skip: !isSelectCampaignLabelsEnabled,
  })

  const modifiedData = useMemo(() => {
    if (!data?.selectCampaigns?.data) {
      return null
    }

    return modifyData(data.selectCampaigns.data)
  }, [ data ])

  const value = {
    data: modifiedData,
    isFetching,
    error,
  }

  return (
    <SelectCampaignsContext.Provider value={value}>
      {children}
    </SelectCampaignsContext.Provider>
  )
}

export default SelectCampaignsProvider
