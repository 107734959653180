const INDENT_MAP = {
  'top_0': 'mt-0',
  'top_4': 'mt-4',
  'top_6': 'mt-6',
  'top_8': 'mt-8',
  'top_12': 'mt-12',
  'top_16': 'mt-16',
  'top_20': 'mt-20',
  'top_24': 'mt-24',
  'top_32': 'mt-32',
  'top_36': 'mt-36',
  'top_40': 'mt-40',
  'top_48': 'mt-48',
  'top_56': 'mt-56',
  'top_60': 'mt-60',
  'top_64': 'mt-64',
  'top_72': 'mt-72',
  'top_80': 'mt-80',
  'top_96': 'mt-96',
}

const getIndentFromStrapiValue = (indent: string) => {
  return INDENT_MAP[indent]
}

export default getIndentFromStrapiValue
