import React from 'react'
import { twcx } from 'helpers'

import { Image } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'

import type { FeaturedBrandsProps } from '../../FeaturedBrands'


const DesktopView: React.FunctionComponent<FeaturedBrandsProps> = (props) => {
  const { className, data } = props

  const rows = data?.brands?.data

  if (!rows) {
    return null
  }

  return (
    <WidthContainer className={twcx('mt-48 text-center', className)} size={1440}>
      {
        rows.map((row, index) => {
          const { attributes, id } = row
          const { alternativeText, url } = attributes

          return (
            <Image
              key={id}
              className={twcx('h-[44rem] max-w-full object-contain', index > 0 && 'mt-24')}
              alt={alternativeText}
              src={url}
              lazy
            />
          )
        })
      }
    </WidthContainer>
  )
}

export default DesktopView
