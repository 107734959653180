export default {
  categories: {
    title: {
      en: 'Filter By Catalogue',
    },
    theShopCandles: {
      en: 'Candles',
    },
    theShopCandleWickTrimmer: {
      en: 'Candle Wick Trimmer',
    },
    theShopDiffusers: {
      en: 'Diffusers',
    },
    theShopIncense: {
      en: 'Incense',
    },
    theShopDeodorants: {
      en: 'Deodorant',
    },
    theShopLotionsAndOils: {
      en: 'Lotions & Oils',
    },
    theShopBodyAndHairMist: {
      en: 'Body Mist & Hair Mist',
    },
    theShopBathAndShowerGel: {
      en: 'Bath & Shower Gel',
    },
    theShopHandSanitizer: {
      en: 'Hand Sanitizer',
    },
  },
  collaborations: {
    scentbirdSelect: {
      en: 'Scentbird Select',
    },
  },
  labels: {
    new: {
      en: 'New!',
    },
  },
}
