import { useCallback, useMemo } from 'react'
import { useQuery } from 'apollo-client'
import { useDevice } from 'device'
import { useIntl } from 'intl'
import { useSearchParams } from 'router'

import { devicePx, scrollToElement, string } from 'helpers'

import { useCatalogueId } from 'modules/products'
import type { CatalogueName } from 'modules/products/useCatalogueId'
import useSelectCampaignProducts from 'modules/selectCampaigns/useSelectCampaignProducts'

import selectCampaignProductsDataQuery from './graph/selectCampaignProductsData.graphql'

import messages from './messages'


type UseContextProviderValueProps = {
  selectCampaignKey: string
  limit: number
}

const useAvailableFilters = () => {
  const intl = useIntl()

  const { catalogueId, isFetching: isCatalogueIdFetching } = useCatalogueId({ catalogueName: 'subscription' as CatalogueName })

  const { data, isFetching } = useQuery(selectCampaignProductsDataQuery, {
    variables: {
      catalogueId,
    },
    skip: !catalogueId,
  })

  const { brandValues, noteValues } = useMemo(() => {
    if (!data) {
      return {
        brandValues: null,
        noteValues: null,
      }
    }

    const values = {
      brands: {} as Record<string, any>,
      notes: {} as Record<string, any>,
    }

    data?.catalogue?.data?.products?.products?.forEach((item) => {
      const { brandInfo: { id, name }, notes } = item

      if (!values.brands[id]) {
        values.brands[id] = {
          id: name,
          name,
          rebrandImage: null,
          image: null,
          popular: false,
          group: null,
        }
      }

      notes.forEach(({ id, name, image, rebrandImage }) => {
        values.notes[id] = {
          id: name,
          name,
          rebrandImage,
          image,
          popular: false,
          group: null,
        }
      })
    })

    return {
      brandValues: Object.values(values.brands).sort((a, b) => string.normalizeClear(a.name).localeCompare(string.normalizeClear(b.name))),
      noteValues: Object.values(values.notes).sort((a, b) => a.name.localeCompare(b.name)),
    }
  }, [ data ])

  const availableFilters = useMemo(() => {
    const filters: CataloguePages.AvailableFilters = {
      CATALOGUE: {
        filterSelectionType: 'SINGLE',
        filterType: 'CATALOGUE',
        id: 'catalogue',
        title: 'Filter by type',
        values: [ 'all', 'perfumes', 'colognes' ].map((name) => ({
          group: '',
          id: name,
          image: '',
          name: intl.formatMessage(messages[name]),
          popular: false,
          rebrandImage: '',
        })),
      },
    }

    if (brandValues) {
      filters.BRANDS = {
        filterSelectionType: 'MULTIPLE',
        filterType: 'BRANDS',
        id: 'brands',
        title: 'Filter by Brands',
        values: brandValues,
        settings: {
          groupByFirstLetter: false,
        },
      }
    }

    if (noteValues) {
      filters.NOTES = {
        filterSelectionType: 'MULTIPLE',
        filterType: 'NOTES',
        id: 'notes',
        title: 'Filter by Notes',
        values: noteValues,
      }
    }

    return filters
  }, [ intl, brandValues, noteValues ])

  return {
    availableFilters,
    isFetchingFilters: isCatalogueIdFetching || isFetching,
  }
}

const filterActualByAvailable = (actual: string, available: {
  id: string
}[] | null, typeSelection: string): string[] => {
  if (!actual) {
    return null
  }

  let result

  if (typeSelection === 'MULTIPLE') {
    result = actual.split(',')
  }
  else {
    result = [ actual ]
  }

  // check values only if we have them
  if (available?.length) {
    result = result.filter((item) => item && available.find(({ id }) => item === id))
  }

  return result?.length ? result : null
}

const useFilters = () => {
  const [ searchParams ] = useSearchParams()
  const { availableFilters, isFetchingFilters } = useAvailableFilters()

  const {
    appliedFilters,
    appliedFiltersForRequest,
    appliedFiltersCount,
  } = useMemo(() => {
    let result = {
      appliedFilters: null,
      appliedFiltersForRequest: [],
      appliedFiltersCount: 0,
    }

    result.appliedFilters = Object.values(availableFilters).reduce((acc, {
      filterType,
      filterSelectionType,
      values,
    }) => {
      const searchParamsValue = searchParams[filterType.toLowerCase()]
      const filterValue = filterActualByAvailable(searchParamsValue, values, filterSelectionType)

      if (filterValue?.length) {
        // don't show all value
        if (filterType === 'CATALOGUE' && filterValue[0] === 'all') {
          return acc
        }

        result.appliedFiltersCount += filterValue?.length
        acc[filterType] = filterValue

        if (filterType !== 'CATALOGUE') {
          result.appliedFiltersForRequest.push({ type: filterType, filterValueIdList: filterValue })
        }
      }

      return acc
    }, {})

    return result
  }, [ availableFilters, searchParams ])

  return {
    availableFilters,
    appliedFilters,
    appliedFiltersForRequest,
    appliedFiltersCount,
    isFetchingFilters,
  }
}


export const useContextProviderValue = (props: UseContextProviderValueProps): CataloguePages.Context => {
  const { selectCampaignKey, limit } = props
  const { isMobile } = useDevice()

  const {
    availableFilters,
    appliedFilters,
    appliedFiltersForRequest,
    appliedFiltersCount,
    isFetchingFilters,
  } = useFilters()

  const { data: products, isFetching: isFetchingProducts, catalogueId } = useSelectCampaignProducts({
    selectCampaignKey,
    limit,
    catalogueType: appliedFilters.CATALOGUE || 'all',
    productsFeed: 'ALL_PERFUMES',
    filter: appliedFiltersForRequest,
  })

  const scrollToFilters = useCallback(() => {
    if (isMobile) {
      setTimeout(() => {
        // scroll to the top of the list
        scrollToElement('#catalogueFilters', { onlyUp: true, compensation: devicePx(-20), smooth: true })
      }, 50)
    }
  }, [ isMobile ])

  return {
    catalogueId,
    products,
    count: products?.length || 0,
    availableFilters,
    appliedFilters,
    appliedFiltersCount,
    isFetchingFilters,
    isFetchingProducts,
    isEcommerce: false,
    isExtras: false,
    fetchMore: () => {},
    scrollToFilters,
  }
}
