import { useContext, createContext } from 'react'


type FilterDisclosureGroupContextValue = {
  closePreviousItem: (closeCallback?: () => void) => void
}

export const FilterDisclosureGroupContext = createContext<FilterDisclosureGroupContextValue>(null)

export const useFilterDisclosureGroupContext = () => useContext(FilterDisclosureGroupContext)
