import React from 'react'

import { useDynamicPageContent } from 'modules/dynamicPages'

import HeroSection from 'compositions/dynamicPages/HeroSection/HeroSection'
import BodySection from 'compositions/dynamicPages/BodySection/BodySection'


const SelectCampaignPage = () => {
  const data = useDynamicPageContent()

  return (
    <>
      <HeroSection data={data.heroSection} />
      <BodySection data={data.bodySection} />
    </>
  )
}

export default SelectCampaignPage
