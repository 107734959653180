import React from 'react'
import { track } from 'analytics'
import logger from 'logger'
import { useSearchParams } from 'router'

import ButtonView from '../ButtonView/ButtonView'


type FilterItemProps = {
  value: string
  filterKey: CataloguePages.FilterKey
  itemsData: CataloguePages.AvailableFilterData['values']
}

const filterByOptions: Partial<Record<CataloguePages.FilterKey, Analytics.Events['Filter clear result click']['filterBy']>> = {
  BEAUTY: 'Category',
  CATEGORY: 'Category',
  NOTES: 'Notes name',
  TAGS: 'Tag',
  BRANDS: 'Brands name',
  SIZES: 'Size',
  PRICE: 'Price',
  FULL_BOTTLE_TYPES: 'Type',
  GWP: 'GWP',
  SALE: 'Sale',
}

const FilterItem: React.FunctionComponent<FilterItemProps> = (props) => {
  const { value, filterKey, itemsData } = props

  const type = filterKey.toLowerCase()

  const [ _, setSearchParams ] = useSearchParams()

  const handleClick = () => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = { ...prevSearchParams }
      let trackParams: Analytics.Events['Filter clear result click']

      const isFullBottleTypeFilter = filterKey === 'FULL_BOTTLE_TYPES'
      const isCategoryTypeFilter = filterKey === 'CATEGORY' || filterKey === 'COLLABORATION'
      const isSinglePossibleValueFilter = isFullBottleTypeFilter || filterKey === 'PRICE' || filterKey === 'SIZES'

      if (isCategoryTypeFilter) {
        delete newSearchParams[type]

        trackParams = { filterBy: value as Analytics.FilterByCategoryValue }
      }
      else if (isSinglePossibleValueFilter) {
        const searchKeyToRemove = isFullBottleTypeFilter ? 'fullBottleTypes' : type

        const filterBy = filterByOptions[filterKey]
        const filterRemoved = itemsData.find(({ id }) => id === value)?.name

        delete newSearchParams[searchKeyToRemove]

        trackParams = { filterBy, filterRemoved }
      }
      else if (newSearchParams[type]) {
        const newParamsList = newSearchParams[type].split(',').filter((id) => value !== id)

        newSearchParams[type] = newParamsList.join(',')

        trackParams = { filterBy: filterByOptions[filterKey], filterValue: newParamsList, filterRemoved: value }
      }
      else {
        logger.error({
          isCategoryTypeFilter,
          isFullBottleTypeFilter,
          isSinglePossibleValueFilter,
          searchParams: prevSearchParams,
          type,
        }, 'Search params miss removed filter')
      }

      track('Filter clear result click', trackParams)

      return newSearchParams
    }, { replace: false, scroll: false })
  }

  const { name } = itemsData.find(({ id }) => id === value)

  return (
    <ButtonView title={name} onClick={handleClick} data-testid={`${type}ActiveFilter`} />
  )
}


export default React.memo(FilterItem)
