export default {
  title: {
    en: 'Filters',
  },
  description: {
    en: 'If you select any of the filters below, your <b>personalized selections</b> will be removed.',
  },
  expand: {
    en: 'Expand your search',
  },
  notes: {
    en: 'Filter by perfume notes',
  },
}
