import React, { useEffect } from 'react'
import localStorage from 'local-storage'
import { openNotification } from 'notifications'

import type { ComponentPagePageNotification } from 'typings/strapi.graphql'

import mothersDayImage from './images/mothers-day-notification.png'


type PageNotificationProps = {
  data: ComponentPagePageNotification
}

const PageNotification: React.FC<PageNotificationProps> = (props) => {
  const { data } = props

  useEffect(() => {
    if (!data) {
      return
    }

    const { notificationGroup, text, title, titleColor } = data

    if (!localStorage.getSessionItem(notificationGroup)) {
      localStorage.setSessionItem(notificationGroup, true)

      // TODO: Replace with more flexible Strapi notification component — added on 15–04–2024 by algeas
      if (notificationGroup === 'mothersDayCutOffNotification') {
        openNotification('imageWithText', {
          text: text,
          textStyle: 'p2',
          image: mothersDayImage,
          imageClassName: '-my-16 -ml-24 w-[98rem] h-[112rem]',
          underPopups: true,
          onClose: () => {
            localStorage.setSessionItem('cutOffNotification', true)
          },
        })
      }
      else {
        openNotification('plain', {
          title,
          text,
          titleColor: titleColor ? titleColor.replace(/_/g, '-') as any : null,
        })
      }
    }
  }, [ data ])

  return null
}

export default PageNotification
