import { useMemo } from 'react'

import type { TradingItemBaseFragmentPayload } from 'modules/products/fragments/tradingItemBase.graphql'


type GetSelectCampaignsIdsProps = {
  tradingItems?: Pick<TradingItemBaseFragmentPayload, 'selectCampaigns'>[]
}

const useProductSelectCampaigns = (props: GetSelectCampaignsIdsProps) => {
  const { tradingItems } = props

  return useMemo((): SelectCampaignsModule.ProductSelectCampaigns => {
    const selectCampaignsMap = new Map()

    tradingItems?.forEach((item) => {
      item.selectCampaigns?.forEach((campaign) => {
        selectCampaignsMap.set(campaign.id, campaign)
      })
    })

    return Array.from(selectCampaignsMap.values())
  }, [ tradingItems ])
}


export default useProductSelectCampaigns
