export default {
  primaryButton: {
    en: `
      Show <span class="text-gold-30" data-testid="count">{count}</span>
      {productType, select, perfume {perfume} cologne {cologne} other {product}}
      {count, plural, one {result} other {results}}
    `,
  },
  clearAll: {
    en: 'Clear all',
  },
  emptyNote: {
    en: `We couldn't find anything in our catalog that matches your selections. Please try editing your search.`,
  },
  tooMuchBrands: {
    en: 'Please edit your search down to eight brands or less, and try again.',
  },
}
