import React from 'react'
import { useDevice } from 'device'

import type { ComponentPageFeaturedBrands } from 'typings/strapi.graphql'

import DesktopView from './components/DesktopView/DesktopView'
import MobileView from './components/MobileView/MobileView'


export type FeaturedBrandsProps = {
  className?: string
  data: ComponentPageFeaturedBrands
}

const FeaturedBrands: React.FunctionComponent<FeaturedBrandsProps> = (props) => {
  const { className, data } = props

  const { isMobile } = useDevice()

  if (isMobile) {
    return (
      <MobileView className={className} data={data} />
    )
  }

  return (
    <DesktopView className={className} data={data} />
  )
}

export default FeaturedBrands
