import type * as Types from '../../../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type GiftSubscriptionPlansForMetaQueryVariables = Types.Exact<{ [key: string]: never; }>;


type GiftSubscriptionPlansForMetaQueryPayload = { __typename?: 'Query', currentUser: { __typename?: 'UserPayload', data: { __typename?: 'UserData', giftPlansInquiry: { __typename?: 'GiftPlansInquiryData', plans: Array<{ __typename?: 'GiftSubscriptionPlan', plan: { __typename?: 'SubscriptionPlan', id: number, price: number } }> } } | null } };


 const GiftSubscriptionPlansForMetaDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GiftSubscriptionPlansForMeta"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"giftPlansInquiry"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"plans"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"plan"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"price"}}]}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GiftSubscriptionPlansForMetaQueryPayload, GiftSubscriptionPlansForMetaQueryVariables>;


export type GiftSubscriptionPlansForMetaVariables = GiftSubscriptionPlansForMetaQueryVariables;
export type GiftSubscriptionPlansForMetaPayload = GiftSubscriptionPlansForMetaQueryPayload;

// remove fragments duplicates
// @ts-expect-error
GiftSubscriptionPlansForMetaDocument['definitions'] = [ ...new Set(GiftSubscriptionPlansForMetaDocument.definitions) ];

export default GiftSubscriptionPlansForMetaDocument;