import React from 'react'
import { useDevice } from 'device'

import { useCatalogueContext } from 'pages/catalog/util'

import Desktop from './view/Desktop/Desktop'
import Mobile from './view/Mobile/Mobile'

import FilterItem from './components/FilterItem/FilterItem'
import BooleanFilterItem from './components/BooleanFilterItem/BooleanFilterItem'
import ClearAllButton from './components/ClearAllButton/ClearAllButton'


type ActiveFiltersListProps = {
  className?: string
}

const ActiveFiltersList: React.FunctionComponent<ActiveFiltersListProps> = ({ className }) => {
  const { appliedFilters, appliedFiltersCount, availableFilters, appliedBooleanFilters, appliedBooleanFiltersCount } = useCatalogueContext()

  const { isMobile } = useDevice()

  if (!appliedFiltersCount && !appliedBooleanFiltersCount) {
    return null
  }

  const Component = isMobile ? Mobile : Desktop

  const isClearAllButtonVisible = appliedFiltersCount > 1

  // TODO: Refactor after code freeze — added on 11–12–2023 by algeas
  const uniqueKey = Object.values({ ...appliedFilters, ...appliedBooleanFilters }).reduce((acc, value) => {
    return acc + value.toString()
  }, '')

  return (
    <Component className={className} uniqueKey={uniqueKey}>
      {
        isClearAllButtonVisible && (
          <ClearAllButton />
        )
      }
      {
        appliedFiltersCount ? (
          Object.keys(appliedFilters).map((key: CataloguePages.FilterKey) => (
            appliedFilters[key].map((value) => (
              <FilterItem
                key={value}
                value={value}
                filterKey={key}
                itemsData={availableFilters[key].values}
              />
            ))
          ))
        ) : null
      }
      {
        appliedBooleanFiltersCount ? (
          appliedBooleanFilters.map((key: CataloguePages.BooleanFilterSearchParamsKey) => (
            <BooleanFilterItem
              key={key}
              filterKey={key}
            />
          ))
        ) : null
      }
    </Component>
  )
}


export default React.memo(ActiveFiltersList)
