import React, { useCallback } from 'react'
import { useDevice } from 'device'
import { constants } from 'helpers'
import { usePathname } from 'router'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { type ModalComponentProps, standaloneModal } from 'modals'
import { useSelectCampaigns } from 'modules/selectCampaigns'

import { Text } from 'components/dataDisplay'
import { ModalWithLayers } from 'components/feedback'

import { useCatalogueContext } from 'pages/catalog/util'

import CategoryList from '../CategoryList/CategoryList'

import BrandsLayer from './components/BrandsLayer/BrandsLayer'
import FilterLayerOpenButton from './components/FilterLayerOpenButton/FilterLayerOpenButton'
import NotesLayer from './components/NotesLayer/NotesLayer'

import messages from './messages'


const FiltersModal: React.FunctionComponent<ModalComponentProps> = ({ closeModal }) => {
  const { isMobile } = useDevice()
  const pathName = usePathname()

  const { data: availableSelectCampaigns } = useSelectCampaigns()
  const { availableFilters, appliedFilters, scrollToFilters } = useCatalogueContext()

  const isSelectCampaignPage = availableSelectCampaigns?.campaignsList?.some((selectCampaign) => {
    return selectCampaign.selectCampaignPageUrl === pathName
  })
  const isSelectCampaignCatalogueFiltersEnabled = useFeatureIsOn(constants.features.selectCampaignCatalogueFilters)

  const handleApply = useCallback(() => {
    closeModal()
    scrollToFilters()
  }, [ closeModal, scrollToFilters ])

  const areCategoriesAvailable = Boolean(
    availableFilters?.CATEGORY
    || availableFilters?.BEAUTY
    || availableFilters?.CATALOGUE
    || availableFilters?.COLLABORATION
  )
  const areRegularCategoriesAvailable = Boolean(availableFilters?.CATEGORY || availableFilters?.BEAUTY)
  const areBrandsAvailableBrands = Boolean(availableFilters?.BRANDS)
  const areNotesAvailable = Boolean(availableFilters?.NOTES)
  const areSelectCampaignFiltersAvailable = !isSelectCampaignPage && isSelectCampaignCatalogueFiltersEnabled && Boolean(availableFilters?.SELECT_CAMPAIGNS)

  const areTagsFilerApplied = Boolean(appliedFilters?.TAGS?.length)

  return (
    <ModalWithLayers
      type={isMobile ? 'sidebar-left' : 'sidebar-right'}
      bgColor="white"
      closeModal={closeModal}
      data-testid="filtersModal"
    >
      <Text className={areTagsFilerApplied ? 'mb-24' : 'mb-32'} message={messages.title} style="sh5" />
      {
        areTagsFilerApplied && (
          <Text className="mb-32" message={messages.description} style="p2" html />
        )
      }
      {
        areSelectCampaignFiltersAvailable && (
          <CategoryList filterType="SELECT_CAMPAIGNS" withBorderBottom={areRegularCategoriesAvailable} onClick={handleApply} />
        )
      }
      {
        areCategoriesAvailable && (
          <>
            <CategoryList filterType="COLLABORATION" withBorderBottom={areRegularCategoriesAvailable} onClick={handleApply} />
            <CategoryList filterType="CATALOGUE" onClick={handleApply} />
            <CategoryList filterType="CATEGORY" onClick={handleApply} />
            <CategoryList filterType="BEAUTY" onClick={handleApply} />
            <Text className="mb-24 mt-48" message={messages.expand} style="sh5" />
          </>
        )
      }
      {
        areBrandsAvailableBrands && (
          <>
            <FilterLayerOpenButton layerId="BRANDS" />
            <ModalWithLayers.Layer id="BRANDS" bgColor="white">
              <BrandsLayer onApply={handleApply} />
            </ModalWithLayers.Layer>
          </>
        )
      }

      {
        areNotesAvailable && (
          <>
            <FilterLayerOpenButton layerId="NOTES" withBorder />
            <ModalWithLayers.Layer id="NOTES" bgColor="white">
              <NotesLayer onApply={handleApply} />
            </ModalWithLayers.Layer>
          </>
        )
      }
    </ModalWithLayers>
  )
}

declare global {
  interface ModalsRegistry extends ExtendModalsRegistry<{ filtersModal: typeof FiltersModal }> {}
}


export default standaloneModal('filtersModal', FiltersModal)
