import React from 'react'

import { WidthContainer } from 'components/layout'
import ProductCardDetailedSkeleton from 'compositions/productCards/ProductCardDetailedSkeleton/ProductCardDetailedSkeleton'

import NoItems from '../NoItems/NoItems'
import ProductList from '../ProductList/ProductList'
import FetchMoreIndicator from '../FetchMoreIndicator/FetchMoreIndicator'


const InfinityScrollProductFeedDesktopView: React.FC<InfinityScrollProductFeed.Props> = (props) => {
  const {
    className, products, metadata, fetchMore, totalCount, fetchSkeletonsAmount, injectBanners,
    isEcommerce, isFetching, excludeLabels, withoutNotes, withEcommercePrefix, withForwardedTradingItemUid = false,
    withProductPriceRange, onProductButtonClick, dataAttributes,
  } = props

  if (!isFetching && products && !products?.length) {
    return (
      <NoItems className="mt-96" />
    )
  }

  const productListCommonProps = {
    metadata,
    excludeLabels,
    isEcommerce,
    withProductPriceRange,
    withEcommercePrefix,
    withoutNotes,
    withForwardedTradingItemUid,
    onProductButtonClick,
  }

  let content

  if (injectBanners && products?.length) {
    let lastIndex = 0

    injectBanners.forEach((banner) => {
      // we're expecting that we don't ruin the UI and don't put banners before all products,
      // at this moment all positions starts from 6
      const { node, index } = banner

      content = content || []

      content.push(
        <ProductList
          key={`products-${index}`}
          className={lastIndex ? 'mt-16' : null}
          products={products.slice(lastIndex, index)}
          {...productListCommonProps}
        />
      )

      content.push(node)

      lastIndex = index
    })

    // extract last portion of products after all banners
    if (lastIndex > 0 && products.length > lastIndex) {
      content.push(
        <ProductList
          key="products-last"
          className="mt-16"
          products={products.slice(lastIndex)}
          {...productListCommonProps}
        />
      )
    }
  }
  else {
    content = (
      <ProductList
        products={products}
        {...productListCommonProps}
      />
    )
  }

  const currentCount = products?.length || 0
  const isFetchMoreVisible = !isFetching && totalCount > currentCount

  return (
    <div
      className={className}
      {...dataAttributes}
    >
      <>
        {content}
      </>
      {
        isFetching && (
          <WidthContainer
            className="grid-cols-3-center mt-16 grid items-stretch gap-16"
          >
            {
              Array(fetchSkeletonsAmount || 6).fill(0).map((_, index) => (
                <ProductCardDetailedSkeleton key={index} />
              ))
            }
          </WidthContainer>
        )
      }
      {
        isFetchMoreVisible && (
          // key required for remount after product list update
          <FetchMoreIndicator key={currentCount} fetchMore={fetchMore} />
        )
      }
    </div>

  )
}


export default React.memo(InfinityScrollProductFeedDesktopView)
