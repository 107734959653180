import React from 'react'
import { useDevice } from 'device'
import { cx } from 'helpers'
import type { ComponentPageDefaultHero } from 'typings/strapi.graphql'

import { HeroMediaBlock, Label, RichText } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'


type DefaultHeroProps = {
  className?: string
  data: ComponentPageDefaultHero
}

const DEFAULT_HEIGHT_DESKTOP = 520
const DEFAULT_HEIGHT_MOBILE = 400

const DefaultHero: React.FC<DefaultHeroProps> = (props) => {
  const { className, data } = props
  const { heroImage, heroText, label } = data

  const { isMobile } = useDevice()

  const withLabel = Boolean(label?.title)

  const image = isMobile
    ? heroImage?.mobile?.data?.attributes
    : heroImage?.desktop?.data?.attributes

  const imageHeight = isMobile
    ? heroImage?.mobileHeight || DEFAULT_HEIGHT_MOBILE
    : heroImage?.desktopHeight || DEFAULT_HEIGHT_DESKTOP

  return (
    <div className={className}>
      {
        Boolean(image) && (
          <HeroMediaBlock>
            <HeroMediaBlock.Image
              src={image?.url}
              height={imageHeight}
              priority
            />
          </HeroMediaBlock>
        )
      }
      {
        Boolean(heroText) && (
          // Magic numbers for h4 and h2 headers
          <WidthContainer className={cx('relative', withLabel && (isMobile ? '-mt-16' : '-mt-24'))}>
            {
              Boolean(withLabel) && (
                <Label
                  className={cx('absolute bottom-full', isMobile ? 'mb-16' : 'mb-24')}
                  title={label.title}
                  bgColor={label.backgroundColor as any}
                  textColor={label.textColor as any}
                />
              )
            }
            <RichText message={isMobile ? heroText.mobile : heroText.desktop} />
          </WidthContainer>
        )
      }
    </div>
  )
}

export default DefaultHero
