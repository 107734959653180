import type * as Types from '../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type InquireSubscriptionCouponQueryVariables = Types.Exact<{ [key: string]: never; }>;


type InquireSubscriptionCouponQueryPayload = { __typename?: 'Query', purchaseInquiry: { __typename?: 'PurchaseInquiryPayload', data: { __typename?: 'PurchaseInquiryData', items: { __typename?: 'InquiryItems', subscription: { __typename?: 'SubscriptionLineItem', coupon: { __typename?: 'Coupon', code: string | null } | null } | null } } | null } };


 const InquireSubscriptionCouponDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"InquireSubscriptionCoupon"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseInquiry"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"purchaseLevel"},"value":{"kind":"EnumValue","value":"ALL"}}]}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"connection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"key"},"value":{"kind":"StringValue","value":"InquireSubscriptionCoupon","block":false}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"ListValue","values":[]}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subscription"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"coupon"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}}]}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<InquireSubscriptionCouponQueryPayload, InquireSubscriptionCouponQueryVariables>;


export type InquireSubscriptionCouponVariables = InquireSubscriptionCouponQueryVariables;
export type InquireSubscriptionCouponPayload = InquireSubscriptionCouponQueryPayload;

// remove fragments duplicates
// @ts-expect-error
InquireSubscriptionCouponDocument['definitions'] = [ ...new Set(InquireSubscriptionCouponDocument.definitions) ];

export default InquireSubscriptionCouponDocument;