import React from 'react'

import { CatalogueContext } from 'pages/catalog/util'

import { useContextProviderValue } from './util'


type SelectCampaignCatalogContextProviderProps = {
  selectCampaignKey: string
  limit: number
}

const SelectCampaignCatalogContextProvider: React.CFC<SelectCampaignCatalogContextProviderProps> = (props) => {
  const { children, selectCampaignKey, limit } = props
  const value = useContextProviderValue({ selectCampaignKey, limit })

  return (
    <CatalogueContext.Provider value={value}>
      {children}
    </CatalogueContext.Provider>
  )
}

export default SelectCampaignCatalogContextProvider
