import React from 'react'
import { cx } from 'helpers'
import type { ComponentPageBrandsLogoBlend } from 'typings/strapi.graphql'

import { Image } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'


type DesktopViewProps = {
  className?: string
  data: ComponentPageBrandsLogoBlend['rows']
}

const DesktopView: React.FunctionComponent<DesktopViewProps> = (props) => {
  const { className, data } = props

  return (
    <WidthContainer className={cx('text-center', className)} size={1440}>
      {
        data.map(({ desktopRowImageWidth, desktopRowImageHeight, desktopRowImage }, index) => (
          <div className={cx('max-w-full object-contain', { 'mt-24': index > 0 })}>
            <Image
              key={index}
              className="max-w-full object-contain"
              remWidth={desktopRowImageWidth}
              src={desktopRowImage?.data?.attributes?.url}
              alt={desktopRowImage?.data?.attributes?.alternativeText}
              lazy
            />
          </div>
        ))
      }
    </WidthContainer>
  )
}

export default DesktopView
