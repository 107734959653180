import React from 'react'
import { track } from 'analytics'
import { useSearchParams } from 'router'

import ButtonView from '../ButtonView/ButtonView'

import messages from './messages'


type BooleanFilterItemProps = {
  filterKey: CataloguePages.BooleanFilterSearchParamsKey
}

// TODO: Add new values to analytic event — added on 11–12–2023 by algeas
const filterByOptions: Partial<Record<CataloguePages.BooleanFilterSearchParamsKey, Analytics.Events['Filter clear result click']['filterBy']>> = {
  gwp: 'GWP',
  sale: 'Sale',
}

const BooleanFilterItem: React.FunctionComponent<BooleanFilterItemProps> = (props) => {
  const { filterKey } = props

  const type = filterKey.toLowerCase()

  const [ _, setSearchParams ] = useSearchParams()

  const handleClick = () => {
    track('Filter clear result click', {
      filterBy: filterByOptions[filterKey],
    })

    setSearchParams((prevSearchParams) => {
      const newSearchParams = { ...prevSearchParams }

      delete newSearchParams[type]

      return newSearchParams
    }, { replace: false, scroll: false })
  }

  const title = messages[filterKey]

  return (
    <ButtonView title={title} onClick={handleClick} data-testid={`${type}ActiveBooleanFilter`} />
  )
}


export default React.memo(BooleanFilterItem)
