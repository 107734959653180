import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { getGlobalHtmlAttrs, type GlobalHTMLAttrs } from 'helpers/getters'

import { Icon, Text } from 'components/dataDisplay'


type RowListItemProps = {
  title: string
  isActive: boolean
  onClick: () => void
} & GlobalHTMLAttrs

const RowListItemView: React.FunctionComponent<RowListItemProps> = (props) => {
  const { title, isActive, onClick, ...rest } = props

  const { isMobile } = useDevice()


  const activeItemClassName = isActive && cx(
    'before:-z-1 before:bg-light-beige before:absolute before:h-full before:content-[\'\']',
    isMobile ? 'before:-inset-x-16' : 'before:-inset-x-40'
  )
  const rootClassName = cx(
    'desktop-hover:text-gold-50 relative z-0 flex h-56 w-full cursor-pointer items-center justify-between',
    isActive ? activeItemClassName : null
  )

  const htmlAttrs = getGlobalHtmlAttrs(rest)

  return (
    <button
      className={rootClassName}
      type="button"
      {...htmlAttrs}
      onClick={onClick}
    >
      <Text message={title} style="p2" />
      {
        isActive && (
          <Icon name="24/checkmark" color="gold-50" />
        )
      }
    </button>
  )
}


export default RowListItemView
