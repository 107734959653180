import React, { useCallback } from 'react'
import { type ModalComponentProps, standaloneModal } from 'modals'

import { Text } from 'components/dataDisplay'
import { PlainModal } from 'components/feedback'

import { useCatalogueContext, useFragranceTagGroups } from 'pages/catalog/util'

import FilterModalsActionButtons from '../FilterModalsActionButtons/FilterModalsActionButtons'

import TagGroup from './components/TagGroup/TagGroup'

import messages from './messages'


type PersonalityTagsModalProps = {
  sectionToScroll?: CataloguePages.AvailableFilterData['values'][number]['group']
  keepSortAndFilters?: boolean
}

const PersonalityTagsModal: React.FC<ModalComponentProps & PersonalityTagsModalProps> = ({ closeModal, sectionToScroll, keepSortAndFilters }) => {
  const { availableFilters, appliedFilters, appliedFiltersCount, scrollToFilters } = useCatalogueContext()

  const availableTags = availableFilters?.TAGS
  const areOtherFiltersApplied = Boolean(!appliedFilters?.TAGS && appliedFiltersCount > 0)

  const groups = useFragranceTagGroups({ availableTags })

  const handleApply = useCallback(() => {
    closeModal()
    scrollToFilters()
  }, [ closeModal, scrollToFilters ])

  return (
    <PlainModal
      type="sidebar-right"
      bgColor="white"
      closeModal={closeModal}
      data-testid="personalizeModal"
    >
      <Text className="mb-24" message={messages.title} style="sh5" />
      <Text message={areOtherFiltersApplied ? messages.descriptionFilter : messages.description} style="p2" html />
      {
        groups.map(({ name, items }) => (
          <TagGroup
            key={name}
            name={name}
            items={items}
            shouldScroll={name === sectionToScroll}
            keepSortAndFilters={keepSortAndFilters}
          />
        ))
      }
      <FilterModalsActionButtons filterKey="TAGS" onApply={handleApply} />
    </PlainModal>
  )
}

declare global {
  interface ModalsRegistry extends ExtendModalsRegistry<{ personalityTagsModal: typeof PersonalityTagsModal }> {}
}

export default standaloneModal('personalityTagsModal', PersonalityTagsModal)
