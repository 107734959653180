import React from 'react'
import { useEntryListener, useSticky } from 'intersection-observer'


type MobileStickyTopContainerProps = {
  id?: string
  stickyClassName?: string
}

// TODO move to common components? - added on 21.09.2021 by sonatskiy
const MobileStickyTopContainer: React.CFC<MobileStickyTopContainerProps> = ({ id, stickyClassName, children }) => {
  const stickyClass = (`${stickyClassName || ''} fixed left-0 top-0 w-full z-floating-header`).split(' ').filter(Boolean)

  const [ containerRef, headerRef, setSticky ] = useSticky({
    stickyClass,
  })

  const [ triggerRef ] = useEntryListener((entry) => {
    setSticky(entry.boundingClientRect.top < 0)
  })

  return (
    <>
      <div ref={triggerRef} />
      <div ref={containerRef} id={id}>
        <div ref={headerRef}>
          {children}
        </div>
      </div>
    </>
  )
}


export default MobileStickyTopContainer
