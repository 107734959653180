import React from 'react'
import { useDevice } from 'device'
import links from 'links'
import type { ComponentPageGiftPageHero } from 'typings/strapi.graphql'

import { HeroMediaBlock, Label, RichText } from 'components/dataDisplay'
import { Breadcrumbs, type BreadcrumbsProps } from 'components/navigation'
import { WidthContainer } from 'components/layout'

import messages from './messages'


type GiftPageHeroProps = {
  className?: string
  data: ComponentPageGiftPageHero
}

const breadcrumbs: BreadcrumbsProps['items'] = [
  {
    title: messages.breadcrumbs.root,
    to: links.gifts,
  },
  {
    title: messages.breadcrumbs.current,
  },
]

const DEFAULT_HEIGHT_DESKTOP = 250
const DEFAULT_HEIGHT_MOBILE = 178

const normalizeColor = (color?: string): any => {
  return color?.replace(/_/g, '-')
}

const GiftPageHero: React.FC<GiftPageHeroProps> = (props) => {
  const { className, data } = props
  const { heroDescription, heroImage, heroTitle, labelText, labelBackgroundColor, labelTextColor } = data

  const { isMobile } = useDevice()

  const image = isMobile
    ? heroImage?.mobile?.data?.attributes
    : heroImage?.desktop?.data?.attributes

  return (
    <div className={className}>
      {
        Boolean(image) && (
          <HeroMediaBlock className="relative">
            <HeroMediaBlock.Image
              src={image?.url}
              height={isMobile ? DEFAULT_HEIGHT_MOBILE : DEFAULT_HEIGHT_DESKTOP}
              priority
            />
            {
              Boolean(labelText) && (
                <Label
                  className="bottom-24 left-0"
                  title={labelText}
                  absolute
                  bgColor={normalizeColor(labelBackgroundColor) || 'brown-red'}
                  textColor={normalizeColor(labelTextColor) || 'white'}
                  indent={16}
                />
              )
            }
          </HeroMediaBlock>
        )
      }
      <WidthContainer className="relative">
        <Breadcrumbs
          className="mt-24"
          items={breadcrumbs}
        />
        {
          Boolean(heroTitle) && (
            <RichText
              className="text-left"
              message={isMobile ? heroTitle.mobile : heroTitle.desktop}
            />
          )
        }
        {
          Boolean(heroDescription) && (
            <RichText
              className="text-left"
              message={isMobile ? heroDescription.mobile : heroDescription.desktop}
            />
          )
        }
      </WidthContainer>
    </div>
  )
}

export default GiftPageHero
