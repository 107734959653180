import { useMemo } from 'react'
import { useQuery } from 'apollo-client'
import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import useCollaborationsFilters from './useCollaborationsFilters'
import useTheShopCatalogueFilters from './useTheShopCatalogueFilters'

import catalogueFiltersQuery from './graph/catalogueFilters.graphql'
import type { CatalogueFiltersPayload } from './graph/catalogueFilters.graphql'


type FilterEntry = CatalogueFiltersPayload['catalogue']['data']['availableFilters'][number]

export type FilterData =
  Omit<FilterEntry, 'filterType' | 'values'>
  & {
    filterType: FilterEntry['filterType'] | 'CATALOGUE' | 'COLLABORATION' | 'GWP' | 'SALE'
    values: (FilterEntry['values'][number] & { label?: string })[]
    settings?: Record<string, any>
  }

export type AvailableFiltersMap = Partial<Record<FilterData['filterType'] | 'CATALOGUE', FilterData>>


type UseAvailableFiltersProps = {
  catalogueId: number
  catalogueKey: string
  catalogueName: ProductsModule.CatalogueName
}

const useAvailableFilters = ({ catalogueId, catalogueKey, catalogueName }: UseAvailableFiltersProps) => {
  const { data, isFetching: isFetchingFilters } = useQuery(catalogueFiltersQuery, {
    variables: {
      catalogueId,
      catalogueKey,
      source: catalogueKey ? 'CONSTRUCTOR' : 'SCENTBIRD',
    },
    skip: !catalogueId && !catalogueKey,
    fetchPolicy: 'cache-first',
    context: {
      ssrCache: true,
    },
  })

  const { filters: collaborationsFilters } = useCollaborationsFilters(catalogueName)
  const { filters: theShopCatalogueFilters, isFetching: isFetchingTheShopCatalogueFilters } = useTheShopCatalogueFilters(catalogueName)

  const isSelectCampaignCatalogueFiltersEnabled = useFeatureIsOn(constants.features.selectCampaignCatalogueFilters)

  const source = data?.catalogue?.data?.availableFilters

  const availableFilters: AvailableFiltersMap = useMemo(() => {
    if (!source?.length) {
      return null
    }

    return [ ...collaborationsFilters, ...theShopCatalogueFilters, ...source ].reduce((result, filterData) => {
      if (filterData.values.length > 0) {
        // TODO: Remove condition after backend fix — added on 1–04–2025 by algeas
        if (isSelectCampaignCatalogueFiltersEnabled && filterData.filterType === 'CATEGORY') {
          const filteredValues = filterData.values.filter(({ id }) => !/select/i.test(id))

          result[filterData.filterType] = { ...filterData, values: filteredValues }
        }
        else {
          result[filterData.filterType] = filterData
        }
      }

      return result
    }, {})
  }, [ collaborationsFilters, source, theShopCatalogueFilters, isSelectCampaignCatalogueFiltersEnabled ])

  return {
    availableFilters,
    isFetching: isFetchingFilters || isFetchingTheShopCatalogueFilters,
  }
}


export default useAvailableFilters
