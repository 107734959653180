import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import type { ComponentPageImageBannersGridOld } from 'typings/strapi.graphql'

import ImageBannersGridItem from './components/GiftHubCard/ImageBannersGridItem'


type ImageBannersGridProps = {
  data: ComponentPageImageBannersGridOld
}

const ImageBannersGrid: React.FC<ImageBannersGridProps> = (props) => {
  const { data } = props
  const { cards } = data

  const { isMobile } = useDevice()

  return (
    <div className={cx('flex flex-wrap gap-16', isMobile ? 'mt-40' : 'mt-60')}>
      {
        cards.map((card) => {
          const { id } = card

          return (
            <ImageBannersGridItem key={id} data={card} />
          )
        })
      }
    </div>
  )
}

export default ImageBannersGrid
