import React from 'react'
import loadable from '@loadable/component'
import { DynamicPageContentProvider, useDynamicPageContent } from 'modules/dynamicPages'
import type { DynamicPageContentContextValue } from 'modules/dynamicPages/modifyDynamicPageContent'

import type { ComponentPageLandingPageConfig, ComponentPageSelectCampaignPageConfig } from 'typings/strapi.graphql'

import { PageLayout, WhitePageLayout } from 'components/layout'

import HasNeverSubscribed from 'containers/HasNeverSubscribed/HasNeverSubscribed'
import PageSkeleton from 'compositions/PageSkeleton/PageSkeleton'
import SelectCampaignPage from 'pages/SelectCampaignPage/SelectCampaignPage'


const fallback = <PageSkeleton />

const Page404 = loadable(() => import('pages/Page404/Page404'), { fallback })
const StrapiLandingPage = loadable(() => import('pages/landing/StrapiLandingPage/StrapiLandingPage'), { fallback })

const MainLayout = loadable(() => import('layouts/MainLayout/MainLayout'), { fallback })
const LandingLayout = loadable(() => import('layouts/LandingLayout/LandingLayout'), { fallback })

const getPageConfig = (pageConfigs: DynamicPageContentContextValue['config']) => {
  if (!pageConfigs) {
    return null
  }

  for (const config of pageConfigs) {
    if (config.__typename === 'ComponentPageLandingPageConfig') {
      return config as ComponentPageLandingPageConfig
    }

    if (config.__typename === 'ComponentPageSelectCampaignPageConfig') {
      return config as ComponentPageSelectCampaignPageConfig
    }
  }
}

const DynamicPageView: React.FC = () => {
  const dynamicData = useDynamicPageContent()
  const pageConfig = getPageConfig(dynamicData?.config)

  if (pageConfig?.__typename === 'ComponentPageLandingPageConfig') {
    return (
      <LandingLayout forceFullLayout={pageConfig.layout === 'full'}>
        <HasNeverSubscribed redirectTo={pageConfig.redirectTo}>
          <StrapiLandingPage data={dynamicData} />
        </HasNeverSubscribed>
      </LandingLayout>
    )
  }

  if (pageConfig?.__typename === 'ComponentPageSelectCampaignPageConfig') {
    return (
      <MainLayout>
        <WhitePageLayout>
          <PageLayout withPaddingTop={false}>
            <SelectCampaignPage />
          </PageLayout>
        </WhitePageLayout>
      </MainLayout>
    )
  }

  return (
    <MainLayout contentWrapperClassName="flex">
      <Page404 />
    </MainLayout>
  )
}

const dynamicPageSkeletonNode = (
  <MainLayout>
    <PageSkeleton />
  </MainLayout>
)

const DynamicPage: React.FC = () => {
  return (
    <DynamicPageContentProvider skeletonNode={dynamicPageSkeletonNode}>
      <DynamicPageView />
    </DynamicPageContentProvider>
  )
}

export default DynamicPage
