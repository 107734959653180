export default {
  matchesProductCount: {
    en: '<span data-testid="productsCount">{count}</span> {count, plural, one {product match} other {product matches}}',
  },
  defaultProductCount: {
    en: 'Now browsing <span data-testid="productsCount">{count}</span> products',
  },
  matchesFragranceCount: {
    en: '<span data-testid="productsCount">{count}</span> {count, plural, one {fragrance match} other {fragrance matches}}',
  },
  defaultFragranceCount: {
    en: 'Now browsing <span data-testid="productsCount">{count}</span> {isMale, select, true {colognes} false {perfumes}}',
  },
}
