import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useProductCollaborationLabel } from 'modules/collaborations'
import { type UseProductCollaborationProps } from 'modules/collaborations/useProductCollaboration'

import { useSelectCampaigns } from './SelectCampaignsContext'


type UseProductSelectCampaignLabelsProps = UseProductCollaborationProps & {
  productSelectCampaigns?: SelectCampaignsModule.ProductSelectCampaigns
  isShortLabelText?: boolean
}


const useProductSelectCampaignLabels = (props: UseProductSelectCampaignLabelsProps) => {
  const { productSelectCampaigns, isShortLabelText } = props

  const isSelectCampaignLabelsEnabled = useFeatureIsOn(constants.features.selectCampaignLabels)

  const { data } = useSelectCampaigns()
  const legacyCollaborationLabel = useProductCollaborationLabel(props)

  if (!isSelectCampaignLabelsEnabled) {
    return legacyCollaborationLabel && [ legacyCollaborationLabel ]
  }

  if (!data?.labelsMap || !productSelectCampaigns?.length) {
    return null
  }

  const selectCampaignLabels: string[] = []

  for (const campaign of productSelectCampaigns) {
    const campaignLabel = data.labelsMap[campaign.id]

    if (campaignLabel) {
      selectCampaignLabels.push(isShortLabelText ? campaignLabel.shortLabel : campaignLabel.fullLabel)
    }
  }

  return selectCampaignLabels
}


export default useProductSelectCampaignLabels
