import { useQuery, type WatchQueryFetchPolicy } from 'apollo-client'
import { usePriceSelection } from 'modules/priceSelection'

import catalogueProductsQuery, { type CatalogueProductsVariables } from './graph/catalogueProducts.graphql'


export type UseCatalogueProductsProps = {
  variables: Omit<CatalogueProductsVariables, 'priceSelection' | 'source'> & { priceSelection?: CatalogueProductsVariables['priceSelection'] }
  skip?: boolean
  ssr?: boolean
  notifyOnNetworkStatusChange?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
}

const useCatalogueProducts = ({ variables, skip, ssr, notifyOnNetworkStatusChange, fetchPolicy }: UseCatalogueProductsProps) => {
  const priceSelection = usePriceSelection()

  const { catalogueKey } = variables

  const { data, isFetching, fetchMore } = useQuery(catalogueProductsQuery, {
    variables: {
      priceSelection,
      source: catalogueKey ? 'CONSTRUCTOR' : 'SCENTBIRD',
      ...variables,
    },
    fetchPolicy: fetchPolicy || 'cache-first',
    skip,
    ssr,
    notifyOnNetworkStatusChange,
  })

  return {
    data: data?.catalogue?.data?.products,
    cnstrcResultId: data?.catalogue?.data?.resultId,
    fetchMore,
    isFetching,
  }
}


export default useCatalogueProducts
