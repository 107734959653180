import React, { useMemo } from 'react'
import { twcx } from 'helpers'

import { useUser } from 'modules/user'
import type { CatalogueName } from 'modules/products/useCatalogueId'

import { useStrapiBlockIndent } from 'modules/strapi'

import type { ProductsFeedPage } from 'typings/graphql'

import type { ComponentPageCatalogueProductsFeed } from 'typings/strapi.graphql'

import SelectCampaignCatalogContextProvider from 'compositions/SelectCampaignCatalogContextProvider/SelectCampaignCatalogContextProvider'

import ProductFeed from 'pages/catalog/components/ProductFeed/ProductFeed'
import CatalogContextProvider from 'pages/catalog/components/CatalogContextProvider/CatalogContextProvider'

import InjectedImageBanner from './components/InjectedImageBanner/InjectedImageBanner'


type CatalogueProductsFeedProps = {
  className?: string
  data: ComponentPageCatalogueProductsFeed
}

const DEFAULT_INDENT = {
  mobile: 'mt-32',
  desktop: 'mt-48',
}

const CatalogueProductsFeed: React.FunctionComponent<CatalogueProductsFeedProps> = (props) => {
  const { className, data } = props
  const {
    indent,
    gender,
    selectCampaignKey,
    catalogueName,
    feedName,
    limit,
    injectedBanners,
    withFilters,
    withSort,
    isEcommerce,
    isFragrance,
    isExtras,
  } = data

  const { gender: userGender } = useUser()
  const blockIndentClassName = useStrapiBlockIndent(indent, DEFAULT_INDENT)

  const injectFeedBanners = useMemo(() => {
    return injectedBanners?.map((banner) => {
      if (banner.gender && !banner.gender?.includes(userGender)) {
        return null
      }

      return {
        index: banner.index,
        node: (
          <InjectedImageBanner key={banner.id} data={banner} />
        ),
      }
    }).filter(Boolean)
  }, [ userGender, injectedBanners ])

  if (gender && !gender?.includes(userGender)) {
    return null
  }

  const isMale = gender === 'MALE'

  const productFeedContent = (
    <ProductFeed
      injectFeedBanners={injectFeedBanners}
      withSort={withSort}
      withFilters={withFilters}
      isFragrance={isFragrance}
      isMale={isMale}
    />
  )

  if (selectCampaignKey) {
    return (
      <SelectCampaignCatalogContextProvider
        selectCampaignKey={selectCampaignKey}
        limit={limit}
      >
        {productFeedContent}
      </SelectCampaignCatalogContextProvider>
    )
  }

  return (
    <CatalogContextProvider
      catalogueName={catalogueName as CatalogueName}
      productsFeed={feedName as ProductsFeedPage}
      limit={limit}
      isExtras={isExtras}
      isEcommerce={isEcommerce}
    >
      {productFeedContent}
    </CatalogContextProvider>
  )
}


export default React.memo(CatalogueProductsFeed)
