import React, { useCallback } from 'react'
import { useIntl } from 'intl'
import { useSearchParams } from 'router'
import type { GlobalHTMLAttrs } from 'helpers/getters'

import { BrandCard } from 'components/dataDisplay'

import { useCatalogueContext } from 'pages/catalog/util'

import RowListItemView from '../RowListItemView/RowListItemView'

import messages from './messages'


type BrandListItemProps = CatalogFilters.FilterItemProps & {
  data: CataloguePages.AvailableFilterData['values'][number]
  type: 'card' | 'row'
}


const BrandListItem: React.FunctionComponent<BrandListItemProps> = (props) => {
  const { data, type, onFilterSelect, onFilterUnselect } = props
  const { id, name, image: logo } = data

  const intl = useIntl()

  const [ _, setSearchParams ] = useSearchParams()

  const { appliedFilters } = useCatalogueContext()

  const searchParamsBrands = appliedFilters.BRANDS

  const isActive = searchParamsBrands?.includes(id)
  const isLimitReached = searchParamsBrands?.length > 8

  const handleClick = useCallback(() => {
    const brands = (
      isActive
        ? searchParamsBrands?.filter((value) => value !== id)
        : (searchParamsBrands || []).concat(id)
    )

    if (isActive) {
      onFilterUnselect('BRANDS', brands)
    }
    else {
      onFilterSelect('BRANDS', brands)
    }

    setSearchParams((prevParams) => ({
      ...prevParams,
      brands,
      category: undefined, // we drop category because we should do it, filters can be grouped as "category" OR "brands & notes" PF-749
      beauty: undefined,
      tags: undefined,
    }), { replace: false, scroll: false })
  }, [ isActive, searchParamsBrands, id, setSearchParams, onFilterUnselect, onFilterSelect ])

  const htmlAttrs: GlobalHTMLAttrs = {
    role: 'checkbox',
    'aria-checked': isActive,
  }

  if (isLimitReached && !isActive) {
    htmlAttrs['aria-disabled'] = true
    htmlAttrs['aria-details'] = intl.formatMessage(messages.limit)
  }

  if (type === 'card') {
    return (
      <BrandCard
        className="mx-4 w-[146rem] flex-none"
        brand={{ name, logo }}
        isActive={isActive}
        {...htmlAttrs}
        onClick={handleClick}
      />
    )
  }

  return (
    <RowListItemView
      title={data.name}
      isActive={isActive}
      {...htmlAttrs}
      onClick={handleClick}
    />
  )
}


export default React.memo(BrandListItem)
