import React from 'react'
import cx from 'classnames'
import { track } from 'analytics'
import { openModal } from 'modals'
import { useFt } from 'hooks'
import { constants } from 'helpers'
import { useDevice } from 'device'

import { Text } from 'components/dataDisplay'
import { useCatalogueContext } from '../../util'

import PersonalityTagsModal from '../PersonalityTagsModal/PersonalityTagsModal'

import FilterButton from './components/FilterButton/FilterButton'
import FiltersModal from './components/FiltersModal/FiltersModal'
import EcommerceFiltersModal from 'pages/catalog/components/Filters/components/EcommerceFiltersModal/EcommerceFiltersModal'
import SortingModal from './components/SortingModal/SortingModal'

import messages from './messages'


type FiltersProps = {
  className?: string
  filtersModalHeaderContent?: React.ReactElement
  withSort?: boolean
  isEcommerce?: boolean
  showGwpFilter?: boolean
  showSaleFilter?: boolean
}

// TODO: Add customization of filter components via Strapi — added on 24-10-2024 by algeas
const Filters: React.FunctionComponent<FiltersProps> = (props) => {
  const { className, filtersModalHeaderContent, withSort, isEcommerce, showGwpFilter, showSaleFilter } = props

  const { isMobile } = useDevice()
  const { availableFilters } = useCatalogueContext()
  const isEcommerceTwoZeroEnabled = useFt(constants.features.ecommerceTwoZero)
  const isSortEnabled = withSort && isEcommerceTwoZeroEnabled
  const isEcommerceVersionEnabled = isEcommerce && isEcommerceTwoZeroEnabled

  const withPersonalize = Boolean(availableFilters?.TAGS)

  const filterButtonClassName = cx(
    'flex-1',
    isSortEnabled ? (isMobile ? 'min-w-[112rem]' : 'min-w-[176rem]') : 'min-w-[168rem]'
  )
  const sortButtonClassName = cx(
    'ml-8 flex-1',
    isMobile ? 'min-w-[112rem]' : 'min-w-[176rem]'
  )
  const personalizeButtonClassName = cx(
    'flex-1',
    isEcommerceTwoZeroEnabled && 'ml-8',
    isSortEnabled ? cx(isMobile ? 'min-w-[144rem]' : 'min-w-[176rem]') : 'min-w-[168rem]'
  )

  return (
    <div className={cx(className, 'flex items-center')}>
      <FilterButton
        className={filterButtonClassName}
        title={messages.filters}
        iconName="32/filters"
        data-testid="openFilterButton"
        onClick={() => {
          track('Filter button click')

          if (isEcommerceVersionEnabled) {
            openModal('ecommerceFiltersModal', { showGwpFilter, showSaleFilter, headerContent: filtersModalHeaderContent })
          }
          else {
            openModal('filtersModal')
          }
        }}
      />
      {
        withPersonalize && (
          <>
            {
              !isEcommerceVersionEnabled && (
                <Text
                  className="mx-8 flex-none"
                  message={messages.or}
                  style="p2"
                  color="gray-80"
                />
              )
            }
            <FilterButton
              className={personalizeButtonClassName}
              title={messages.personalize}
              iconName="32/personalize"
              data-testid="openPersonalizeButton"
              onClick={() => {
                track('Personalize button click')
                openModal('personalityTagsModal', { keepSortAndFilters: isEcommerce })
              }}
            />
            <PersonalityTagsModal />
          </>
        )
      }
      {
        isSortEnabled && (
          <FilterButton
            className={sortButtonClassName}
            title={messages.sort}
            iconName="32/arrow-down"
            data-testid="openSortButton"
            onClick={() => {
              track('Sort button click')
              openModal('sortingModal')
            }}
          />
        )
      }
      <SortingModal />
      <FiltersModal />
      <EcommerceFiltersModal />
    </div>
  )
}


export default React.memo(Filters)
