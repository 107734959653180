import React from 'react'
import { useEntryListener } from 'intersection-observer'


type FetchMoreIndicatorProps = {
  fetchMore: CataloguePages.FetchMore
}

const FetchMoreIndicator: React.FunctionComponent<FetchMoreIndicatorProps> = ({ fetchMore }) => {
  const [ ref ] = useEntryListener((entry) => {
    if (entry.isIntersecting) {
      fetchMore()
    }
  }, { once: true, observerProps: { rootMargin: '100%' } })

  return (
    <div ref={ref} />
  )
}


export default FetchMoreIndicator
