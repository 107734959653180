import { useMemo } from 'react'
import { useDevice } from 'device'

import { tagGroupNamesOrder } from './constants'

import perfumesFragranceFamilyDesktopImage from './images/perfumes/fragrance-family-desktop.jpg'
import perfumesFragranceFamilyMobileImage from './images/perfumes/fragrance-family-mobile.jpg'
import perfumesOccasionDesktopImage from './images/perfumes/occasion-desktop.jpg'
import perfumesOccasionMobileImage from './images/perfumes/occasion-mobile.jpg'
import perfumesPersonalityDesktopImage from './images/perfumes/personality-desktop.jpg'
import perfumesPersonalityMobileImage from './images/perfumes/personality-mobile.jpg'
import perfumesScentTypeDesktopImage from './images/perfumes/scent-type-desktop.jpg'
import perfumesScentTypeMobileImage from './images/perfumes/scent-type-mobile.jpg'
import perfumesSeasonDesktopImage from './images/perfumes/season-desktop.jpg'
import perfumesSeasonMobileImage from './images/perfumes/season-mobile.jpg'

import colognesFragranceFamilyDesktopImage from './images/colognes/fragrance-family-desktop.jpg'
import colognesFragranceFamilyMobileImage from './images/colognes/fragrance-family-mobile.jpg'
import colognesOccasionDesktopImage from './images/colognes/occasion-desktop.jpg'
import colognesOccasionMobileImage from './images/colognes/occasion-mobile.jpg'
import colognesPersonalityDesktopImage from './images/colognes/personality-desktop.jpg'
import colognesPersonalityMobileImage from './images/colognes/personality-mobile.jpg'


type SubCatalogues = 'perfumes' | 'colognes' | 'webMainPerfumesSubscription' | 'webMainColognesSubscription'
type ShopCatalogues = 'shopSamplePerfumes'
| 'shopSampleColognes'
| 'shopTravelSizePerfumes'
| 'shopTravelSizeColognes'
| 'theShopPerfumes'
| 'theShopColognes'
| 'shop20mlMiniBottlesPerfumes'
| 'shop20mlMiniBottlesColognes'
type Catalogues = Extract<ProductsModule.CatalogueName, SubCatalogues | ShopCatalogues>

type DeviceType = 'mobile' | 'desktop'

const perfumesGroupImages = {
  'Fragrance family': {
    desktop: perfumesFragranceFamilyDesktopImage,
    mobile: perfumesFragranceFamilyMobileImage,
  },
  'Scent type': {
    desktop: perfumesScentTypeDesktopImage,
    mobile: perfumesScentTypeMobileImage,
  },
  'Occasion': {
    desktop: perfumesOccasionDesktopImage,
    mobile: perfumesOccasionMobileImage,
  },
  'Personality': {
    desktop: perfumesPersonalityDesktopImage,
    mobile: perfumesPersonalityMobileImage,
  },
  'Season': {
    desktop: perfumesSeasonDesktopImage,
    mobile: perfumesSeasonMobileImage,
  },
}
const colognesGroupImages = {
  'Fragrance family': {
    desktop: colognesFragranceFamilyDesktopImage,
    mobile: colognesFragranceFamilyMobileImage,
  },
  'Occasion': {
    desktop: colognesOccasionDesktopImage,
    mobile: colognesOccasionMobileImage,
  },
  'Personality': {
    desktop: colognesPersonalityDesktopImage,
    mobile: colognesPersonalityMobileImage,
  },
  'Season': {
    // the same image as for perfumes, so use only one
    desktop: perfumesSeasonDesktopImage,
    mobile: perfumesSeasonMobileImage,
  },
}

const groupImages: Record<Catalogues, Partial<Record<typeof tagGroupNamesOrder[number], Record<DeviceType, string>>>> = {
  perfumes: perfumesGroupImages,
  webMainPerfumesSubscription: perfumesGroupImages,
  colognes: colognesGroupImages,
  webMainColognesSubscription: colognesGroupImages,
  shopSamplePerfumes: perfumesGroupImages,
  shopSampleColognes: colognesGroupImages,
  shopTravelSizePerfumes: perfumesGroupImages,
  shopTravelSizeColognes: colognesGroupImages,
  theShopPerfumes: perfumesGroupImages,
  theShopColognes: colognesGroupImages,
  shop20mlMiniBottlesPerfumes: perfumesGroupImages,
  shop20mlMiniBottlesColognes: colognesGroupImages,
}

type UseFragranceTagGroupsProps = {
  availableTags: CataloguePages.AvailableFilters['TAGS']
  appliedTagIds?: CataloguePages.AppliedFilters['TAGS']
  catalogueName?: Catalogues
}

type Groups = Array<{
  name: string
  items: CataloguePages.AvailableFilterData['values']
  image: string
}>

const useFragranceTagGroups = ({ availableTags, catalogueName }: UseFragranceTagGroupsProps) => {
  const { isMobile } = useDevice()

  const deviceType: DeviceType = isMobile ? 'mobile' : 'desktop'

  return useMemo<Groups>(() => {
    if (!availableTags) {
      return null
    }

    const groupsMap = availableTags.values.reduce((result, item) => {
      const name = item.group
      const image = groupImages[catalogueName]?.[name]?.[deviceType] || item.rebrandImage || item.image

      if (!result[name]) {
        result[name] = {
          name,
          items: [ item ],
          image,
        }
      }
      else {
        result[name].items.push(item)

        // uncomment if there will be a decision to override image for group if current tag is active filter
        // if (appliedTagIds?.includes(item.id)) {
        //   result[name].image = image
        // }
      }

      return result
    }, {})

    return tagGroupNamesOrder.reduce((groups, name) => {
      if (groupsMap[name]) {
        groups.push(groupsMap[name])
      }

      return groups
    }, [])
  }, [ availableTags, catalogueName, deviceType ])
}


export default useFragranceTagGroups
