import React from 'react'
import cx from 'classnames'

import { WidthContainerCompensator } from 'components/layout'


type MobileProps = {
  className?: string
}

const Mobile: React.CFC<MobileProps> = ({ className, children }) => (
  <WidthContainerCompensator
    className={cx(className, 'no-scrollbar overflow-auto px-16')}
    data-testid="activeFilterList"
  >
    <div className="flex justify-start">
      {children}
    </div>
  </WidthContainerCompensator>
)


export default Mobile
