import React, { useCallback, useRef } from 'react'

import FilterDisclosureItem from './components/FilterDisclosureItem/FilterDisclosureItem'

import { FilterDisclosureGroupContext } from './util'


const FilterDisclosureGroup: React.CFC & { Item: typeof FilterDisclosureItem } = (props) => {
  const { children } = props

  const closeCallbackRef = useRef(null)
  const closePreviousItem = useCallback((closeCallback) => {
    if (closeCallbackRef.current && closeCallbackRef.current !== closeCallback) {
      closeCallbackRef.current()
    }

    closeCallbackRef.current = closeCallback
  }, [])

  return (
    <FilterDisclosureGroupContext.Provider value={{ closePreviousItem }}>
      {children}
    </FilterDisclosureGroupContext.Provider>
  )
}

FilterDisclosureGroup.Item = FilterDisclosureItem


export default FilterDisclosureGroup
