export default {
  breadcrumbs: {
    root: {
      en: 'Gifts',
    },
    current: {
      en: 'Gift subscription',
    },
  },
}
