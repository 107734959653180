import React, { useCallback, useEffect, useRef } from 'react'
import { useSearchParams } from 'router'
import { useIntl } from 'intl'
import { track } from 'analytics'

import { useCatalogueContext } from 'pages/catalog/util'

import TagItem from '../TagItem/TagItem'

import messages from './messages'


type TagGroupProps = {
  name: CataloguePages.AvailableFilterData['values'][number]['group']
  items: CataloguePages.AvailableFilterData['values']
  shouldScroll: boolean
  // We drop other filter by default so there only tags without prev values, set undefined to remove key from url
  keepSortAndFilters?: boolean
}

const TagGroup: React.FunctionComponent<TagGroupProps> = (props) => {
  const { name, items, shouldScroll, keepSortAndFilters } = props
  const intl = useIntl()
  const groupLabel = intl.formatMessage(messages.groupLabel, { name })

  const titleRef = useRef<HTMLDivElement>(null)

  const { appliedFilters } = useCatalogueContext()
  const appliedTags = appliedFilters?.TAGS

  const itemGroupIds = items.map(({ id }) => id)

  const appliedGroupTagId = appliedTags?.find((value) => itemGroupIds.includes(value))

  const [ searchParams, setSearchParams ] = useSearchParams()

  const handleChange = useCallback((tag: CataloguePages.AvailableFilterData['values'][number], position: number) => {
    const { id } = tag

    let tags = appliedTags || []

    if (appliedGroupTagId) {
      tags = tags.filter((value) => value !== appliedGroupTagId)
    }

    if (id !== appliedGroupTagId) {
      tags = tags.concat(id)
    }

    // Keep sort and filters params
    const savedSearchParams = keepSortAndFilters ? searchParams : undefined

    setSearchParams({
      ...savedSearchParams,
      tags: tags.length ? tags : undefined,
    }, { replace: false, scroll: false })

    track('Personalization tag select', {
      position,
      tagCategory: name,
      tagName: tag.name,
    })
  }, [ name, appliedGroupTagId, appliedTags, setSearchParams, keepSortAndFilters, searchParams ])

  useEffect(() => {
    let timer

    if (shouldScroll) {
      // TODO doesn't work without timeout because of
      //  next useEffect in Modal that ruins scroll (even inside element) by body-scroll-frozen - added on 10.01.2022 by sonatskiy
      timer = setTimeout(() => {
        if (titleRef.current) {
          titleRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    }

    return () => {
      clearTimeout(timer)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div ref={titleRef} className="text-h8 text-gold-50 mb-24 pt-32">
        {name}
      </div>
      <div
        className="grid grid-cols-3 gap-16"
        role="group"
        data-testid={`${name.replace(' ', '').toLowerCase()}Group`}
        aria-label={groupLabel}
      >
        {
          items.map((data, index) => (
            <TagItem
              key={data.id}
              data={data}
              position={index + 1}
              appliedGroupTagId={appliedGroupTagId}
              onClick={handleChange}
            />
          ))
        }
      </div>
    </>
  )
}


export default React.memo(TagGroup)
