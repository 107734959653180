import React from 'react'
import { useDevice } from 'device'

import { Icon, Text } from 'components/dataDisplay'
import { ButtonBase } from 'components/inputs'


type ButtonViewProps = {
  title: Intl.Message | string
  isBold?: boolean
  'data-testid'?: string
  onClick: React.MouseEventHandler
}

const ButtonView: React.FunctionComponent<ButtonViewProps> = (props) => {
  const { title, isBold, 'data-testid': dataTestId, onClick } = props

  const { isMobile } = useDevice()

  return (
    <ButtonBase
      className="desktop-hover:text-gray-80 mr-12 flex cursor-pointer select-none whitespace-nowrap"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <Text message={title} style={isMobile ? 'p5' : 'p4'} tag={isBold ? 'b' : 'span'} />
      <Icon className="flex-none self-center" name="16/close" />
    </ButtonBase>
  )
}


export default ButtonView
