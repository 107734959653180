import React from 'react'
import { useDevice } from 'device'
import type { ComponentPageGiftCardHero } from 'typings/strapi.graphql'
import { AspectContainer } from 'components/layout'
import { getImageDataFromStrapi, Image, RichText } from 'components/dataDisplay'


type GiftCardHero = {
  className?: string
  data: ComponentPageGiftCardHero
}

const GiftCardHero: React.FC<GiftCardHero> = (props) => {
  const { className, data } = props
  const { heroImage, heroText } = data

  const { isMobile } = useDevice()

  const image = isMobile
    ? heroImage?.mobile?.data?.attributes
    : heroImage?.desktop?.data?.attributes

  return (
    <div className={className}>
      {
        Boolean(image) && (
          isMobile ? (
            <Image className="w-full" src={getImageDataFromStrapi(image)} alt={image.alternativeText} />
          ) : (
            <AspectContainer>
              <Image src={getImageDataFromStrapi(image)} alt={image.alternativeText} />
            </AspectContainer>
          )
        )
      }
      {
        Boolean(heroText) && (
          <RichText className="relative mt-[-18rem] pl-16" message={isMobile ? heroText.mobile : heroText.desktop} />
        )
      }
    </div>
  )
}

export default GiftCardHero
