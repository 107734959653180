import React, { useCallback, useMemo } from 'react'
import { track } from 'analytics'
import { useSearchParams } from 'router'

import { ModalWithLayers } from 'components/feedback'
import { ActionListItem } from 'components/navigation'
import { Icon, Text } from 'components/dataDisplay'
import { ButtonBase } from 'components/inputs'

import { useCatalogueContext } from 'pages/catalog/util'

import messages from './messages'


type FilterLayerOpenButtonProps = {
  layerId: Extract<CataloguePages.FilterKey, 'BRANDS' | 'NOTES'>
  withBorder?: boolean
}
const FilterLayerOpenButton: React.FunctionComponent<FilterLayerOpenButtonProps> = ({ layerId, withBorder }) => {
  const title = messages[layerId]

  const [ _, setSearchParams ] = useSearchParams()

  const { appliedFilters, availableFilters } = useCatalogueContext()
  const appliedFilterIds = appliedFilters?.[layerId]
  const availableItems = availableFilters?.[layerId]?.values

  const appliedFilterNames = useMemo(() => {
    if (!appliedFilterIds?.length || !availableItems?.length) {
      return null
    }

    return appliedFilterIds.map((id) => {
      const item = availableItems.find((item) => item.id === id)

      return item?.name
    })
      .filter(Boolean)
      .join(',')
  }, [ appliedFilterIds, availableItems ])

  const type = layerId.toLowerCase()

  const handleClearClick = useCallback((event) => {
    event.stopPropagation()

    track('Filter clear result click', {
      filterBy: layerId === 'BRANDS' ? 'All brands' : 'All notes',
    })

    setSearchParams((prevParams) => ({
      ...prevParams,
      [type]: undefined,
    }), { replace: false, scroll: false })
  }, [ layerId, setSearchParams, type ])

  return (
    <ModalWithLayers.OpenButton layerId={layerId}>
      <ActionListItem
        title={title}
        style="black"
        withArrow={false}
        withBorder={withBorder}
        data-testid={`${type}OpenButton`}
      >
        {
          Boolean(appliedFilterNames) && (
            <div className="mt-4 flex items-baseline justify-start text-left" data-testid={`${type}ActiveList`}>
              <Text message={appliedFilterNames} style="p5" color="gray-80" />
              <ButtonBase
                className="desktop-hover:text-gold-70 ml-8 inline-flex cursor-pointer"
                tag="div"
                role="button"
                tabIndex={0}
                data-testid={`${type}ClearAll`}
                onClick={handleClearClick}
              >
                <Text message={messages.clear} style="p5" tag="b" />
                <Icon className="self-center" name="16/close" />
              </ButtonBase>
            </div>
          )
        }
      </ActionListItem>
    </ModalWithLayers.OpenButton>
  )
}


export default FilterLayerOpenButton
