export default {
  BRANDS: {
    en: 'Filter by brands',
  },
  NOTES: {
    en: 'Filter by perfume notes',
  },
  clear: {
    en: 'Clear',
  },
}
