import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { string } from 'helpers'

import { Text } from 'components/dataDisplay'
import { ButtonBase } from 'components/inputs'
import { ScrollableContainer } from 'components/layout'

import { useCatalogueContext } from 'pages/catalog/util'

import BrandListItem from '../BrandListItem/BrandListItem'
import FilterDisclosureGroup from '../FilterDisclosureGroup/FilterDisclosureGroup'

import messages from './messages'


const BrandsFilter: React.FunctionComponent<CatalogFilters.FilterListContainerProps> = (props) => {
  const { defaultOpen, onFilterSelect, onFilterUnselect, onFilterListOpen } = props
  const { availableFilters, appliedFilters } = useCatalogueContext()

  const selectedBrands = appliedFilters?.BRANDS || []
  const availableFilter = availableFilters.BRANDS
  const availableBrands = availableFilter?.values
  const { groupByFirstLetter = true } = availableFilter?.settings || {}

  const { isMobile } = useDevice()
  const [ selectedLetter, setSelectedLetter ] = React.useState<string>(null)

  const { brandGroups, popularBrands, brandGroupsKeys, firstGroupLetter } = useMemo(() => {
    if (!availableBrands) {
      return {}
    }

    const popularBrands = availableBrands.filter(({ popular }) => popular)
    const sortedBrands = [ ...availableBrands ]
      .filter(({ id }) => id !== '')
      .sort((a, b) => a.name.localeCompare(b.name))

    let firstGroupLetter: string
    // if grouping is enabled group by a first letter, otherwise create one single group
    let brandGroups = groupByFirstLetter ? sortedBrands.reduce<CataloguePages.FilterGroups>((result, item) => {
      let firstLetter = string.getFirstLetter(item.name)

      if (/\d/.test(firstLetter)) {
        firstLetter = '0-9'
      }

      result[firstLetter] = result[firstLetter] || []
      result[firstLetter].push(item)

      firstGroupLetter = firstGroupLetter || firstLetter

      return result
    }, {}) : null
    const brandGroupsKeys = Object.keys(brandGroups).sort((a, b) => a.localeCompare(b))

    return {
      brandGroups,
      brandGroupsKeys,
      popularBrands,
      firstGroupLetter,
    }
  }, [ availableBrands, groupByFirstLetter ])

  const handleLetterClick = useCallback((letter: string) => {
    setSelectedLetter(letter)
  }, [])

  const title = {
    ...messages.title,
    values: {
      quantity: selectedBrands.length > 0 ? `(${selectedBrands.length})` : '',
    },
  }

  const activeGroupLetter = selectedLetter || firstGroupLetter

  return (
    <FilterDisclosureGroup.Item
      title={title}
      filterKey="BRANDS"
      defaultOpen={defaultOpen}
      onFilterListOpen={onFilterListOpen}
      data-testid="brandsEcommFilter"
    >
      <div className="flex flex-col">
        <div className="flex-1">
          <ScrollableContainer className="flex" isDesktopEnabled>
            {
              Boolean(popularBrands.length) && (
                popularBrands?.map((data) => (
                  <BrandListItem
                    key={data.name}
                    data={data}
                    type="card"
                    onFilterSelect={onFilterSelect}
                    onFilterUnselect={onFilterUnselect}
                  />
                ))
              )
            }
          </ScrollableContainer>

          <ScrollableContainer className="-mx-16 mb-24 flex px-16 pb-8 pt-24" isDesktopEnabled>
            {
              Boolean(brandGroups) && (
                brandGroupsKeys.map((letter) => (
                  <ButtonBase
                    key={letter}
                    className="relative mr-32 cursor-pointer whitespace-nowrap before:absolute before:-inset-16 before:content-['']"
                    tag="div"
                    role="button"
                    tabIndex={0}
                    onClick={() => handleLetterClick(letter)}
                  >
                    <Text
                      key={letter}
                      message={String(letter)}
                      style="h7"
                      color={activeGroupLetter === letter ? 'gold-50' : 'black'}
                    />
                  </ButtonBase>
                ))
              )
            }
          </ScrollableContainer>

          <div className={cx(isMobile ? '' : '-mx-40 mt-24 px-20')} role="group">
            {
              brandGroups[activeGroupLetter]?.map((data) => (
                <BrandListItem
                  key={data.id}
                  data={data}
                  type="row"
                  onFilterSelect={onFilterSelect}
                  onFilterUnselect={onFilterUnselect}
                />
              ))
            }
          </div>
        </div>
      </div>
    </FilterDisclosureGroup.Item>
  )
}


export default BrandsFilter
